import React from "react";

import styleSideBarLogo from "./SideBarMain.module.css";
import SideBarLogoImage from "../../../assets/logoprofil.png";

const SideBarLogo = () => {
  return (
    <img src={SideBarLogoImage} className={styleSideBarLogo.sidebar__logo} />
  );
};

export default SideBarLogo;
