import React from "react";
import { useParams, Outlet } from "react-router-dom";

import Table from "../../table/Table";

const ProductMasterListPage = () => {
  const detail = useParams().tableUrl;

  const project = useParams().product;

  const tableUrl = detail;

  console.log("tableUrl", tableUrl);
  console.log("detail", detail);

  console.log("detail", project);

  return <Table tableUrl={tableUrl} />;
};

export default ProductMasterListPage;
