import React from "react";

import "./TableColumnMenuDrop.css";

const TableColumnMenuDrop = ({
  handleSortingAZ,
  handleSortingZA,
  handleFilterChange,
  drop,
  catMenu,
  ctypemultiselect,
}) => {
  return (
    <ul ref={catMenu} className="menu-links">
      <li onClick={() => handleSortingAZ(drop)}>A to Z</li>
      <div className=" menu_line"></div>
      <li onClick={() => handleSortingZA(drop)}>Z to A</li>
      <div className=" menu_line"></div>
      <li onClick={() => handleFilterChange(drop, ctypemultiselect)}>Filter</li>
      <div className=" menu_line"></div>
    </ul>
  );
};

export default TableColumnMenuDrop;
