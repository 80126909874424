import React, { useContext, useState, useEffect, useCallback } from "react";

import { useForm } from "../../shared/hooks/form-hook";
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_MINLENGTH,
} from "../../shared/util/validators";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import Button from "../../shared/components/FormElements/Button";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import StylessTableeditableRow from "./TableEditableRow.module.css";

const TableEditableRow = ({
  columns,
  handleCheck,
  checked,
  rows,
  tableUrl,
}) => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [preupdatedRows, setPreUpdatedRows] = useState(rows);
  const [updatedRows, setUpdatedRows] = useState(rows);
  const [updatedColumns, setUpdatedColumns] = useState(columns);

  const [blurIsOn, setBlurIsOn] = useState(false);

  const [message, setMessage] = useState("");

  useEffect(() => {
    if (updatedRows.length !== 0 && blurIsOn) {
      const abc = (updatedRows.length - 1).toString() + "-1";
      document.getElementById(abc).focus();
      setBlurIsOn(false);
    }
  }, [updatedRows]);

  const refreshPage = () => {
    window.location.reload();
  };

  console.log("Sutunlar", updatedColumns);
  console.log("Satirlar", updatedRows);

  console.log("Satirlar Uzunluk", updatedRows.length);
  console.log("Satirlar ilki", updatedRows[0]);
  console.log("Satirlar ilki id", updatedRows[0]._id);

  // const as = JSON.parse([updatedRows]);

  const allColumnsCopy = JSON.parse(JSON.stringify(updatedRows));

  console.log("Parse AS", allColumnsCopy);
  console.log("Parse AS Uzunluk", allColumnsCopy.length);

  const gonderilen = JSON.stringify(updatedRows[0]);
  console.log("gonderilen", gonderilen);

  //const xx = JSON.parse(gonderilen);
  //console.log("xxid", xx[0]._id);
  //console.log("xx", xx[0]);
  // console.log("xx", xx);

  const allIds = [];
  allIds.push(...rows.map((emp) => emp._id));

  console.log("allIds", allIds);

  const eachRow = [];
  eachRow.push(...updatedRows.map((emp) => emp));

  console.log("eachRow", eachRow);

  const handlePaste1 = (e, index, column, i) => {
    setUpdatedRows((prevState) =>
      prevState.map((row, i) =>
        index === i
          ? {
              ...row,
              [`${column.value}`]: e.target.value,
            }
          : row
      )
    );
  };

  const rowId = updatedRows[0]._id;

  console.log("row0id", rowId);

  const itemRow = updatedRows[0];

  console.log("row0", itemRow);

  const handlePaste = (event) => {
    event.clipboardData.items[0].getAsString((text) => {
      setMessage("");
      let lines = text.split("\n");
      let i = 1;
      for (let k = 0; k < lines.length; k++) {
        let cell = lines[k].split("\t");
        console.log(cell[0] === "");
        if (!(cell.length === 1 && cell[0] === "")) {
          let xyz = {};
          columns.map(
            (item, index) => (xyz[item.value] = cell[index]),
            (xyz["_id"] = "0"),
            setUpdatedRows((updatedRows) => updatedRows.concat(xyz))
          );
        }
      }
    });
  };

  const handleBlur = (event) => {
    console.log(event.target.value);
    setBlurIsOn(false);

    if (!(event.target.value === "")) {
      let xyz = {};
      columns.map(
        (item, index) =>
          index === 0
            ? (xyz[item.value.toString()] = event.target.value)
            : (xyz[item.value.toString()] = " "),
        (xyz["_id"] = "0"),
        console.log(xyz),
        setUpdatedRows((contact) => contact.concat(xyz))
      );

      setMessage("");
      setBlurIsOn(true);
    }
  };

  const handleInput = (event) => {
    setMessage(event.value);
  };

  const placeUpdateSubmitHandler = async (event) => {
    event.preventDefault();

    const numrows = updatedRows.length;
    console.log("numrows", numrows);
    const blah = Array.from(Array(numrows).keys());
    console.log("BLAH", blah);
    blah.forEach((i) => {
      if (updatedRows[i]._id === "0") {
        try {
          sendRequest(
            `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data`,
            "POST",
            JSON.stringify(updatedRows[i]),

            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.log("err1", err);
        }
      } else {
        try {
          sendRequest(
            `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/${updatedRows[i]._id}`,

            "PATCH",

            JSON.stringify(updatedRows[i]),

            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) {
          console.log("err2", err);
        }
      }
    });
    setTimeout(refreshPage(), 3000);
  };

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <React.Fragment>
      <div>
        <br></br>
        <Button size="4" onClick={(event) => placeUpdateSubmitHandler(event)}>
          Save All
        </Button>
        <br></br>
        <br></br>
      </div>
      {updatedRows.map((row, index) => (
        <tr key={index * 4532134586532}>
          <td>
            <input
              type="checkbox"
              value={checked}
              checked={checked[index]}
              onChange={(e) => handleCheck(e, row)}
            ></input>
          </td>
          {columns.map((column, i) => {
            return (
              <td key={i / 56465}>
                <input
                  className={StylessTableeditableRow.cell}
                  id={index.toString() + "-" + i.toString()}
                  type="text"
                  onChange={(e) => handlePaste1(e, index, column, i)}
                  defaultValue={row[`${column.value}`]}
                />
              </td>
            );
          })}
        </tr>
      ))}

      <tr>
        <td>
          <input type="checkbox" value={checked}></input>
        </td>

        <input
          className={StylessTableeditableRow.cell}
          type="text"
          value={message}
          placeholder="+ Yeni Öge Ekle"
          onPaste={handlePaste}
          onInput={handleInput}
          onBlur={handleBlur}
        />

        {columns.map((column, i) => {
          return i === 0 ? null : (
            <td>
              <input className={StylessTableeditableRow.cell} type="text" />
            </td>
          );
        })}
      </tr>
    </React.Fragment>
  );
};

export default TableEditableRow;
