import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../shared/context/auth-context";

import Table from "../../table/Table";

const PurchaseOrderListPage = () => {
  const auth = useContext(AuthContext);
  // const detail = useParams().tableUrl;

  // const project = useParams().product;

  const list = useParams().purchaselist;
  console.log("👩‍🎤👩‍🎤👩‍🎤👩‍🎤👩‍🎤👩‍🎤👩‍🎤👩‍🎤👩‍🎤👩‍🎤👩‍🎤👩‍🎤", list);

  const tableUrl = list;

  //const tableUrl = "purchaseorderlist";

  let employeeEnabledListsName = auth.employeeEnabledList;
  let department = auth.employeeDepartment;

  console.log("purchasing list deparment", department);

  let employeeEnabledListsArr = [];

  employeeEnabledListsArr = employeeEnabledListsName?.split(",");

  if (
    (tableUrl === "purchaseorderlist" &&
      employeeEnabledListsArr?.includes("Purchase Order List")) ||
    (tableUrl === "budgetapprovallist" &&
      employeeEnabledListsArr?.includes("Budget Approval List")) ||
    (tableUrl === "supplier" &&
      employeeEnabledListsArr?.includes("Supplier List")) ||
    department === "Purchasing Department"
  ) {
    return <Table tableUrl={tableUrl} />;
  } else {
    return <div>Unathorized!!!!!</div>;
  }
};


export default PurchaseOrderListPage;
