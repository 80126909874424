import React, { useState, useEffect } from "react";

import { useHttpClient } from "../../shared/hooks/http-hook";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { NavLink, Link } from "react-router-dom";
import EquipmentTableCss from "./EqipmentLibraryTable.module.css";
const RelatedDocument = ({ clickedItem, projectname }) => {
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [relatedDocuments, setRelatedDocuments] = useState([]);
    const [documentsHistory, setDocumentsHistory] = useState([]);


    useEffect(() => {

        const fetchData = async () => {

            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/equipmentlibrary/relateddocument`,
                    {
                        method: "POST",
                        body: JSON.stringify({
                            itemprefix: clickedItem.path

                        }),
                        headers: { "Content-Type": "application/json" },
                    }
                );

                const responseData = await response.json();
                console.log("RelatedDocument!!!!", responseData);
                let abc = [];
                for (let k = 0; k < responseData.length; k++) {
                    if (!responseData[k].Key.endsWith("/")) {
                        abc.push(responseData[k]);
                    }
                }
                console.log("abc", abc);
                setRelatedDocuments(abc);

                //history
                const responseHistory = await fetch(
                    `${process.env.REACT_APP_API_URL}/equipmentlibrary/documentshistory`,
                    {
                        method: "POST",
                        body: JSON.stringify({
                            itemnr: clickedItem.itemnr,
                            project: projectname
                        }),
                        headers: { "Content-Type": "application/json" },
                    }
                );

                const responseDataHistory = await responseHistory.json();
                setDocumentsHistory(responseDataHistory);
                //history
            } catch (err) { }
        };
        fetchData();

    }, [clickedItem]);

    function getDate(lastModified) {
        let ts = lastModified;

        let date_ob = new Date(ts);
        let date = date_ob.getDate();
        let month = date_ob.getMonth() + 1;
        let year = date_ob.getFullYear();
        let hour = date_ob.getHours();
        let minute = date_ob.getMinutes();
        // prints date & time in YYYY-MM-DD format
        let newdate = date + "." + month + "." + year + " " + hour + ":" + minute;

        return newdate;
    }

    const getPreSignedUrl = async (key) => {
        console.log(key);
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/filemanager/geturl`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        path: key
                    }),
                    headers: { "Content-Type": "application/json" },
                }
            );

            const responseData = await response.json();
            console.log("👋🏻👋🏻👋🏻👋🏻👋🏻👋🏻", responseData);
            return responseData;
        } catch (err) { return err }

    }
    return (
        <React.Fragment>
            <h3>Related Documents</h3>
            <table class={EquipmentTableCss.equipmenttable}>
                <thead>
                    <tr>

                        <th>Title</th>
                        <th>Last Modified</th>



                    </tr>
                </thead>
                <tbody>
                    {isLoading && (
                        <div className="center">
                            <LoadingSpinner />
                        </div>
                    )}


                    {!isLoading &&
                        relatedDocuments &&
                        relatedDocuments.map((doc, index) => (
                            !doc.Key.endsWith('/') &&
                            <tr>

                                <td><Link onClick={async () => {
                                    const url = await getPreSignedUrl(doc.Key);
                                    window.open(url, '_blank');
                                }}>{doc.Key.split("/")[doc.Key.split("/").length - 1]}</Link></td>
                                <td>{getDate(doc.LastModified)}</td>


                            </tr>

                        ))}
                    {!isLoading &&
                        relatedDocuments === [] &&
                        <tr>
                            <td>No records to display</td>
                            <td>No records to display</td>
                            <td>No records to display</td>
                            <td>No records to display</td>
                            <td>No records to display</td>



                        </tr>
                    }

                </tbody>

            </table>
            <br></br>
            <hr class={EquipmentTableCss.solid_divider} />
            <br></br>
            <h3>History</h3>
            <table class={EquipmentTableCss.equipmenttable}>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>User</th>
                        <th>Description</th>



                    </tr>
                </thead>
                <tbody>
                    {isLoading && (
                        <div className="center">
                            <LoadingSpinner />
                        </div>
                    )}


                    {!isLoading &&
                        documentsHistory &&
                        documentsHistory.map((doc, index) => (

                            <tr>
                                <td>{doc.date}</td>
                                <td>{doc.user}</td>
                                <td>{doc.description}</td>



                            </tr>

                        ))}
                    {!isLoading &&
                        documentsHistory === [] &&
                        <tr>
                            <td>No records to display</td>
                            <td>No records to display</td>
                            <td>No records to display</td>



                        </tr>
                    }

                </tbody>

            </table>


        </React.Fragment >)
};
export default RelatedDocument;