import React, { useState } from "react";

const TableExportToExcel = ({ tableUrl, id }) => {
  const fileName = tableUrl.charAt(0).toUpperCase() + tableUrl.slice(1);
  console.log("id", id);

  const fetchJsonArrayColumnCname = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/column`
      );
      const jsonArray = await response.json();
      console.log("columns", jsonArray);
      let collectColumnsCname = [];

      for (let i = 0; i < jsonArray.length; i++) {
        collectColumnsCname = [...collectColumnsCname, jsonArray[i].cname];
      }
      return collectColumnsCname;
    } catch (error) {
      console.log(error.message);
    }
  };

  const fetchJsonArrayColumnValue = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/column`
      );
      const jsonArray = await response.json();
      console.log("columns", jsonArray);

      let collectColumnsValue = [];
      for (let i = 0; i < jsonArray.length; i++) {
        collectColumnsValue = [...collectColumnsValue, jsonArray[i].value];
      }
      return collectColumnsValue;
    } catch (error) {
      console.log(error.message);
    }
  };

  const fetchJsonArrayRows = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data`
      );
      let jsonArray = await response.json();
      console.log("rows", jsonArray);
      return jsonArray;
    } catch (error) {
      console.log(error.message);
    }
  };

  const convertArrayToTable = async () => {
    const apiArrayColumnCname = await fetchJsonArrayColumnCname();
    const apiArrayColumnValue = await fetchJsonArrayColumnValue();
    const apiArrayRows = await fetchJsonArrayRows();
    console.log("column cname", apiArrayColumnCname);
    console.log("column value", apiArrayColumnValue);

    const tableHeaders = `<tr>${Object.values(apiArrayColumnCname)
      .map((key) => `<th>${key}</th>`)
      .join("")}</tr>`;

    const tableRows = apiArrayRows
      .map(
        (obj) =>
          `<tr>
            ${Object.values(apiArrayColumnValue)
            .map(
              (key) =>
                `<td>${obj[key] === null || obj[key] === "" ? "" : obj[key]
                }</td>`
            )
            .join("")}
          </tr>`
      )
      .join("");

    console.log("tableHeaders", tableHeaders);
    console.log("tableRows", tableRows);
    return `<table>${tableHeaders}${tableRows}</table>`.trim();
  };

  const getTableXML = async () => {
    const table = await convertArrayToTable();
    console.log("table", table);
    const tableXML = `
      <html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/html40">
         <meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"/>
         <head>
             <xml>
                  <x:ExcelWorkbook>
                      <x:ExcelWorksheets>
                          <x:ExcelWorksheet>
                              <x:Name>${fileName}</x:Name>
                              <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions>
                          </x:ExcelWorksheet>
                      </x:ExcelWorksheets>
                  </x:ExcelWorkbook>
              </xml>
          </head>
          <body>
              ${table}
          </body>
      </html>
      `;
    console.log("tableXML", tableXML);
    return tableXML;
  };

  const createFileUrl = async () => {
    const tableOutput = await getTableXML();
    console.log("tableOutput", tableOutput);
    const tableBlob = new Blob([tableOutput], {
      type: "application/vnd.ms-excel;base64,",
    });
    console.log("tableBlob", tableBlob);
    const url = URL.createObjectURL(tableBlob);
    return url;
  };
  const downloadFile = async () => {
    const downloadURL = await createFileUrl();
    const downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    downloadLink.download = fileName;
    downloadLink.href = downloadURL;
    downloadLink.click();
  };

  return downloadFile();
};

export default TableExportToExcel;
