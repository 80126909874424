import React, { useRef, useState, useEffect } from "react";

import Button from "../Button";
import "./ImageUpload.css";

const AvatarUpload = (props) => {
  const [isValid, setIsValid] = useState(false);

  const filePickerRef = useRef();

  const [avatar, setAvatar] = useState("");
  const [avatarName, setAvatarName] = useState("");
  const [initialAvatarName, SetInitialAvatarName] = useState("");
  const [previewUrl, setPreviewUrl] = useState();

  const [showButton, setShowButton] = useState(false);

  const initialValue = props.initialValue;
  const initialValid = props.initialValid;

  console.log("initialValue", initialValue);

  useEffect(() => {
    if (initialValue !== undefined) {
      SetInitialAvatarName(initialValue);
    }
    setIsValid(initialValid);
  }, [initialValue, initialValid]);

  useEffect(() => {
    if (avatarName !== "" || initialAvatarName !== "") {
      setShowButton(true);
    }
  }, [avatarName, initialAvatarName]);

  useEffect(() => {
    if (!avatar) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
    };
    fileReader.readAsDataURL(avatar);
  }, [avatar]);

  useEffect(() => {
    if (avatar === undefined) {
      props.onInput(props.id, "undefined", isValid);
    } else {
      props.onInput(props.id, avatar, isValid);
    }
  }, [avatar, isValid]);

  const pickedHandler = (event) => {
    let pickedFile;
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      setAvatar(pickedFile);
      setIsValid(true);
      setAvatarName(event.target.files[0].name);
    } else {
      setIsValid(false);
    }
  };

  const pickAvatarHandler = () => {
    filePickerRef.current.click();
  };

  const removeAvatarHandler = (event) => {
    //filePickerRef.current.abort();
    props.setRemovedAvatarName(initialAvatarName);
    SetInitialAvatarName("");
    setShowButton(false);
  };

  const removeNewAvatarHandler = (event) => {
    //filePickerRef.current.abort();
    filePickerRef.current.value = "";
    setAvatarName("");
    setAvatar("");
    setIsValid(false);
    setShowButton(false);


  };



  console.log("initialAvatarName", initialAvatarName);
  return (
    <React.Fragment>

      <div className="remove_default" >
        <input

          id={props.id}
          name="avatarfile"
          // id="fileinput"
          alt=" "
          ref={filePickerRef}
          style={{ display: "none" }}
          type="file"
          accept=".jpg,.png,.jpeg,.pdf,.xls,.xlsx"
          onChange={pickedHandler}
        // multiple="multiple"
        />

        {showButton && avatarName !== "" && (
          <>
            <br></br>
            <div className="image_box" >{previewUrl && <img style={{ width: 200, height: 200 }} src={previewUrl} alt="Preview" />}</div>
            <div className="file_picker_out_row">
              <div className="file_picker_file_row">
                <label>{avatarName}</label>
              </div>
              <div className="file_picker_remove_row">
                <button

                  type="button"
                  style={{ display: avatarName === "" ? "none" : "block" }}
                  onClick={removeNewAvatarHandler}
                >
                  X
                </button>
              </div>
              <br></br>
            </div>
          </>
        )}
        {showButton && initialAvatarName !== "" && (
          <>
            <br></br>
            <label>
              {" "}
              {
                initialAvatarName[0].split("/")[
                initialAvatarName[0].split("/").length - 1
                ]
              }
            </label>
            <div className="file_picker_remove_row">
              <button
                type="button"
                style={{ display: initialAvatarName === "" ? "none" : "block" }}
                onClick={removeAvatarHandler}
              >
                X
              </button>
            </div>
          </>
        )}

        {!isValid && <p>{props.errorText}</p>}
      </div>
      <div>
        <Button size="3"
          type="button"
          // style={{ display: initialAvatarName === "" ? "none" : "block" }}
          onClick={pickAvatarHandler}
        >
          Pick Avatar
        </Button>
        <br></br>


      </div>

    </React.Fragment>
  );
};

export default AvatarUpload;
