import React, { useState, useEffect } from "react";

import { useHttpClient } from "../../shared/hooks/http-hook";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import EquipmentLibraryHeaderCss from "./EquipmentLibraryHeader.module.css";
const EquipmentLibraryButtons = ({ openNewDrawerHandler, openEditDrawerHandler, showEditButton }) => {


    return (
        <React.Fragment>
            <button onClick={openNewDrawerHandler} class={EquipmentLibraryHeaderCss.button}>+ Add Equipment</button>
            {showEditButton && <button onClick={openEditDrawerHandler} class={EquipmentLibraryHeaderCss.button}>Edit</button>}


        </React.Fragment>)
}
export default EquipmentLibraryButtons;