import React, { useContext, useState, useEffect } from "react";

import { useHttpClient } from "../../shared/hooks/http-hook";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { useForm } from "../../shared/hooks/form-hook";
import FilterComponent from "./FilterComponent";
import { AuthContext } from "../../shared/context/auth-context";
import EqipmentLibraryFilterCss from "./EqipmentLibraryFilter.module.css";
const EquipmentLibraryFilter = ({ projectname, refreshTable, setRefreshTable, equipmentColumns, setFolders, folders }) => {

    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [formState, inputHandler, setFormData] = useForm(false);
    const [filterCategories, setFilterCategories] = useState([]);
    const [filterCategoriesResponse, setFilterCategoriesResponse] = useState([]);
    const auth = useContext(AuthContext);
    useEffect(() => {

        const fetchData = async () => {

            try {

                if (equipmentColumns != undefined) {
                    var abc = [];
                    var def = [];
                    console.log("👀👀👀👀👀👀👀👀", equipmentColumns);
                    for (let k = 0; k < equipmentColumns.length; k++) {

                        if (equipmentColumns[k].ctypefilter) {
                            if (equipmentColumns[k].value === "space") {
                                const responseDivision = await fetch(
                                    `${process.env.REACT_APP_API_URL}/equipmentlibrary/filterdata/${projectname + "Space"}`,
                                    {
                                        method: "GET",
                                        headers: { "Content-Type": "application/json" },
                                    }
                                );

                                const responseDataDivision = await responseDivision.json();

                                abc = [...abc, responseDataDivision];
                                def = [...def, equipmentColumns[k].cname];
                            } else if (equipmentColumns[k].value === "supplier") {
                                const responseDivision = await fetch(
                                    `${process.env.REACT_APP_API_URL}/equipmentlibrary/filterdata/supplier/${projectname}`,
                                    {
                                        method: "GET",
                                        headers: { "Content-Type": "application/json" },
                                    }
                                );

                                const responseDataDivision = await responseDivision.json();

                                abc = [...abc, responseDataDivision];
                                def = [...def, equipmentColumns[k].cname];
                            } else {
                                const responseDivision = await fetch(
                                    `${process.env.REACT_APP_API_URL}/equipmentlibrary/filterdata/${equipmentColumns[k].value}`,
                                    {
                                        method: "GET",
                                        headers: { "Content-Type": "application/json" },
                                    }
                                );

                                const responseDataDivision = await responseDivision.json();

                                abc = [...abc, responseDataDivision];
                                def = [...def, equipmentColumns[k].cname];
                            }

                        }


                    }


                    console.log("FilterCategoriesResponseFilterCategoriesResponseFilterCategoriesResponse", abc);
                    setFilterCategoriesResponse(abc);
                    setFilterCategories(def);

                    const result = folders.filter(f => f.supplier != undefined);
                }

            } catch (err) { console.log("JDOC ERR", err) }

        };
        fetchData();
    }, [projectname, equipmentColumns]);

    useEffect(() => {

        const fetchTable = async () => {
            console.log("🥰🥰🥰🥰🥰🥰🥰🥰🥰🥰🥰", formState.inputs);
            try {
                var responseData = await sendRequest(
                    `${process.env.REACT_APP_API_URL}/equipmentlibrary/filtertable/${projectname}`,
                    "POST",

                    JSON.stringify(
                        Object.values(formState)
                            .reduce(
                                (concatedArr, item) =>
                                    concatedArr.concat(Object.entries(item)),
                                []
                            )
                            .reduce((result, [category, values]) => {
                                console.log("değerler", category);

                                result[category] = result[category] || "";
                                result[category] = result[category].concat(values.value);
                                console.log("authtoken", result);
                                return result;
                            }, {})
                    ),

                    {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + auth.token,
                    }
                );
                setFolders(responseData);
            } catch (err) {
                console.log("err", err);
            }

        }

        if (formState.inputs != false) {
            fetchTable();
        }
    }, [formState.inputs]);
    return (
        <React.Fragment>
            <h3 class={EqipmentLibraryFilterCss.h3}>Filter AREA</h3>


            <br></br>


            <br></br>
            {
                filterCategories &&
                filterCategories.map((filtercat, index) => (
                    <>

                        <FilterComponent
                            title={filtercat}
                            options={filterCategoriesResponse[index]}
                            inputHandler={inputHandler}
                        >

                        </FilterComponent>



                    </>

                ))}





        </React.Fragment>)
};
export default EquipmentLibraryFilter;