import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { useHttpClient } from "../../shared/hooks/http-hook";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import EquipmentTableCss from "./EqipmentLibraryTable.module.css";
const EquipmentTable = ({ tableUrl, projectname, isCheckedJdoc, isCheckedDivision, isCheckedSpace, refreshTable, setClickedItem, setEquipmentColumns,
    checkedlist, setCheckedList, setSelectedID, setIsChecked, isChecked, setIsCheckedFile, isCheckedFile, resetSearchFilter, setFolders, folders }) => {
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [columns, setColumns] = useState([]);


    const handleCheck = (e, row) => {
        const { value, checked } = e.target;
        const file = row.attachmentname;


        let updatedArr = [];
        if (checked) {
            setIsChecked([...isChecked, value]);
            if (file !== undefined) {
                setIsCheckedFile([...isCheckedFile, file]);
            }

            updatedArr = checkedlist.map((val, idx) =>
                idx === folders.findIndex((el) => el._id === value) ? true : val
            );
        } else {
            setIsChecked(isChecked.filter((e) => e !== value));
            if (file !== undefined) {
                setIsCheckedFile(isCheckedFile.filter((e) => e !== file));
            }

            updatedArr = checkedlist.map((val, idx) =>
                idx === folders.findIndex((el) => el._id === value) ? false : val
            );
        }
        console.log("wwwwwwwwwwww 👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽", updatedArr);
        setCheckedList(updatedArr);
        setSelectedID(value);
    };


    useEffect(() => {
        // tabloyu cekiyor
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/equipmentlibrary/project/${projectname}`,
                    {
                        method: "GET",
                        headers: { "Content-Type": "application/json" },
                    }
                );

                const responseData = await response.json();

                setFolders(responseData);
                const arr = Array(responseData.length).fill(false);
                setCheckedList(arr);
                const responseColumns = await fetch(
                    `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/column`,
                    {
                        method: "GET",
                    }
                );

                const responseColumnsData = await responseColumns.json();
                console.log("responseColumnsData", responseColumnsData);
                if (!responseColumns.ok) {
                    throw new Error(responseColumnsData.message);
                }
                setColumns(responseColumnsData);
                setEquipmentColumns(responseColumnsData);

            } catch (err) { }
        };
        fetchData();
    }, [projectname, resetSearchFilter]);

    useEffect(() => {

        if (isCheckedJdoc.length !== 0 || isCheckedDivision.length !== 0 || isCheckedSpace.length !== 0) {


            async function filterData() {
                try {


                    const response = await fetch(
                        `${process.env.REACT_APP_API_URL}/equipmentlibrary/filter`,
                        {
                            method: "POST",
                            body: JSON.stringify({
                                isCheckedJdoc: isCheckedJdoc,
                                isCheckedDivision: isCheckedDivision,
                                isCheckedSpace: isCheckedSpace,
                                project: projectname
                            }),
                            headers: { "Content-Type": "application/json" },
                            // credentials: "include",
                        }
                    );


                    const responseData = await response.json();


                    setFolders(responseData);


                } catch (err) { }
            };
            filterData();
        } else {
            const fetchData = async () => {
                try {
                    const response = await fetch(
                        `${process.env.REACT_APP_API_URL}/equipmentlibrary/project/${projectname}`,
                        {
                            method: "GET",
                            headers: { "Content-Type": "application/json" },
                        }
                    );

                    const responseData = await response.json();

                    setFolders(responseData);

                } catch (err) { }
            };
            fetchData();
        }


    }, [refreshTable]);



    return (
        <React.Fragment>
            <table class={EquipmentTableCss.equipmenttable}>
                <thead>
                    <tr>
                        <th>

                        </th>
                        {!isLoading &&
                            columns && columns.map((column, index) => (column.ctypedisplay ? <th>{column.cname}</th> : null))
                        }



                    </tr>
                </thead>
                <tbody>
                    {isLoading && (
                        <div className="center">
                            <LoadingSpinner />
                        </div>
                    )}


                    {!isLoading &&
                        folders &&
                        folders.map((folder, index) => (
                            <tr >
                                <td>
                                    <input
                                        type="checkbox"
                                        value={folder._id}
                                        checked={checkedlist[index]}
                                        onChange={(event) => handleCheck(event, folder)}
                                    ></input>
                                </td>
                                <td>

                                    {folder.status ? "✔️" : "  "} {folder.attachment ? "📄" : "  "}

                                </td>
                                <td><Link
                                    onClick={() => setClickedItem(folder)}
                                >
                                    {folder.itemnr}
                                </Link>
                                </td>
                                <td>{folder.itemname}</td>
                                <td>{folder.space}</td>
                                <td>{folder.spacename}</td>
                                <td>{folder.supplier}</td>
                                <td>{folder.foundation}</td>
                                <td>{folder.accessibility}</td>

                                <td>{folder.articlename}</td>

                            </tr>

                        ))}

                </tbody>

            </table>


        </React.Fragment>)
};
export default EquipmentTable;