import React from "react";
import { NavLink } from "react-router-dom";
import stylessAdminPage from "./AdminPage.module.css";
import ProfilSide from "../shared/components/Navigation/ProfileSide";

const AdminPage = () => {
  return (

    <div className ={stylessAdminPage.body_row}>
    <div className={stylessAdminPage.profil}><ProfilSide/></div>
    <div className={stylessAdminPage.body}>
      <nav className={stylessAdminPage.card}>AdminPage</nav>
      <div className={stylessAdminPage.body_in}>
        <li>
          <NavLink to="/admin/details/user" exact>
            <div className={stylessAdminPage.choose_page}>
              {" "}
              Go to Users Page - MASTER{" "}
              <div className={stylessAdminPage.user_image}></div>
            </div>
          </NavLink>
        </li>
        <div userName={stylessAdminPage.space}></div>

        <li>
          <NavLink to="/admin/details/usertype" exact>
            {" "}
            <div className={stylessAdminPage.choose_page}>
              Go to User Types Page
              <div className={stylessAdminPage.list_image}></div>
            </div>
          </NavLink>
        </li>

        <div userName={stylessAdminPage.space}></div>

        <li>
          <NavLink to="/admin/details/employee" exact>
            {" "}
            <div className={stylessAdminPage.choose_page}>
              Go to Employees Page - MASTER
              <div className={stylessAdminPage.user_image}></div>
            </div>
          </NavLink>
        </li>

        <div userName={stylessAdminPage.space}></div>
        <li>
          <NavLink to="/admin/details/location" exact>
            <div className={stylessAdminPage.choose_page}>
              Go to Locations Page
              <div className={stylessAdminPage.list_image}></div>
            </div>
          </NavLink>
        </li>

        <div userName={stylessAdminPage.space}></div>
        <li>
          <NavLink to="/admin/details/department" exact>
            {" "}
            <div className={stylessAdminPage.choose_page}>
              Go to Departments Page
              <div className={stylessAdminPage.departman_image}></div>
            </div>
          </NavLink>
        </li>

        <div userName={stylessAdminPage.space}></div>

        <li>
          <NavLink to="/admin/details/assignment" exact>
            <div className={stylessAdminPage.choose_page}>
              {" "}
              Go to Assignments Page{" "}
              <div className={stylessAdminPage.user_image}></div>
            </div>
          </NavLink>
        </li>

        <div userName={stylessAdminPage.space}></div>
        <li>
          <NavLink to="/admin/details/graduation" exact>
            <div className={stylessAdminPage.choose_page}>
              {" "}
              Go to Graduations Page{" "}
              <div className={stylessAdminPage.user_image}></div>
            </div>
          </NavLink>
        </li>

        <div userName={stylessAdminPage.space}></div>
        <li>
          <NavLink to="/admin/details/profession" exact>
            <div className={stylessAdminPage.choose_page}>
              {" "}
              Go to Professions Page{" "}
              <div className={stylessAdminPage.user_image}></div>
            </div>
          </NavLink>
        </li>

        <div userName={stylessAdminPage.space}></div>
        <li>
          <NavLink to="/admin/details/contractstatus" exact>
            <div className={stylessAdminPage.choose_page}>
              {" "}
              Go to Contract Status Page{" "}
              <div className={stylessAdminPage.user_image}></div>
            </div>
          </NavLink>
        </li>

        <div userName={stylessAdminPage.space}></div>
        <li>
          <NavLink to="/admin/details/enabledlist" exact>
            <div className={stylessAdminPage.choose_page}>
              {" "}
              Go to Enabled Lists Page{" "}
              <div className={stylessAdminPage.user_image}></div>
            </div>
          </NavLink>
        </li>

        <div userName={stylessAdminPage.space}></div>
        <li>
          <NavLink to="/admin/details/material" exact>
            <div className={stylessAdminPage.choose_page}>
              {" "}
              Go to Material Page - MASTER
              <div className={stylessAdminPage.user_image}></div>
            </div>
          </NavLink>
        </li>

        <div userName={stylessAdminPage.space}></div>
        <li>
          <NavLink to="/admin/details/unit" exact>
            <div className={stylessAdminPage.choose_page}>
              {" "}
              Go to Unit Page{" "}
              <div className={stylessAdminPage.user_image}></div>
            </div>
          </NavLink>
        </li>

        <div userName={stylessAdminPage.space}></div>
        <li>
          <NavLink to="/admin/details/weightunit" exact>
            <div className={stylessAdminPage.choose_page}>
              {" "}
              Go to Weight Units Page{" "}
              <div className={stylessAdminPage.user_image}></div>
            </div>
          </NavLink>
        </li>

        <div userName={stylessAdminPage.space}></div>

        <li>
          <NavLink to="/admin/details/supplier" exact>
            <div className={stylessAdminPage.choose_page}>
              {" "}
              Go to Suppliers Page - MASTER
              <div className={stylessAdminPage.suppliers_image}></div>
            </div>
          </NavLink>
        </li>

        <div userName={stylessAdminPage.space}></div>

        <li>
          <NavLink to="/admin/details/suppliertype" exact>
            <div className={stylessAdminPage.choose_page}>
              {" "}
              Go to Supplier Types Page{" "}
              <div className={stylessAdminPage.user_image}></div>
            </div>
          </NavLink>
        </li>

        <div userName={stylessAdminPage.space}></div>

        <li>
          <NavLink to="/admin/details/discount" exact>
            <div className={stylessAdminPage.choose_page}>
              {" "}
              Go to Discounts Page{" "}
              <div className={stylessAdminPage.user_image}></div>
            </div>
          </NavLink>
        </li>

        <div userName={stylessAdminPage.space}></div>

        <li>
          <NavLink to="/admin/details/paymentterm" exact>
            <div className={stylessAdminPage.choose_page}>
              {" "}
              Go to Payment Terms Page{" "}
              <div className={stylessAdminPage.user_image}></div>
            </div>
          </NavLink>
        </li>

        <div userName={stylessAdminPage.space}></div>

        <li>
          <NavLink to="/admin/details/transportation" exact>
            <div className={stylessAdminPage.choose_page}>
              {" "}
              Go to Transportations Page{" "}
              <div className={stylessAdminPage.user_image}></div>
            </div>
          </NavLink>
        </li>

        <div userName={stylessAdminPage.space}></div>
        <li>
          <NavLink to="/admin/details/product" exact>
            <div className={stylessAdminPage.choose_page}>
              {" "}
              Go to Product Page - MASTER{" "}
              <div className={stylessAdminPage.user_image}></div>
            </div>
          </NavLink>
        </li>

        <div userName={stylessAdminPage.space}></div>
        <li>
          <NavLink to="/admin/details/customer" exact>
            <div className={stylessAdminPage.choose_page}>
              {" "}
              Go to Customer Page{" "}
              <div className={stylessAdminPage.user_image}></div>
            </div>
          </NavLink>
        </li>

        <div userName={stylessAdminPage.space}></div>
        <li>
          <NavLink to="/admin/details/orderstatus" exact>
            <div className={stylessAdminPage.choose_page}>
              {" "}
              Go to Order Status Page{" "}
              <div className={stylessAdminPage.user_image}></div>
            </div>
          </NavLink>
        </li>

        <div userName={stylessAdminPage.space}></div>
        <li>
          <NavLink to="/admin/details/ordertype" exact>
            <div className={stylessAdminPage.choose_page}>
              {" "}
              Go to Order Type Page{" "}
              <div className={stylessAdminPage.user_image}></div>
            </div>
          </NavLink>
        </li>

        <div userName={stylessAdminPage.space}></div>

        <li>
          <NavLink to="/admin/details/currency" exact>
            <div className={stylessAdminPage.choose_page}>
              {" "}
              Go to Currency Page{" "}
              <div className={stylessAdminPage.user_image}></div>
            </div>
          </NavLink>
        </li>

        <div userName={stylessAdminPage.space}></div>

        <li>
          <NavLink to="/admin/details/approvalstatus" exact>
            <div className={stylessAdminPage.choose_page}>
              {" "}
              Go to Approval Status Page{" "}
              <div className={stylessAdminPage.user_image}></div>
            </div>
          </NavLink>
        </li>

        <div userName={stylessAdminPage.space}></div>

        <li>
          <NavLink to="/admin/details/budgetapprovallist" exact>
            <div className={stylessAdminPage.choose_page}>
              {" "}
              Go to Budget Approval List Page{" "}
              <div className={stylessAdminPage.user_image}></div>
            </div>
          </NavLink>
        </li>

        <div userName={stylessAdminPage.space}></div>

        <li>
          <NavLink to="/admin/details/history" exact>
            <div className={stylessAdminPage.choose_page}>
              {" "}
              Go to History List Page{" "}
              <div className={stylessAdminPage.user_image}></div>
            </div>
          </NavLink>
        </li>

        <div userName={stylessAdminPage.space}></div>


        <li></li>
      </div>
    </div>
    </div>
  );
};

export default AdminPage;
