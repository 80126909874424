import React from "react";
import { useState, useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import stylessProfileSide from "./ProfileSide.module.css";
import { AuthContext } from "../../context/auth-context";
import { useHttpClient } from "../../hooks/http-hook";
const ProfileSide = ({}) => {
  const auth = useContext(AuthContext);
  const [open, setOpen] = useState(false);

  const { sendRequest } = useHttpClient();
  async function logout() {
    try {
      const responseData = await sendRequest(
        `/api2/logout`,
        "POST",
        JSON.stringify({
          username: auth.userName,
        }),
        {
          "Content-Type": "application/json",
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

  const handleOpen = () => {
    setOpen(!open);
  };

  let departmentRouteName = auth.employeeDepartment;
  let employeeRouteName = auth.userName;

  let productRouteName = auth.employeeProduct;

  departmentRouteName = departmentRouteName.replace(/ /g, "-").toLowerCase();

  let productRouteNameArr = [];

  productRouteNameArr = productRouteName.split(",");

  return (
    <div className={stylessProfileSide.profil}>
      <div className={stylessProfileSide.logo_image}></div>
      <br></br>

      <img src={auth.avatarLink} width="80px" height="80px" alt="React Logo" />
      <div className={stylessProfileSide.profile_name}>{auth.employeeName}</div>
      <br></br>

      <Link
        to={"/employee/" + employeeRouteName}
        exact
        className={stylessProfileSide.profile_list}
      >
        <div> My Dashboard </div>
      </Link>

      <NavLink
        to={"/department/" + departmentRouteName}
        exact
        className={stylessProfileSide.profile_list}
      >
        <div>My Department</div>
      </NavLink>

      <br></br>

      <div className={stylessProfileSide.profile_list} onClick={handleOpen}>
        My Projects{" "}
      </div>
      {open ? (
        <ul>
          {productRouteNameArr.map((item) => (
            <li>
              <NavLink to={"/product/" + item} exact>
                {item}
              </NavLink>
            </li>
          ))}
        </ul>
      ) : null}

      <br></br>

      {auth.isLoggedIn && (
        <ul className={stylessProfileSide.profile_list}>
          <NavLink
            to={"/"}
            exact
            onClick={() => {
              logout();
              auth.logout();
            }}
          >
            LOGOUT
          </NavLink>
        </ul>
      )}
    </div>
  );
};

export default ProfileSide;
