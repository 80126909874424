import Button from "../../shared/components/FormElements/Button";
import React, { useState, useEffect } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import LibraryTableBodyCss from "./LibraryTableBody.module.css";
const TableBody = ({ departmentname, setDepartmentname, refreshTable, setIsCheckedFolder, isCheckedFolder, setCheckedListFolder, checkedListFolder,
  setIsCheckedFile, isCheckedFile, setCheckedListFile, checkedListFile }) => {
  const [folders, setFolders] = useState([]);
  const [files, setFiles] = useState([]);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [lastdepartment, setLastDepartment] = useState();

  useEffect(() => {

    let sss = departmentname.split("/");

    let fff = "";


    if (sss[sss.length - 1] !== "") {
      setLastDepartment(sss[sss.length - 1]);

    } else {
      setLastDepartment(sss[sss.length - 2]);

    }


    var xyz = [];
    if (departmentname.split("/").length >= 2) {
      for (let k = 0; k < departmentname.split("/").length - 1; k++) {
        xyz = [...xyz, departmentname.split("/")[k]];
      }
      departmentname = xyz;
    }

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/filemanager/listObjects/${departmentname}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );

        const responseData = await response.json();

        setFolders(responseData[0]);
        setFiles(responseData[1]);
      } catch (err) { }
    };
    fetchData();
  }, [departmentname, refreshTable]);



  const getPreSignedUrl = async (key) => {
    console.log(key);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/filemanager/geturl`,
        {
          method: "POST",
          body: JSON.stringify({
            path: key
          }),
          headers: { "Content-Type": "application/json" },
        }
      );

      const responseData = await response.json();
      console.log("👋🏻👋🏻👋🏻👋🏻👋🏻👋🏻", responseData);
      return responseData;
    } catch (err) { return err }

  }

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear(),
      hours = "" + d.getHours(),
      minutes = "" + d.getMinutes();

    if (hours.length < 2) hours = "0" + hours;
    if (minutes.length < 2) minutes = "0" + minutes;
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("-") + "  " + [hours, minutes].join(":");
  }

  const handleCheckFolder = (e, faf) => {
    const { value, checked } = e.target;

    let updatedArr = [];
    if (checked) {
      setIsCheckedFolder([...isCheckedFolder, value]);

      updatedArr = checkedListFolder.map((val, idx) =>
        idx === faf.findIndex((el) => el.Prefix === value) ? true : val
      );
    } else {
      setIsCheckedFolder(isCheckedFolder.filter((e) => e !== value));

      updatedArr = checkedListFolder.map((val, idx) =>
        idx === faf.findIndex((el) => el.Prefix === value) ? false : val
      );
    }

    setCheckedListFolder(updatedArr);

  };

  const handleCheckFile = (e, faf) => {
    const { value, checked } = e.target;

    let updatedArr = [];
    if (checked) {
      setIsCheckedFile([...isCheckedFile, value]);

      updatedArr = checkedListFile.map((val, idx) =>
        idx === faf.findIndex((el) => el.Key === value) ? true : val
      );
    } else {
      setIsCheckedFile(isCheckedFile.filter((e) => e !== value));

      updatedArr = checkedListFile.map((val, idx) =>
        idx === faf.findIndex((el) => el.Key === value) ? false : val
      );
    }

    setCheckedListFile(updatedArr);

  };


  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {
        <div>
          <ul class={LibraryTableBodyCss.breadcrumb}>
            {departmentname.split("/").map((item, index) => {
              return (item !== "" ? <li><a onClick={() => setDepartmentname(departmentname.split("/").slice(0, index + 1).join("/").toString() + "/")} >{item}</a></li> : null);
            })}


          </ul>
          <table>
            <thead>
              <tr>
                <td><input
                  type="checkbox"

                ></input></td>
                <th>📁</th>
                <th>Ad</th>
                <th>Değiştirme</th>
                <th>Değiştiren</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <div className="center">
                  <LoadingSpinner />
                </div>
              )}


              {!isLoading &&
                folders &&
                folders.map((faf, index) => (
                  <tr

                  >
                    <td><input
                      type="checkbox"
                      value={faf.Prefix}
                      checked={checkedListFolder[index]}
                      onChange={(event) => handleCheckFolder(event, faf)}
                    ></input></td>
                    <td>{"📁"}</td>
                    <td onClick={() => {
                      setDepartmentname(faf.Prefix);

                    }}>{faf.Prefix.split("/")[faf.Prefix.split("/").length - 2]}</td>
                    <td></td>
                    <td>Admin</td>
                  </tr>
                ))}
              {!isLoading &&
                files &&
                files.map((faf, index) => (
                  faf.Key !== departmentname && (<tr

                  >
                    <td><input
                      type="checkbox"
                      value={faf.Key}
                      checked={checkedListFile[index]}
                      onChange={(event) => handleCheckFile(event, faf)}
                    ></input></td>
                    <td>{"📃"}</td>
                    <td onClick={async () => {
                      const url = await getPreSignedUrl(faf.Key);
                      window.open(url, '_blank');
                    }}>
                      {faf.Key.split("/")[faf.Key.split("/").length - 1]}
                    </td>
                    <td></td>
                    <td>Admin</td>
                  </tr>)
                ))}
            </tbody>
          </table>
        </div>
      }
    </React.Fragment >
  );
};

export default TableBody;
