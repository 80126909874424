import React, { useEffect, useState, useContext } from "react";
import Button from "../../shared/components/FormElements/Button";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
const LibraryTableHeader = ({
    openNewModalHandler, departmentname, setRefreshTable, refreshTable, showDeleteButton, openDeleteModalHandler, openRenameModalHandler
}) => {
    const auth = useContext(AuthContext);
    const [openDropDown, setOpenDropDown] = useState(false);
    const { sendRequest, clearError } = useHttpClient();
    const container = React.createRef();
    const handleButtonClick = (e) => {
        setOpenDropDown(!openDropDown);
    };
    const handleFileUpload = async (e) => {

        departmentname = departmentname.replaceAll("/", "-");
        const imageData = new FormData();

        for (const file of e.target.files) {
            imageData.append('image', file);
        }

        const url = `${process.env.REACT_APP_API_URL}/filemanager/uploadFiles/${departmentname}`;

        const config = {
            method: "POST",
            body: imageData,

        };

        try {
            const req = await fetch(url, config);
            console.log(req);
            if (req.ok) {
                const res = await req.json();
                console.log(res);
                setRefreshTable(!refreshTable);
            } else {
                console.log(req);
            }

        } catch (err) {
            console.log(err);
        }

    };
    const handleFolderUpload = async (e) => {
        departmentname = departmentname.replaceAll("/", "-");
        const formdata = new FormData();

        for (var file of e.target.files) {

            formdata.append('webkitRelativePath', file.webkitRelativePath);
            formdata.append('image', file);
        }

        for (const value of formdata.values()) {
            console.log("??????", value);
        }


        const url = `${process.env.REACT_APP_API_URL}/filemanager/uploadFolders/${departmentname}`;

        const config = {
            method: "POST",
            body: formdata,

        };

        try {
            const req = await fetch(url, config);
            console.log(req);
            if (req.ok) {
                const res = await req.json();
                console.log(res);
                setRefreshTable(!refreshTable);
            } else {
                console.log(req);
            }

        } catch (err) {
            console.log(err);
        }




    };
    return (
        <div>
            <div>
                {" "}
                <div>
                    <div>
                        <div ></div>
                    </div>
                </div>
                <div >
                    <div>
                        {showDeleteButton && <Button onClick={openDeleteModalHandler}>Delete</Button>}

                        <Button onClick={openNewModalHandler}>+ New </Button>


                        <Button onClick={handleButtonClick}>Upload</Button>

                        {openDropDown && (
                            <div class="dropdown">
                                <form method='POST'>

                                    <input type='file' multiple='multiple' accept='image/*' name='image' id='image' onChange={handleFileUpload} />
                                    <input type="file" id="folder" webkitdirectory="" multiple onChange={handleFolderUpload} />
                                </form>
                            </div>
                        )}
                        <Button>Edit in Grid View </Button>
                        <Button>Syc </Button>
                        <Button>Export to Excel </Button>
                        <Button onClick={openRenameModalHandler}>Rename </Button>

                        <div ></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LibraryTableHeader;
