import React, { useRef, useEffect } from "react";
import stylessDropDownCustomSelect from "./DropDownCustomSelect.module.css";
const Dropdown = ({
  searchPlaceholder,
  search,
  searchChangeHandler,
  options,
  selectedValue,
  changeSelectedHandler,
  name,
  selectedIndex,
  data,
}) => {
  const searchInputEl = useRef();
  const itemsEl = useRef();

  useEffect(() => {
    searchInputEl.current.focus();
    /* if (selectedValue) {
      itemsEl.current.scrollTop =
        itemsEl.current.querySelector(`.item-${selectedIndex}`).offsetTop - 42;
    }*/
    // eslint-disable-next-line
  }, []);

  console.log("data.indexOf(item.name)", selectedValue);

  console.log("options", options);
  return (
    <div className={stylessDropDownCustomSelect.dropdown__menu}>
      <input
        type="text"
        placeholder={searchPlaceholder ? searchPlaceholder : "Search..."}
        className={stylessDropDownCustomSelect.dropdown__menu_search}
        value={search}
        onChange={searchChangeHandler}
        ref={searchInputEl}
      />
        <br></br>
      <div className={stylessDropDownCustomSelect.dropdown__menu_items} ref={itemsEl}>
        {options?.map((item, index) => (
          <div
            className={
              selectedValue === item.name
                ? `dropdown__menu_item item-${data.indexOf(item)} selected`
                : `dropdown__menu_item item-${data.indexOf(item)}`
            }
            key={index}
            onClick={() => changeSelectedHandler(item.name, name, index, true)}
          >
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
