import React, { useEffect, useState } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import stylessFilterForm from "./FilterForm.module.css";
import Button from "../../shared/components/FormElements/Button";
const FilterForm = ({
  checkedChoosen,
  setCheckedChoosen,
  filterChoosen,
  setFilterChoosen,

  setRows,
  filterby,
  closeFilterDrawerHandler,
  setSelectedColumn,
  tableUrl,
  isFilterFieldMultiSelect,
}) => {
  const { sendRequest } = useHttpClient();
  const [filterData, setFilterData] = useState([]);
  const [query, setQuery] = useState("");
  const [isCheck, setIsCheck] = useState([]);

  useEffect(() => {
    console.log(filterChoosen);
    console.log(checkedChoosen);
    let cleanCheckedChoosen = [];
    let cleanFilterChoosen = [];

    cleanCheckedChoosen = checkedChoosen;
    cleanFilterChoosen = filterChoosen;

    console.log(filterChoosen);
    console.log(checkedChoosen);
    const fetchFilterList = async () => {
      try {
        var responseData;
        if (
          cleanFilterChoosen.length === 0 ||
          cleanCheckedChoosen.length === 0 ||
          (cleanFilterChoosen.length === 1 &&
            cleanFilterChoosen[0] === filterby)
        ) {

          responseData = await sendRequest(
            `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/filterby/${filterby}`
          );
        } else {

          /// bu alan backend filtre sorgusunu hazirlamak icin yazilmistir
          let abc = [];
          for (let y = 0; y < cleanFilterChoosen.length; y++) {
            var newarray = [];
            for (let k = 0; k < cleanCheckedChoosen[y].length; k++) {
              newarray.push("/" + cleanCheckedChoosen[y][k] + "/")
            }
            let ycg = {};
            ycg[cleanFilterChoosen[y]] = { $in: cleanCheckedChoosen[y] };
            abc = [...abc, ycg];
          }
          console.log("abc1", abc);
          // abc = JSON.stringify(abc);
          //console.log("abc2", abc);
          /// bu alan backend filtre sorgusunu hazirlamak icin yazilmistir

          responseData = await sendRequest(
            `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/filterMultiForDrawer/${filterby}`,
            "POST",
            JSON.stringify(abc, function (key, value) {
              if (value instanceof RegExp) {
                return value.toString();
              }
              return value;
            }),
            {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
          );
          /*  responseData = await sendRequest(
              `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/filterMultiForDrawer/${filterby}/${abc}`
            );*/
        }
        /// isFilterFieldMultiSelect

        if (isFilterFieldMultiSelect === true) {
          var responseReset = [];
          for (let k = 0; k < responseData.length; k++) {
            responseReset.push.apply(responseReset, responseData[k].split(","))
          }
          responseReset = [...new Set(responseReset)];
          setFilterData(responseReset);
        } else {
          setFilterData(responseData);
        }

        console.log(checkedChoosen);
        console.log(filterChoosen);
        console.log(filterby);
        console.log(filterChoosen);
        console.log(filterChoosen.indexOf(filterby));
        const index = filterChoosen.indexOf(filterby);
        let arr5 = [];
        for (let j = 0; j < checkedChoosen[index].length; j++) {
          console.log(responseData);
          if (isFilterFieldMultiSelect) {
            var emptyarray = [];
            responseData.forEach(element => {
              emptyarray.push(...element.split(","));
            });
            emptyarray = [...new Set(emptyarray)];
            if (emptyarray.includes(checkedChoosen[index][j])) {
              arr5 = [...arr5, checkedChoosen[index][j]];
            }
          } else {
            if (responseData.includes(checkedChoosen[index][j])) {
              arr5 = [...arr5, checkedChoosen[index][j]];
            }
          }

        }
        console.log("🐠🐠🐠🐠🐠🐠🐠🐠🐠🐠🐠🐠🐠🐠🐠🐠🐠🐠🐠🐠", arr5);
        setIsCheck((isCheck) => isCheck.concat(arr5)); //checkbox history de olanlarin checkbox ini isaretlemek icin
      } catch (err) {
        console.log(err);
      }
    };
    fetchFilterList();

    console.log(filterChoosen);
    console.log(checkedChoosen);
    console.log(isCheck);
  }, []);

  const onSubmit = async (event) => {
    event.preventDefault();
    console.log(filterChoosen);
    console.log(checkedChoosen);
    if (filterChoosen.includes(filterby)) {
      console.log("11111");
      if (isCheck.length === 0) {
        console.log("22222");
        if (filterChoosen.length === 1) {
          console.log("33333");
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data`,
            {
              method: "GET",
            }
          );

          const responseData = await response.json();

          if (!response.ok) {
            throw new Error(responseData.message);
          }

          setRows(responseData);
          setSelectedColumn("");
          setCheckedChoosen([]);
          setFilterChoosen([]);
          closeFilterDrawerHandler();
        } else {
          console.log("44444");

          let cleanCheckedChoosen = [];
          let cleanFilterChoosen = [];

          const index = filterChoosen.indexOf(filterby);

          cleanFilterChoosen = filterChoosen; // 2nd parameter means remove one item only
          cleanCheckedChoosen = checkedChoosen;

          cleanFilterChoosen.splice(index, 1); // 2nd parameter means remove one item only
          cleanCheckedChoosen.splice(index, 1);

          setFilterChoosen(cleanFilterChoosen);
          setCheckedChoosen(cleanCheckedChoosen);
          /// bu alan backend filtre sorgusunu hazirlamak icin yazilmistir
          let abc = [];
          for (let y = 0; y < cleanFilterChoosen.length; y++) {
            var newarray = [];
            for (let k = 0; k < cleanCheckedChoosen[y].length; k++) {
              newarray.push("/" + cleanCheckedChoosen[y][k] + "/")
            }
            let ycg = {};
            ycg[cleanFilterChoosen[y]] = { $in: cleanCheckedChoosen[y] };
            abc = [...abc, ycg];
          }
          console.log(abc);
          //abc = JSON.stringify(abc);
          /// bu alan backend filtre sorgusunu hazirlamak icin yazilmistir

          const response = await sendRequest(
            `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/filterMulti`,
            "POST",
            JSON.stringify(abc, function (key, value) {
              if (value instanceof RegExp) {
                return value.toString();
              }
              return value;
            }),
            {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
          );
          /*  const response = await fetch(
              `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/filterMulti/${abc}`,
              {
                method: "GET",
                headers: { "Content-Type": "application/json" },
  
                // credentials: "include",
              }
            );*/

          /*  const responseData = await response.json();
  
            if (!response.ok) {
              throw new Error(responseData.message);
            }*/
          const responseData = await response;

          setRows(responseData);
          setSelectedColumn("");

          closeFilterDrawerHandler();
        }
      } else {
        console.log("55555");
        let cleanCheckedChoosen = [];
        let cleanFilterChoosen = [];

        const indexof = filterChoosen.indexOf(filterby);
        cleanCheckedChoosen = checkedChoosen;
        cleanCheckedChoosen[indexof] = isCheck;
        cleanFilterChoosen = filterChoosen;

        setCheckedChoosen(cleanCheckedChoosen);
        /// bu alan backend filtre sorgusunu hazirlamak icin yazilmistir
        let abc = [];
        for (let y = 0; y < cleanFilterChoosen.length; y++) {
          var newarray = [];
          for (let k = 0; k < cleanCheckedChoosen[y].length; k++) {
            newarray.push("/" + cleanCheckedChoosen[y][k] + "/")
          }
          let ycg = {};
          ycg[cleanFilterChoosen[y]] = { $in: cleanCheckedChoosen[y] };
          abc = [...abc, ycg];
        }
        console.log(abc);
        // abc = JSON.stringify(abc);
        /// bu alan backend filtre sorgusunu hazirlamak icin yazilmistir


        const response = await sendRequest(
          `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/filterMulti`,
          "POST",
          JSON.stringify(abc, function (key, value) {
            if (value instanceof RegExp) {
              return value.toString();
            }
            return value;
          }),
          {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('jwt')
          }
        );

        /*  const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/filterMulti/${abc}`,
            {
              method: "GET",
              headers: { "Content-Type": "application/json" },
              // credentials: "include",
            }
          );*/

        /*   const responseData = await response.json();
   
           if (!response.ok) {
             throw new Error(responseData.message);
           }*/
        const responseData = await response;

        setRows(responseData);
        setSelectedColumn("");

        closeFilterDrawerHandler();
      }
    } else {
      console.log("66666");
      if (isCheck.length === 0) {
        console.log("77777");

        closeFilterDrawerHandler();
      } else {
        console.log("88888");
        let cleanCheckedChoosen = [];
        let cleanFilterChoosen = [];

        cleanFilterChoosen = filterChoosen.concat(filterby);
        cleanCheckedChoosen = [...checkedChoosen, isCheck];

        setFilterChoosen([...filterChoosen, filterby]);
        setCheckedChoosen([...checkedChoosen, isCheck]);

        /// bu alan backend filtre sorgusunu hazirlamak icin yazilmistir
        let abc = [];
        for (let y = 0; y < cleanFilterChoosen.length; y++) {
          var newarray = [];
          for (let k = 0; k < cleanCheckedChoosen[y].length; k++) {
            newarray.push("/" + cleanCheckedChoosen[y][k] + "/")
          }
          let ycg = {};
          ycg[cleanFilterChoosen[y]] = { $in: newarray };
          abc = [...abc, ycg];
        }
        console.log("abc1", abc);

        /// bu alan backend filtre sorgusunu hazirlamak icin yazilmistir

        /* const response = await fetch(
           `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/filterMulti/${abc}`,
           {
             method: "GET",
             headers: { "Content-Type": "application/json" },
             // credentials: "include",
           }
         );*/

        const response = await sendRequest(
          `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/filterMulti`,
          "POST",
          JSON.stringify(abc, function (key, value) {
            if (value instanceof RegExp) {
              return value.toString();
            }
            return value;
          }),
          {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('jwt')
          }
        );

        //const responseData = await response.json();
        const responseData = await response;
        /*  if (!response.ok) {
            throw new Error(responseData.message);
          }*/

        setRows(responseData);
        setSelectedColumn("");
        closeFilterDrawerHandler();
      }
    }
  };

  const handleSelectAll = (e) => {
    console.log(filterChoosen);
    console.log(checkedChoosen);
    setIsCheck([]);
    console.log(filterChoosen);
    console.log(checkedChoosen);
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  return (
    <div className="checkList">
      <form onSubmit={onSubmit}>
        <div className={stylessFilterForm.filter_criteria} >Filtering Criteria : <div className={stylessFilterForm.filter_criteria_name}>{filterby}</div></div>
        <br></br>
        <br></br>
        <br></br>

        <input
          className={stylessFilterForm.search}
          placeholder="Search.."
          onChange={(event) => setQuery(event.target.value)}
        />


        <br></br>
        <div className={stylessFilterForm.row}> <p>
          <Button size="5" type="submit" value="Submit"> Submit</Button>
        </p>
          <br></br>
          <p>
            <Button
              size="5"

              onClick={handleSelectAll}
            >
              Clear All
            </Button>
          </p></div>

        <br></br>

        {filterData
          .filter((post) => {
            if (post !== undefined) {
              if (query === "") {
                //if query is empty
                return post;
              } else if (post.toLowerCase().includes(query.toLowerCase())) {
                //returns filtered array
                return post;
              }
            }
          })

          .map((item, index) => (
            <div className={stylessFilterForm.file_picker_file_row} key={index}>
              <input

                id={item}
                type="checkbox"
                onChange={handleClick}
                checked={isCheck.includes(item)}
              />
              {item}
            </div>
          ))}
        ;

      </form>
    </div>
  );
};

export default FilterForm;
