import React, { useContext } from "react";

import styleSideBarName from "./SideBarMain.module.css";
import { AuthContext } from "../../../context/auth-context";

const SideBarName = () => {
  const auth = useContext(AuthContext);
  return (
    <div className={styleSideBarName.sidebar__name}>{auth.employeeName}</div>
  );
};

export default SideBarName;
