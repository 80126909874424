import React, { useEffect, useState, useContext } from "react";

import Button from "../../shared/components/FormElements/Button";
import MultiSelect from "../../shared/components/FormElements/MultiSelect/MultiSelect";
import AttachmentUpload from "../../shared/components/FormElements/FileUpload/AttachmentUpload";

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { useForm } from "../../shared/hooks/form-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { useLoaderData } from "react-router-dom";

const TableSelectSupplierForm = ({
  tableUrl,
  isChecked,
  closeSelectSupplierModalHandler,
  setRefreshData,
  refreshData,
  setIsChecked,
}) => {
  const auth = useContext(AuthContext);
  const [columns, setColumns] = useState([]);
  const [columnData, setColumnData] = useState({});
  const [formState, inputHandler, setFormData] = useForm(columnData, false);

  const { sendRequest, clearError } = useHttpClient();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  //console.log("🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸", isChecked);

  const [loadedItem, setLoadedItem] = useState([]);

  const [supplierOptions, setSupplierOptions] = useState([]);

  const [rqcNumber, setRqcNumber] = useState([]);

  useEffect(() => {
    const fetchItem = async () => {
      // setIsLoading(true);
      try {
        const responseData =
          isChecked !== undefined
            ? await sendRequest(
              `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/${isChecked}`
            )
            : null;

        setLoadedItem(responseData);

        const responseColumns =
          isChecked !== undefined
            ? await fetch(`${process.env.REACT_APP_API_URL}/api2/${tableUrl}/column`, {
              method: "GET",
            })
            : null;

        const responseColumnsData = await responseColumns.json();

        if (!responseColumns.ok) {
          throw new Error(responseColumnsData.message);
        }

        setColumns(responseColumnsData);

        const ctypeSelectedSupplier = responseColumnsData
          .filter(({ ctypeselectedsupplier }) => ctypeselectedsupplier === true)
          .map((el) => el);

        const uniqueNames =
          ctypeSelectedSupplier !== false
            ? Array.from(new Set(ctypeSelectedSupplier?.map((q) => q)))
            : [];

        const arr = JSON.parse(JSON.stringify(uniqueNames));

        let receivedValues = {};
        let sendValues = {};
        let categorymapp = Object.values(arr)
          .reduce(
            (concatedArr, item) => concatedArr.concat(Object.entries(item)),
            []
          )
          .filter((pilot) => pilot[0] === "value")
          .reduce((result, [category, values]) => {
            result["intvalue"] = result["intvalue"] || [];
            result["intvalue"] = result["intvalue"].concat({
              [values]: {
                value: responseData[values],
                isValid: true,
              },
            });

            receivedValues = result;

            return result;
          }, {});

        for (let i = 0; i < categorymapp["intvalue"].length; i++) {
          Object.assign(sendValues, categorymapp["intvalue"][i]);
        }

        setColumnData(sendValues);

        setFormData(sendValues, true);
        // console.log("sendvalues", sendValues);
      } catch (err) { }
    };
    fetchItem();
  }, [sendRequest, isChecked, setFormData]);

  let supplierOptionsArr = [];

  supplierOptionsArr = loadedItem.supplieroptions?.split(",");

  // const numrows2 = supplierOptionsArr?.length;

  // const blah2 = Array.from(Array(numrows2).keys());

  // console.log("supplierOptionsArrlength", supplierOptionsArr?.length);

  // console.log("loadedItem", loadedItem);

  useEffect(() => {
    if (supplierOptionsArr?.length !== 0 && supplierOptionsArr !== undefined) {
      let changeMultiSelectName = [];
      for (let i = 0; i < supplierOptionsArr?.length; i++) {
        changeMultiSelectName = [
          ...changeMultiSelectName,
          {
            name: supplierOptionsArr[i],
          },
        ];

        /*  console.log(
            "🧶 🧶 🧶 🧶 🧶 🧶 🧶 🧶 🧶 🧶 🧶 🧶 ",
            changeMultiSelectName
          );*/
        // setDropDownData(indexDropDownToJSON);

        const indexMultiSelectToJSON = JSON.parse(
          JSON.stringify(changeMultiSelectName)
        );

        console.log("🧶 🧶 🧶 🧶 🧶 ", indexMultiSelectToJSON);

        setSupplierOptions(indexMultiSelectToJSON);
      }
    }
  }, [loadedItem.supplieroptions]);

  // console.log("formState.inputs", formState.inputs);

  // console.log("supplierOptionsArr", supplierOptionsArr);

  useEffect(() => {
    setRqcNumber(loadedItem.rqcnumber);
  }, [loadedItem]);

  //  console.log("🩸", loadedItem.rqcnumber);

  /*  console.log(
      "👾👾👾👾👾👾👾👾👾👾👾",
      formState.inputs.attachmentfile?.value?.length
    );
    console.log("👾👾👾👾👾👾", formState.inputs.attachmentfile?.length);
    console.log("👾👾👾", formState.inputs.attachmentfile?.value);
  
    console.log("🩸🩸🩸🩸🩸🩸🩸", isChecked);*/

  const itemSelectSupplierSubmitHandler = async (event) => {
    event.preventDefault();
   // console.log("🩸🩸🩸🩸🩸🩸🩸", isChecked);
    /*
    if (
      formState.inputs.attachmentfile?.value !== undefined &&
      formState.inputs.attachmentfile?.value.length > 1
    ) {
      try {
        var formData = new FormData();

        for (var key in formState.inputs) {
          if (key === "attachmentfile") {
            for (
              let i = 0;
              i < formState.inputs.attachmentfile?.value.length;
              i++
            ) {
              formData.append(key, formState.inputs[key].value[i]);
            }
          } else {
            formData.append(key, formState.inputs[key].value);
          }
        }

        await sendRequest(
          `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/selectedsupplier/${isChecked}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        //  setRefreshData(!refreshData);
        // setIsLoading(false);
        // closeSelectSupplierModalHandler();
        setIsChecked([]);
      } catch (err) {}
    } */ if (formState.inputs.attachmentfile?.value !== undefined) {
      try {
        var formData = new FormData();
        for (var key in formState.inputs) {
          formData.append(key, formState.inputs[key].value);
        }
        formData.append("approvalstatus", "Waiting for approval");
        await sendRequest(
          `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/selectedsupplier/${isChecked}`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setRefreshData(!refreshData);
        setIsLoading(false);
        closeSelectSupplierModalHandler();
        setIsChecked([]);
      } catch (err) {
        console.log(err);
      }
    }
  };

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }
  console.log(
    "🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️",
    formState.inputs
  );
  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isChecked.length !== 0 ? (
        <>
          <form onSubmit={itemSelectSupplierSubmitHandler}>
            {isLoading && <LoadingSpinner asOverlay />}

            {columns.map((item, i) => {
              return item.ctypeselectedsupplier ? (
                item.ctypeinput && item.value === "rqcnumber" ? (
                  <p> {rqcNumber}</p>
                ) : item.ctypemultiselect &&
                  item.value === "supplieroptions" ? (
                  <p> {loadedItem.supplieroptions}</p>
                ) : item.value === "selectedsupplier" ? (
                  <>
                    <MultiSelect
                      id={item.value}
                      onChange={inputHandler}
                      data={supplierOptions}
                      name={item.value}
                      label={item.cname}
                    />
                  </>
                ) : item.ctypeattachmentfile ? (
                  <AttachmentUpload
                    id="attachmentfile"
                    onInput={inputHandler}
                    initialValue=""
                  />
                ) : null
              ) : null;
            })}
            <br></br>
            <Button size="3" type="submit" disabled={!formState.isValid}>
              Add Selected Supplier
            </Button>
            <br></br>
          </form>
        </>
      ) : (
        <>
          {" "}
          <p>Please select an item </p>
          <Button type="button">OK</Button>
        </>
      )}
    </React.Fragment>
  );
};

export default TableSelectSupplierForm;
