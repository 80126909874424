import React from "react";

//import "./TableColumn.css";
import TableColumnItem from "./TableColumnItem";

const TableColumn = ({
  columns,
  filterChoosen,
  handleSorting,
  setSelectedColumn,
  tableUrl,
  setCheckedList,
  checkedlist,
  handleCheckAll
}) => {
  return (
    <TableColumnItem
      columns={columns}
      filterChoosen={filterChoosen}
      handleSorting={handleSorting}
      setSelectedColumn={setSelectedColumn}
      tableUrl={tableUrl}
      setCheckedList={setCheckedList}
      checkedlist={checkedlist}
      handleCheckAll={handleCheckAll}
    />
  );
};

export default TableColumn;
