import React, { useEffect, useState } from "react";

import Assignment from "./Assignment";

const AssignmentCascading = ({
  setSelectedDepartment,
  department,
  onChangeDepartment,
  setSelectedAssignment,
  selectedAssignmentList,
  onChangeAssignment,

  initialDepartment,
  initialAssignment,
}) => {
  const [data, setData] = useState();
  const [dataAssignment, setDataAssignment] = useState();

  const [initialValueDepartment, setInitialValueDepartment] =
    useState(initialDepartment);
  const [initialValueAssignment, setInitialValueAssignment] =
    useState(initialAssignment);

  const [selectedValue, setSelectedValue] = useState();
  const [selectedValueAssignment, setSelectedValueAssignment] = useState();

  useEffect(() => {
    if (department !== undefined) {
      setData(department);
    }
  }, [department]);
  console.log("datause", data);

  useEffect(() => {
    //if (selectedAssignmentList !== undefined) {
    if (selectedAssignmentList.length !== 0) {

      setDataAssignment(selectedAssignmentList);
      setInitialValueDepartment("");
      setSelectedValueAssignment("");
      setInitialValueAssignment("");
    }
  }, [selectedAssignmentList]);

  return (
    <React.Fragment>
      <Assignment
        id="department"
        setFunc={setSelectedDepartment}
        onChange={onChangeDepartment}
        name="department"
        initialValue={initialValueDepartment}
        data={data}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        label="Department"
      ></Assignment>

      <Assignment
        id="assignment"
        setFunc={setSelectedAssignment}
        onChange={onChangeAssignment}
        name="assignment"
        initialValue={initialValueAssignment}
        data={dataAssignment}
        selectedValue={selectedValueAssignment}
        setSelectedValue={setSelectedValueAssignment}
        label="Assignment"
      ></Assignment>
    </React.Fragment>
  );
};

export default AssignmentCascading;
