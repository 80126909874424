import React, { useState, useEffect } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import stylesItemDetails from "./EqipmentLibraryItemDetails.module.css";
const ItemDetails = ({ clickedItem, equipmentColumns }) => {
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [item, setItem] = useState();
    console.log("clickedItem", clickedItem);
    console.log("clickedItem", clickedItem === undefined);

    /*
    <><h5 className={stylesItemDetails.h5}>{column.cname + ": "}</h5><small>{clickedItem[column.value]}</small></> :
                                    <><h5 className={stylesItemDetails.h5}>{column.cname + ": "}</h5><small>{clickedItem[column.value]}</small></>
    
                                    */
    return (
        <React.Fragment>
            <div className={stylesItemDetails.item_details_grid}>
                <div className={stylesItemDetails.item0}><h3>Item Details</h3></div>
                <div className={stylesItemDetails.item1}>
                    {clickedItem != undefined && equipmentColumns != undefined &&
                        equipmentColumns.map((column, index) => (column.displayindetails ?
                            column.value === "attachmentname" ?

                                <><b>{column.cname + ": "}</b><span>{clickedItem[column.value]}</span><br /> </> :
                                <><b>{column.cname + ": "}</b><span>{clickedItem[column.value]}</span><br /> </>



                            : null))

                    }
                </div>

            </div>


        </React.Fragment >)
};
export default ItemDetails;