import React, { useRef, useEffect, useState } from "react";
import stylessDropDownMultiselect from "./DropDownMultiSelect.module.css";
const DropdownMultiSelect = ({
  searchPlaceholder,
  search,
  searchChangeHandler,
  options,
  selectedValue,
  setSelectedValue,
  changeSelectedHandler,
  name,
  selectedIndex,
  data,
  value,
  isValid,
}) => {
  const searchInputEl = useRef();
  const itemsEl = useRef();

  console.log("👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽👽", value);
  console.log("☠️☠️☠️☠️☠️☠️☠️☠️☠️☠️☠️☠️☠️☠️☠️☠️☠️☠️☠️☠️☠️☠️☠️", selectedValue);

  useEffect(() => {
    searchInputEl.current.focus();

    //value !== undefined ? setSelected(value.split(",")) : setSelected([]);
    value !== undefined && value === selectedValue ? setSelectedValue(value.split(",")) : setSelected([]);


  }, [value]);

  console.log("data.indexOf(item.name)", selectedValue);

  console.log("options", options);



  const [selected, setSelected] = useState([]);

  const toggleOption = (itemname, name, index) => {

    setSelected((prevSelected) => {
      // if it's in, remove


      const newArray = selectedValue !== undefined ? [...selectedValue] : [];
      if (newArray.includes(itemname)) {
        console.log("newArrayFiltered", newArrayFiltered);
        console.log("name", name);
        console.log("index", index);
        console.log("isValid", isValid);

        const newArrayFiltered = newArray.filter((item) => item != itemname);

        if (newArrayFiltered.length === 0) {
          changeSelectedHandler(newArrayFiltered, name, index, false);
        } else {
          changeSelectedHandler(newArrayFiltered, name, index, true);
        }
        //  changeSelectedHandler(newArrayFiltered, name, index, isValid);
        return newArrayFiltered;
        // else, add
      } else {
        console.log("newArray", newArray);
        console.log("name", name);
        console.log("index", index);
        console.log("isValid", isValid);
        newArray.push(itemname);
        // changeSelectedHandler(newArray, name, index, isValid);
        changeSelectedHandler(newArray, name, index, true);
        return newArray;
      }
    });
  };

  return (
    <div className={stylessDropDownMultiselect.dropdown__menu}>
      <input
        type="text"
        placeholder={searchPlaceholder ? searchPlaceholder : "Search..."}
        className={stylessDropDownMultiselect.dropdown__menu_search}
        value={search}
        onChange={searchChangeHandler}
        ref={searchInputEl}
      />
      <br></br>
      <div
        className={stylessDropDownMultiselect.dropdown__menu_items}
        ref={itemsEl}
      >
        {options?.map((item, index) => {
          const isSelected = selectedValue?.includes(item.name);
          return (
            <>
              <input
                type="checkbox"
                checked={isSelected}
                onChange={() => toggleOption(item.name, name, index)}
              ></input>
              <div key={index}>{item.name}</div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default DropdownMultiSelect;
