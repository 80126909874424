import React, { useEffect, useState, useContext } from "react";

import Button from "../../shared/components/FormElements/Button";

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
//import "./NewForm.css";

const RestoreForm = ({
    selectedID,
    tableUrl,
    isChecked,
    closeRestoreModalHandler,
    isCheckedFile,
    isCheckedAvatar,
    isCheckedImage,
    setRefreshData,
    refreshData,
    setIsChecked,
    setIsCheckedFile,
    setIsCheckedAvatar,
    setIsCheckedImage,
}) => {
    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();

    const itemId = `${selectedID}`;

    console.log("itemID", itemId);

    console.log("isChecked", isChecked);

    const itemRestoreSubmitHandler = async (event) => {
        event.preventDefault();

        try {
            await sendRequest(
                `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/${isChecked}`,
                "POST",
                null,
                {
                    Authorization: "Bearer " + auth.token,
                }
            );
            setRefreshData(!refreshData);
            closeRestoreModalHandler();
        } catch (err) { console.log("RESTORE FORM ERROR:", err) }
        setIsChecked([]);
        setIsCheckedFile([]);
        setIsCheckedAvatar([]);
        setIsCheckedImage([]);
        //refreshPage();
        //}
        /*else {
          alert("please Select at least one check box !");
        }*/
    };

    if (isLoading) {
        return (
            <div className="center">
                <LoadingSpinner />
            </div>
        );
    }

    return (
        <React.Fragment>
            <ErrorModal error={error} onClear={clearError} />
            {!isLoading && (
                <div onSubmit={itemRestoreSubmitHandler}>
                    {isLoading && <LoadingSpinner asOverlay />}
                    {isChecked.length !== 0 ? (
                        <>
                            {" "}
                            <p>Are you sure to restore selected items? </p>
                            <Button size="2" type="submit" onClick={itemRestoreSubmitHandler}>
                                Restore
                            </Button>
                        </>
                    ) : (
                        <>
                            {" "}
                            <p>Please select an item </p>
                            <Button type="button" onClick={closeRestoreModalHandler}>
                                OK
                            </Button>
                        </>
                    )}
                </div>
            )}
        </React.Fragment>
    );
};

export default RestoreForm;
