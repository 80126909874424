import React from "react";
import { useParams } from "react-router-dom";

import Table from "../table/Table";

const DetailsPage = () => {
  const detail = useParams().detail;

  const tableUrl = detail;

  console.log("tableUrl", tableUrl);
  console.log("detail", detail);

  return <Table tableUrl={tableUrl} />;
};

export default DetailsPage;
