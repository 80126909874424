import React, { useState, useEffect } from "react";
import EquipmentLibraryCss from "./equipmentLibrary.module.css";
import EquipmentTable from "./Components/EqipmentLibraryTable"
import ItemDetails from "./Components/EqipmentLibraryItemDetails"
import RelatedDocuments from "./Components/EqipmentLibraryRelatedDocuments"
import EquipmentLibraryFilter from "./Components/EqipmentLibraryFilter"
import EquipmentLibraryNewForm from "./Forms/EquipmentLibraryNewForm";
import EquipmentLibraryHeader from "./Components/EquipmentLibraryHeader";
import BackDrop from "../shared/components/UIElements/BackDrop";
import SideDrawer from "../shared/components/Navigation/SideDrawer";
import NewForm from "./Forms/EquipmentLibraryNewForm";
import EditForm from "./Forms/EqipmentLibraryEditForm";
import { useParams, NavLink, Outlet } from "react-router-dom";
import EquipmentLibraryButtons from "./Components/EqipmentLibraryButtons";
const EquipmentLibrary = () => {

    var projectname = useParams().product;
    const [refreshData, setRefreshData] = useState(false);
    const [resetSearchFilter, setResetSearchFilter] = useState(false);
    const [isCheckedJdoc, setIsCheckedJdoc] = useState([]);
    const [isCheckedSpace, setIsCheckedSpace] = useState([]);
    const [isCheckedDivision, setIsCheckedDivision] = useState([]);
    const [refreshTable, setRefreshTable] = useState();
    const [clickedItem, setClickedItem] = useState();
    const [equipmentColumns, setEquipmentColumns] = useState();
    const [folders, setFolders] = useState([]);

    const [isChecked, setIsChecked] = useState([]);
    const [isCheckedFile, setIsCheckedFile] = useState([]);
    const [selectedID, setSelectedID] = useState();
    const [checkedlist, setCheckedList] = useState([]);

    const [newDrawerIsOpen, setNewDrawerIsOpen] = useState(false);

    const [showEditButton, setShowEditButton] = useState(false);
    useEffect(() => {

        if (isChecked.length === 1) {
            setShowEditButton(true);
        } else {
            setShowEditButton(false);
        }

    }, [checkedlist]);

    const openNewDrawerHandler = () => {
        setNewDrawerIsOpen(true);
    };

    const closeNewDrawerHandler = () => {
        setNewDrawerIsOpen(false);
    };

    const [editDrawerIsOpen, setEditDrawerIsOpen] = useState(false);
    const openEditDrawerHandler = () => {
        setEditDrawerIsOpen(true);
    };

    const closeEditDrawerHandler = () => {
        setEditDrawerIsOpen(false);
    };



    return (
        <React.Fragment>
            <div class={EquipmentLibraryCss.wrapper}>
                <div class={EquipmentLibraryCss.box0}>
                    <EquipmentLibraryHeader
                        equipmentColumns={equipmentColumns}
                        tableUrl="EquipmentLibrary"
                        projectname={projectname}
                        resetSearchFilter={resetSearchFilter}
                        setResetSearchFilter={setResetSearchFilter}
                        setFolders={setFolders}
                    ></EquipmentLibraryHeader>
                </div>
                <div class={EquipmentLibraryCss.box1}>
                    <EquipmentLibraryFilter
                        projectname={projectname}

                        refreshTable={refreshTable}
                        setRefreshTable={setRefreshTable}
                        equipmentColumns={equipmentColumns}
                        setFolders={setFolders}
                        folders={folders}
                    >
                    </EquipmentLibraryFilter>

                </div>
                <div class={EquipmentLibraryCss.box2}>

                    <EquipmentLibraryButtons openNewDrawerHandler={openNewDrawerHandler} openEditDrawerHandler={openEditDrawerHandler} showEditButton={showEditButton}></EquipmentLibraryButtons>
                    <br></br>
                    <EquipmentTable
                        tableUrl="EquipmentLibrary"
                        projectname={projectname}
                        isCheckedJdoc={isCheckedJdoc}
                        isCheckedSpace={isCheckedSpace}
                        isCheckedDivision={isCheckedDivision}
                        refreshTable={refreshTable}
                        setClickedItem={setClickedItem}
                        setEquipmentColumns={setEquipmentColumns}
                        checkedlist={checkedlist}
                        setCheckedList={setCheckedList}
                        setSelectedID={setSelectedID}
                        setIsChecked={setIsChecked}
                        isChecked={isChecked}
                        setIsCheckedFile={setIsCheckedFile}
                        isCheckedFile={isCheckedFile}
                        resetSearchFilter={resetSearchFilter}
                        setFolders={setFolders}
                        folders={folders}
                    >

                    </EquipmentTable>
                </div>
                <div class={EquipmentLibraryCss.box3}>
                    <ItemDetails clickedItem={clickedItem} equipmentColumns={equipmentColumns}></ItemDetails>
                </div>
                <div class={EquipmentLibraryCss.box4}>
                    <RelatedDocuments clickedItem={clickedItem} projectname={projectname}></RelatedDocuments>
                </div>


            </div>

            {newDrawerIsOpen && <BackDrop onClick={closeNewDrawerHandler} />}
            <SideDrawer show={newDrawerIsOpen} /* onClick={closeNewDrawerHandler}*/>
                <NewForm
                    projectname={projectname}
                    tableUrl="EquipmentLibrary"
                    setRefreshData={setRefreshTable}
                    refreshData={refreshTable}
                    closeNewDrawerHandler={closeNewDrawerHandler}
                />
            </SideDrawer>
            {editDrawerIsOpen && <BackDrop onClick={closeEditDrawerHandler} />}
            <SideDrawer show={editDrawerIsOpen} /* onClick={closeNewDrawerHandler}*/>
                <EditForm
                    projectname={projectname}
                    selectedID={selectedID}
                    tableUrl="EquipmentLibrary"
                    setRefreshData={setRefreshTable}
                    refreshData={refreshTable}
                    closeEditDrawerHandler={closeEditDrawerHandler}
                    setIsChecked={setIsChecked}
                    setIsCheckedFile={setIsCheckedFile}
                    setSelectedID={setSelectedID}
                    checkedlist={checkedlist}
                    setCheckedList={setCheckedList}

                />
            </SideDrawer>
        </React.Fragment>)
};
export default EquipmentLibrary;