import React, { useState, useEffect } from "react";

import TableReadOnlyRow from "./TableReadOnlyRow";
import TableEditableRow from "./TableEditableRow";
import TableColumn from "./TableColumn";
import Button from "../../shared/components/FormElements/Button";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import stylesTableBodyMobile from "./TableBodyMobile.module.css";
import asd from "../../shared/assets/filter.png"
const TableBodyMobile = ({
    setSelectedID,
    editAll,
    tableUrl,
    setIsChecked,
    isChecked,
    rows,
    setRows,
    filterChoosen,
    setSelectedColumn,
    openDisplayDrawerHandler,
    setIsCheckedFile,
    isCheckedFile,
    refreshData,
    setCheckedList,
    checkedlist,
    setIsCheckedAvatar,
    isCheckedAvatar,
    setIsCheckedImage,
    isCheckedImage,
    isCheckedOrderType,
    setIsCheckedOrderType,
    isCheckedWoNumber,
    setIsCheckedWoNumber,
    setIsCheckedCreatedBy,
    isCheckedCreatedBy,
    setIsCheckedCreatedByDepartment,
    isCheckedCreatedByDepartment
}) => {
    const [columns, setColumns] = useState([]);
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [checkAllStatus, setCheckAllStatus] = useState(false);
    //get all rows
    useEffect(() => {
        const sendRequest = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data`,
                    {
                        method: "GET",
                    }
                );

                const responseData = await response.json();

                if (!response.ok) {
                    throw new Error(responseData.message);
                }

                setRows(responseData);
                console.log("rows", responseData);
                const arr = Array(responseData.length).fill(false);
                setCheckedList(arr);
            } catch (err) {
                setError(err.message);
            }
            setIsLoading(false);
        };
        sendRequest();
    }, [tableUrl, refreshData]);

    const errorHandler = () => {
        setError(null);
    };

    // get all columns
    useEffect(() => {
        const sendRequest = async () => {
            setIsLoading(true);
            // arzu ekledi recyclebin icin 12.12.2022
            var newTableUrl;
            console.log("😺😺😺😺😺😺😺😺😺😺😺😺😺😺😺😺😺😺😺", tableUrl);
            if (tableUrl.includes("Recycle")) {
                newTableUrl = tableUrl.replace('Recycle', '');
            } else {
                newTableUrl = tableUrl;
            }
            console.log("😺😺😺😺😺😺😺😺😺😺😺😺😺😺😺😺😺😺😺 ---newTableUrl", newTableUrl);
            // arzu ekledi recyclebin icin 12.12.2022
            try {
                const responseColumns = await fetch(
                    `${process.env.REACT_APP_API_URL}/api2/${newTableUrl}/column`,
                    {
                        method: "GET",
                    }
                );

                const responseColumnsData = await responseColumns.json();

                if (!responseColumns.ok) {
                    throw new Error(responseColumnsData.message);
                }

                setColumns(responseColumnsData);
                console.log("columns", responseColumnsData);
            } catch (err) {
                setError(err.message);
            }
            setIsLoading(false);
        };
        sendRequest();
    }, []);

    const handleCheck = (e, row) => {
        const { value, checked } = e.target;
        const file = row.attachmentname;
        const avatar = row.avatarname;
        const image = row.imagename;
        const ordertype = row.ordertype;
        const wonumber = row.wonumber;

        let updatedArr = [];
        if (checked) {
            setIsChecked([...isChecked, value]);

            setIsCheckedCreatedBy([...isCheckedCreatedBy, row.createdby]);

            if (file !== undefined) {
                setIsCheckedFile([...isCheckedFile, file]);
            }
            if (avatar !== undefined) {
                setIsCheckedAvatar([...isCheckedAvatar, avatar]);
            }
            if (image !== undefined) {
                setIsCheckedImage([...isCheckedImage, image]);
            }
            if (ordertype !== undefined) {
                setIsCheckedOrderType([...isCheckedOrderType, ordertype]);
            }
            if (wonumber !== undefined) {
                setIsCheckedWoNumber([...isCheckedWoNumber, wonumber]);
            }
            updatedArr = checkedlist.map((val, idx) =>
                idx === rows.findIndex((el) => el._id === value) ? true : val
            );
        } else {
            setIsChecked(isChecked.filter((e) => e !== value));
            var indx = isChecked.findIndex((element) => element === value);
            isCheckedCreatedBy.splice(indx, 1);
            setIsCheckedCreatedBy(isCheckedCreatedBy);
            if (file !== undefined) {
                setIsCheckedFile(isCheckedFile.filter((e) => e !== file));
            }
            if (avatar !== undefined) {
                setIsCheckedAvatar(isCheckedAvatar.filter((e) => e !== avatar));
            }
            if (image !== undefined) {
                setIsCheckedImage(isCheckedImage.filter((e) => e !== image));
            }
            if (ordertype !== undefined) {
                setIsCheckedOrderType(
                    isCheckedOrderType.filter((e) => e !== ordertype)
                );
            }
            if (wonumber !== undefined) {
                setIsCheckedWoNumber(isCheckedWoNumber.filter((e) => e !== wonumber));
            }

            updatedArr = checkedlist.map((val, idx) =>
                idx === rows.findIndex((el) => el._id === value) ? false : val
            );
        }

        setCheckedList(updatedArr);
        setSelectedID(value);
    };

    const handleSorting = async (sortField, sortOrder) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/sorting/${sortField}/${sortOrder}`,
                {
                    method: "GET",
                }
            );

            const responseData = await response.json();

            if (!response.ok) {
                throw new Error(responseData.message);
            }

            setRows(responseData);
        } catch (err) {
            setError(err.message);
        }

        /*if (sortField) {
          const sorted = [...rows].sort((a, b) => {
            if (a[sortField] === null) return 1;
            if (b[sortField] === null) return -1;
            if (a[sortField] === null && b[sortField] === null) return 0;
            return (
              a[sortField]
                ?.toString()
                .localeCompare(b[sortField]?.toString(), "en", {
                  numeric: true,
                }) * (sortOrder === "asc" ? 1 : -1)
            );
          });
          setRows(sorted);
        } */
    };
    function handleCheckAll() {
        setCheckAllStatus(!checkAllStatus);
        setCheckedList(new Array(checkedlist.length).fill(!checkAllStatus));
        if (!checkAllStatus) {
            let result = rows.map(a => a._id);
            setSelectedID(result);
            setIsChecked(result);
        } else {
            setSelectedID([]);
            setIsChecked([]);
        }

    }
    return (

        <div className={stylesTableBodyMobile.cardArea}>
            {rows.map((row, index) => (

                <div className={stylesTableBodyMobile.card2}>
                    <input
                        type="checkbox"
                        value={row._id}
                        checked={checkedlist[index]}
                        onChange={(event) => handleCheck(event, row)}
                    ></input>
                    <div className={stylesTableBodyMobile.card} onClick={() => {
                        setSelectedID(row._id);
                        openDisplayDrawerHandler();
                    }}>

                        {columns.length !== 0 &&
                            <div className={stylesTableBodyMobile.cascadingfamily1}>
                                <div className={stylesTableBodyMobile.statusText}>{row[`${columns.find(o => (o.ctypecardfamily === "header" && o.ctypecardposition === "status")).value}`]}</div>
                                <div className={stylesTableBodyMobile.numberText}>{row[`${columns.find(o => (o.ctypecardfamily === "header" && o.ctypecardposition === "number")).value}`]}</div>
                            </div>}
                        {columns.map((column, index) => {

                            return column.ctypemobile === true ? (
                                column.ctypecardfamily === "double" && column.ctypecardposition === "start" ? (
                                    <div>
                                        <div className={stylesTableBodyMobile.cascadingfamily2}>
                                            <div>
                                                <div className={stylesTableBodyMobile.title}>{column.cname}</div>
                                                <div className={stylesTableBodyMobile.titleText}>{row[`${column.value}`]}</div>
                                            </div>
                                            <div>
                                                <div className={stylesTableBodyMobile.title}>{columns.find(o => (o.ctypecard === column.ctypecard && o.ctypecardfamily === "double" && o.ctypecardposition === "end")).cname}</div>
                                                <div className={stylesTableBodyMobile.titleText}>{row[`${columns.find(o => (o.ctypecard === column.ctypecard && o.ctypecardfamily === "double" && o.ctypecardposition === "end")).value}`]}</div>
                                            </div>


                                        </div>
                                    </div>
                                ) : column.ctypecardfamily === "single" ? (
                                    <div className={stylesTableBodyMobile.cascadingfamily3}>
                                        <div className={stylesTableBodyMobile.title}>{column.cname}</div>
                                        <div className={stylesTableBodyMobile.titleText}>{row[`${column.value}`]}</div>
                                    </div>
                                ) : null




                            ) : null



                        })}
                    </div>
                </div>
            ))}


        </div>
    );
};

export default TableBodyMobile;

/*

<div className={stylesTableBodyMobile.cardArea}>
            {rows.map((row, index) => (
                <div className={stylesTableBodyMobile.card}>
                    {columns.map((column, index) => {
                        if (column.ctypemobile === true) {
                            return (<>
                                <div className={stylesTableBodyMobile.title}>{column.value}</div>
                                <div className={stylesTableBodyMobile.titleText}>{row[`${column.value}`]}</div></>)
                        }
                    })}
                </div>

            ))}


        </div>

*/


/*

<>
            <div className={stylesTableBodyMobile.cardArea}><div className={stylesTableBodyMobile.card}>
                <div className={stylesTableBodyMobile.cascadingfamily1}>
                    <div className={stylesTableBodyMobile.statusText}>Status</div>
                    <div className={stylesTableBodyMobile.numberText}>TO-RD-AK-030223-001</div>
                </div>
                <div>
                    <div className={stylesTableBodyMobile.cascadingfamily2}>
                        <div>
                            <div className={stylesTableBodyMobile.title}>To</div>
                            <div className={stylesTableBodyMobile.titleText}>ArzuK</div>
                        </div>
                        <div>
                            <div className={stylesTableBodyMobile.title}>Due Date</div>
                            <div className={stylesTableBodyMobile.titleText}>03.02.2023</div>
                        </div>


                    </div>
                </div>
                <div className={stylesTableBodyMobile.cascadingfamily3}>
                    <div className={stylesTableBodyMobile.title}>Description</div>
                    <div className={stylesTableBodyMobile.titleText}>Phasellus et semper mi, in luctus orci. Cras mollis magna nec sem posuere accumsan.</div>
                </div></div></div>
        </>

*/



/*

 

        <div className={stylesTableBodyMobile.cardArea}>
            {rows.map((row, index) => (
                <div className={stylesTableBodyMobile.card}>
                    {columns.map((column, index) => {

                        return column.ctypemobile === true ? (

                            column.ctypecardfamily === "header" ? (
                                <div className={stylesTableBodyMobile.cascadingfamily1}>
                                    <div className={stylesTableBodyMobile.statusText}>{column.ctypecardposition === "status" ? row[`${column.value}`] : ""}</div>
                                    <div className={stylesTableBodyMobile.numberText}>{column.ctypecardposition === "number" ? row[`${column.value}`] : ""}</div>
                                </div>

                            ) : column.ctypecardfamily === "double" && column.ctypecardposition === "start" ? (
                                <div>
                                    <div className={stylesTableBodyMobile.cascadingfamily2}>
                                        <div>
                                            <div className={stylesTableBodyMobile.title}>{column.value}</div>
                                            <div className={stylesTableBodyMobile.titleText}>{row[`${column.value}`]}</div>
                                        </div>
                                        <div>
                                            <div className={stylesTableBodyMobile.title}>{columns.find(o => (o.ctypecard === column.ctypecard && column.ctypecardfamily === "double")).value}</div>
                                            <div className={stylesTableBodyMobile.titleText}>{row[`${columns.find(o => (o.ctypecard === column.ctypecard && column.ctypecardfamily === "double")).value}`]}</div>
                                        </div>


                                    </div>
                                </div>
                            ) : column.ctypecardfamily === "single" ? (
                                <div className={stylesTableBodyMobile.cascadingfamily3}>
                                    <div className={stylesTableBodyMobile.title}>{column.value}</div>
                                    <div className={stylesTableBodyMobile.titleText}>{row[`${column.value}`]}</div>
                                </div>
                            ) : null




                        ) : null



                    })}
                </div>

            ))}


        </div>




        */