import React, { useEffect, useState } from "react";

import Location from "./Location";

const LocationCascading = ({
  setSelectedCountry,
  country,
  onChangeCountry,
  setSelectedCity,
  selectedCityList,
  onChangeCity,
  setSelectedDistrict,
  selectedDistrictList,
  onChangeDistrict,
  initialCountry,
  initialCity,
  initialDistrict,
}) => {
  const [data, setData] = useState();
  const [dataCity, setDataCity] = useState();
  const [dataDistrict, setDataDistrict] = useState();

  const [initialValueCountry, setInitialValueCountry] =
    useState(initialCountry);
  const [initialValueCity, setInitialValueCity] = useState(initialCity);
  const [initialValueDistrict, setInitialValueDistrict] =
    useState(initialDistrict);

  const [selectedValue, setSelectedValue] = useState();
  const [selectedValueCity, setSelectedValueCity] = useState();
  const [selectedValueDistrict, setSelectedValueDistrict] = useState();

  useEffect(() => {
    if (country !== undefined) {
      setData(country);
    }
  }, [country]);
  console.log("datause", data);

  useEffect(() => {
    if (selectedCityList !== undefined) {
      setDataCity(selectedCityList);
      setInitialValueCountry("");
      setSelectedValueCity("");
      setSelectedValueDistrict("");
      setInitialValueCity("");
      setInitialValueDistrict("");
    }
  }, [selectedCityList]);

  useEffect(() => {
    if (selectedDistrictList !== undefined) {
      setDataDistrict(selectedDistrictList);

      setSelectedValueDistrict("");
      setInitialValueCity("");
      setInitialValueDistrict("");
    }
  }, [selectedDistrictList]);

  return (
    <React.Fragment>
      <Location
        id="country"
        setFunc={setSelectedCountry}
        onChange={onChangeCountry}
        name="country"
        initialValue={initialValueCountry}
        data={data}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        label="Country"
      ></Location>

      <Location
        id="city"
        setFunc={setSelectedCity}
        onChange={onChangeCity}
        name="city"
        initialValue={initialValueCity}
        data={dataCity}
        selectedValue={selectedValueCity}
        setSelectedValue={setSelectedValueCity}
        label="City"
      ></Location>

      <Location
        id="district"
        setFunc={setSelectedDistrict}
        // datalist={selectedDistrictList}
        onChange={onChangeDistrict}
        name="district"
        initialValue={initialValueDistrict}
        data={dataDistrict}
        selectedValue={selectedValueDistrict}
        setSelectedValue={setSelectedValueDistrict}
        label="District"
      ></Location>
    </React.Fragment>
  );
};

export default LocationCascading;
