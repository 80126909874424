import React, { useEffect, useState, useContext } from "react";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
const TableFinancialApproveForm = ({ selectedID, tableUrl, isChecked, closeTechApproveModalHandler, setRefreshData, refreshData, setIsChecked }) => {
  const auth = useContext(AuthContext);
  const [notes, setNotes] = useState('');
  const { error, sendRequest, clearError } = useHttpClient();
  const [isLoading, setIsLoading] = useState(false);




  //const submitApprove = async () => {
  async function submitApprove() {
    setIsLoading(true);

    console.log("selectedID", selectedID);


    try {

      await sendRequest(
        `${process.env.REACT_APP_API_URL}/api2/financialapprove `,
        "POST",
        JSON.stringify({
          id: selectedID,
          notes: notes
        }),
        {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + auth.token,
        }
      );
      setIsLoading(false);
      setNotes('');
      setRefreshData(!refreshData);
      closeTechApproveModalHandler();
    } catch (err) {
      setIsLoading(false);
      alert(err);
      console.log(err);
      closeTechApproveModalHandler();
    }


  }
  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }
  return (<React.Fragment>

    {!isLoading && (
      <div >

        <input type="text" value={notes} placeholder="Financal Approval notes.." id="notes"
          onChange={(e) => setNotes(e.target.value)}
        />
        <button onClick={submitApprove}>Approve</button>
        {isLoading && <LoadingSpinner asOverlay />}
      </div>
    )}
  </React.Fragment>);
};

export default TableFinancialApproveForm;
