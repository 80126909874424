import React, { useEffect, useState, useContext } from "react";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import CustomSelect from "../../shared/components/FormElements/CustomSelect/CustomSelect";
import { useForm } from "../../shared/hooks/form-hook";
const TableUpdateOrderStatusForm = ({ selectedID, tableUrl, isChecked, closeUpdateOrderStatusModalHandler, setRefreshData, refreshData, setIsChecked }) => {
    const auth = useContext(AuthContext);
    const [formState, inputHandler] = useForm(
        {
            orderstatus: {
                value: '',
                isValid: false
            },

        },
        false
    );
    const { error, sendRequest, clearError } = useHttpClient();
    const [isLoading, setIsLoading] = useState(false);
    const [dropdownData, setDropDownData] = useState([]);
    let indexDropDown = [];
    useEffect(() => {

        const getDropdownData = async () => {
            setIsLoading(true);

            try {
                const responseColumns2 = await fetch(
                    `${process.env.REACT_APP_API_URL}/api2/dropdown/orderstatus`,

                    {
                        method: "GET",
                    }
                );

                const responseColumnsData2 = await responseColumns2.json();

                if (!responseColumns2.ok) {
                    throw new Error(responseColumnsData2.message);
                }
                console.log(
                    "🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏",
                    responseColumnsData2
                );
                let changeDropDownName = [];
                let categorymapp = Object.values(responseColumnsData2)
                    .reduce(
                        (concatedArr, item) =>
                            concatedArr.concat(Object.entries(item)),
                        []
                    )
                    .reduce((result, [category, values]) => {
                        result["intvalue"] = result["intvalue"] || [];
                        result["intvalue"] = result["intvalue"].concat({
                            name: values,
                        });

                        changeDropDownName = [
                            ...changeDropDownName,
                            {
                                name: values,
                            },
                        ];

                        return result;
                    }, {});

                indexDropDown[0] = changeDropDownName;

                const indexDropDownToJSON = JSON.parse(
                    JSON.stringify(indexDropDown)
                );

                setDropDownData(indexDropDownToJSON);
            } catch (err) {
                console.log(err.message);
            }
            setIsLoading(false);
        };
        getDropdownData();
    }, []);
    console.log("😱😱😱😱😱😱😱😱😱😱😱😱😱", formState.inputs.orderstatus);

    async function submitApprove() {
        setIsLoading(true);

        console.log("selectedID", selectedID);


        try {

            await sendRequest(
                `${process.env.REACT_APP_API_URL}/api2/updateorder `,
                "POST",
                JSON.stringify({
                    id: isChecked,
                    value: formState.inputs.orderstatus.value,
                    field: "orderstatus",
                    tableUrl: tableUrl,
                }),
                {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + auth.token,
                }
            );
            setIsLoading(false);
            //  setOrderNotes('');
            setRefreshData(!refreshData);
            closeUpdateOrderStatusModalHandler();
        } catch (err) {
            setIsLoading(false);
            alert(err);
            console.log(err);
            closeUpdateOrderStatusModalHandler();
        }


    }
    if (isLoading) {
        return (
            <div className="center">
                <LoadingSpinner />
            </div>
        );
    }
    return (<React.Fragment>
        <div>Update Order Status</div>
        {!isLoading && (

            <div >
                <CustomSelect
                    id={"orderstatus"}
                    onChange={inputHandler}
                    data={dropdownData[0]}
                    name={"orderstatus"}
                    label={"Order Status"}
                ></CustomSelect>
                <button onClick={submitApprove}>Update</button>
                {isLoading && <LoadingSpinner asOverlay />}
            </div>
        )}
    </React.Fragment>);
};

export default TableUpdateOrderStatusForm;
