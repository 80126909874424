import React, { useRef, useState, useEffect } from "react";

import Button from "../Button";
import "./ImageUpload.css";

const AttachmentUpload = (props) => {
  const [isValid, setIsValid] = useState(false);

  const filePickerRef = useRef();

  const [showButton, setShowButton] = useState(false);

  const [showUploadedFiles, setShowUploadedFiles] = useState(false);
  const [showFileList, setShowFileList] = useState(false);

  const [fileList, setFileList] = useState([]);

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const initialValue = props.initialValue;
  const initialValid = props.initialValid;

  console.log(
    "👅 👅 👅 👅 👅 👅 👅 👅 👅 👅 👅 👅 👅 👅 👅 👅 👅 ",
    initialValid
  );

  const MAX_COUNT = 5;

  const [fileLimit, setFileLimit] = useState(false);

  console.log("initialValue", initialValue);
  console.log("fileList", fileList);
  console.log("uploadedFiles", uploadedFiles);

  useEffect(() => {
    if (initialValue !== undefined) {
      initialFiles = initialValue;
      setFileList(initialFiles);
      setIsValid(initialValid);
      console.log("CCCCCC");
    } else setFileList("");
  }, [initialValue, initialValid]);

  useEffect(() => {
    if (uploadedFiles.length > 1) {
      const multipleFiles = [...new Set(uploadedFiles?.map((q) => q))];
      console.log("EDITuploadedFiles", uploadedFiles);
      let i;

      console.log("ZAKZUK", multipleFiles);
      props.onInput("attachmentfile", multipleFiles, isValid);
    } else {
      const singleFile = uploadedFiles[0];
      console.log("ZAKZUK2", singleFile);
      if (uploadedFiles?.length === 0 && initialValid === true) {
        props.onInput("attachmentfile", undefined, true);
      } else if (uploadedFiles?.length === 0 && initialValid === undefined) {
        props.onInput("attachmentfile", undefined, isValid);
      } else {
        props.onInput("attachmentfile", singleFile, isValid);
      }
    }
  }, [uploadedFiles, isValid]);

  let initialFiles = [];

  useEffect(() => {
    if (fileList !== "") {
      setShowFileList(true);
    }
  }, [fileList]);

  useEffect(() => {
    if (uploadedFiles !== "" && uploadedFiles.length !== 0) {
      setShowUploadedFiles(true);
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [uploadedFiles]);

  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    console.log("uploaded", uploaded);

    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) {
          setFileLimit(true);
        }
        if (uploaded.length !== 0) {
          setIsValid(true);
        } else {
          setIsValid(false);
        }
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);

          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setUploadedFiles(uploaded);
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    console.log("chosenFiles", chosenFiles);
    handleUploadFiles(chosenFiles);
    setShowUploadedFiles(true);
  };

  const pickAttachmentHandler = () => {
    filePickerRef.current.click();
  };

  const removeAttachmentHandler = (removedFileName) => {
    console.log("REMOVEDFILENAME", removedFileName);

    const otbok =
      typeof removedFileName !== "undefined" &&
      fileList
        .filter((name) => (removedFileName ? name === removedFileName : true))
        .map((el) => el);

    const otbok2 =
      typeof removedFileName !== "undefined" &&
      fileList
        .filter((name) => (removedFileName ? name !== removedFileName : true))
        .map((el) => el);

    setFileList(otbok2);
    if (otbok2?.length === 0) {
      setIsValid(false);
    }
    console.log("OTBOKKKKKKKKK", otbok);
    props.setRemovedFileName(otbok);
  };

  const removeNewAttachmentHandler = (removedFile) => {
    const otbok =
      typeof removedFile !== "undefined" &&
      uploadedFiles
        .filter(({ name }) => (removedFile ? name !== removedFile : true))
        .map((el) => el);
    if (uploadedFiles?.length === 0) {
      setIsValid(false);
    }
    setUploadedFiles(otbok);
  };

  console.log("uploadedFilesafterremove", uploadedFiles);

  return (
    <React.Fragment>
      <div className="form-control">
        <input
          id={props.id}
          name="attachmentfile"
          // id="fileinput"
          ref={filePickerRef}
          style={{ display: "none" }}
          type="file"
          accept=".jpg,.png,.jpeg,.pdf,.xls,.xlsx"
          onChange={handleFileEvent}
          multiple="multiple"
          disabled={fileLimit}
        />
        {showUploadedFiles && uploadedFiles.length !== 0 && (
          <div>
            {uploadedFiles?.map((file) => {
              return (
                <>
                <div className="file_picker_out_row">
                <div className="file_picker_file_row">
                  <label>{file.name}</label>
                  </div>
                  <div className="file_picker_remove_row">
                  <button
                    type="button"
                    //style={{
                    //    display: uploadedFiles.length === 0 ? "none" : "block",
                    //</>}}
                    onClick={() => removeNewAttachmentHandler(file.name)}
                  >
                   X
                  </button>
                  </div>
                  </div>
                 
                </>
               
              );
            })}
          </div>
        )}

        {showFileList && fileList !== "" && (
          <div>
            {fileList?.map((file) => {
              return (
                <>
                  <label>{file.split("/")[file.split("/").length - 1]}</label>

                  <button
                    type="button"
                    style={{
                      display: fileList === "" ? "none" : "block",
                    }}
                    onClick={() => removeAttachmentHandler(file)}
                  >
                    REMOVE2
                  </button>
                </>
              );
            })}
          </div>
        )}

        {!isValid && <p>{props.errorText}</p>}
      </div>

      <div>
        <Button size = "3" type="button" onClick={pickAttachmentHandler}>
         Pick File
        </Button>
      </div>
    </React.Fragment>
  );
};

export default AttachmentUpload;
