import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import stylessEditForm from "./EditForm.module.css";
import Input from "../../shared/components/FormElements/Input";
import Button from "../../shared/components/FormElements/Button";
import CustomSelect from "../../shared/components/FormElements/CustomSelect/CustomSelect";
import LocationCascading from "../../shared/components/FormElements/Location/LocationCascading";
import MultiSelect from "../../shared/components/FormElements/MultiSelect/MultiSelect";
import AttachmentUpload from "../../shared/components/FormElements/FileUpload/AttachmentUpload";
import AvatarUpload from "../../shared/components/FormElements/FileUpload/AvatarUpload";
import ImageUpload from "../../shared/components/FormElements/FileUpload/ImageUpload";
import DateComp from "../../shared/components/FormElements/Date";
import AssignmentCascading from "../../shared/components/FormElements/Assignment/AssignmentCascading";
import MaterialCascading from "../../shared/components/FormElements/Material/MaterialCascading";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import Modal from "../../shared/components/UIElements/Modal";
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_MINLENGTH,
} from "../../shared/util/validators";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";

const EditForm = ({
  selectedID,
  tableUrl,
  setRefreshData,
  refreshData,
  closeEditDrawerHandler,
  setIsChecked,
  setIsCheckedFile,
  setSelectedID,
  checkedlist,
  setCheckedList,
  setIsCheckedAvatar,
  setIsCheckedImage,
}) => {
  const auth = useContext(AuthContext);
  const [columns, setColumns] = useState([]);
  const [columnData, setColumnData] = useState({});
  const { error, sendRequest, clearError } = useHttpClient();
  const [isLoading, setIsLoading] = useState(false);
  const [loadedItem, setLoadedItem] = useState([]);
  const itemId = `${selectedID}`;

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  const [formState, inputHandler, setFormData] = useForm(columnData, false);

  const [removedFileName, setRemovedFileName] = useState("");
  const [attachmentInitialValue, setAttachmentInitialValue] = useState([]);
  const [book, setBook] = useState([]);

  const [attachmentUpdatedValue, setAttachmentUpdatedValue] = useState([]);

  const [attachmentNameUpdatedValue, setAttachmentNameUpdatedValue] = useState(
    []
  );

  const [removedAvatarName, setRemovedAvatarName] = useState();
  const [removedImageName, setRemovedImageName] = useState();

  let choosen = [];
  let choosenAtt = [];

  useEffect(() => {
    if (loadedItem.attachmentname !== undefined) {
      choosen = loadedItem.attachmentname;
      setAttachmentNameUpdatedValue(choosen);
    }
  }, [loadedItem.attachmentname]);

  useEffect(() => {
    if (loadedItem.attachmentpath !== undefined) {
      choosenAtt = loadedItem.attachmentpath;
      setAttachmentUpdatedValue(choosenAtt);
    }
  }, [loadedItem.attachmentpath]);

  useEffect(() => {
    if (removedFileName !== "") {
      setBook((searches) => searches.concat(removedFileName));
    }
  }, [removedFileName]);

  console.log("BOOOOOK", book);

  // MATERIAL STATES START
  const [material, setMaterial] = useState([]);

  const [selectedModelList, setSelectedModelList] = useState([]);
  // const [selectedSize, setSelectedSize] = useState([]);
  const [selectedSizeList, setSelectedSizeList] = useState();

  // const [selectedDescription, setSelectedDescription] = useState([]);
  const [selectedDescriptionList, setSelectedDescriptionList] = useState();

  // const [selectedUnit, setSelectedUnit] = useState([]);
  const [selectedUnitList, setSelectedUnitList] = useState();

  // const [selectedBarcode, setSelectedBarcode] = useState([]);
  const [selectedBarcodeList, setSelectedBarcodeList] = useState();

  // const [selectedWeight, setSelectedWeight] = useState([]);
  const [selectedWeightList, setSelectedWeightList] = useState();

  const [selectedWeightUnit, setSelectedWeightUnit] = useState([]);
  const [selectedWeightUnitList, setSelectedWeightUnitList] = useState();

  const brandmark = [...new Set(material?.map((q) => q.brandmark))];

  const setSelectedBrandmark = (selectedBrandmark) => {
    setSelectedModelList([]);
    setSelectedSizeList([]);
    setSelectedDescriptionList([]);
    setSelectedUnitList([]);
    setSelectedBarcodeList([]);
    setSelectedWeightList([]);
    setSelectedWeightUnitList([]);
    const otbok =
      typeof selectedBrandmark !== "undefined" &&
      material
        .filter(({ brandmark }) =>
          selectedBrandmark ? brandmark === selectedBrandmark : true
        )
        .map((el) => el.model);

    const uniqueNames =
      otbok !== false ? Array.from(new Set(otbok?.map((q) => q))) : [];

    const arr = JSON.parse(JSON.stringify(uniqueNames));

    setSelectedModelList(arr);
  };

  console.log(
    "🫁 🧠🫁 🧠🫁 🧠🫁 🧠🫁 🧠🫁 🧠🫁 🧠🫁 🧠🫁 🧠🫁 🧠🫁 🧠🫁 🧠🫁 🧠🫁 🧠",
    selectedModelList
  );

  const setSelectedModel = (selectedModel) => {
    const otbok =
      typeof selectedModel !== "undefined" &&
      material
        .filter(({ model }) => (selectedModel ? model === selectedModel : true))
        .map((el) => el.size);

    const uniqueNames =
      otbok !== false ? Array.from(new Set(otbok?.map((q) => q))) : [];

    const arr = JSON.parse(JSON.stringify(uniqueNames));

    setSelectedSizeList(arr);
  };
  console.log("👻👻👻👻👻👻👻👻👻👻👻👻👻👻👻👻", selectedSizeList);

  const setSelectedSize = (selectedSize) => {
    const description =
      typeof selectedSize !== "undefined" &&
      material
        .filter(({ size }) => (selectedSize ? size === selectedSize : true))
        .map((el) => el.description);

    const descriptionUniqueNames =
      description !== false
        ? Array.from(new Set(description?.map((q) => q)))
        : [];

    const descriptionArr = JSON.parse(JSON.stringify(descriptionUniqueNames));

    setSelectedDescriptionList(descriptionArr);

    const unit =
      typeof selectedSize !== "undefined" &&
      material
        .filter(({ size }) => (selectedSize ? size === selectedSize : true))
        .map((el) => el.unit);

    const unitUniqueNames =
      unit !== false ? Array.from(new Set(unit?.map((q) => q))) : [];

    const unitArr = JSON.parse(JSON.stringify(unitUniqueNames));

    setSelectedUnitList(unitArr);

    const barcode =
      typeof selectedSize !== "undefined" &&
      material
        .filter(({ size }) => (selectedSize ? size === selectedSize : true))
        .map((el) => el.barcode);

    const barcodeUniqueNames =
      barcode !== false ? Array.from(new Set(barcode?.map((q) => q))) : [];

    const barcodeArr = JSON.parse(JSON.stringify(barcodeUniqueNames));

    setSelectedBarcodeList(barcodeArr);

    const weight =
      typeof selectedSize !== "undefined" &&
      material
        .filter(({ size }) => (selectedSize ? size === selectedSize : true))
        .map((el) => el.weight);

    const weightUniqueNames =
      weight !== false ? Array.from(new Set(weight?.map((q) => q))) : [];

    const weightArr = JSON.parse(JSON.stringify(weightUniqueNames));

    setSelectedWeightList(weightArr);

    const weightUnit =
      typeof selectedSize !== "undefined" &&
      material
        .filter(({ size }) => (selectedSize ? size === selectedSize : true))
        .map((el) => el.weightunit);

    const weightUnitUniqueNames =
      weightUnit !== false
        ? Array.from(new Set(weightUnit?.map((q) => q)))
        : [];

    const weightUnitArr = JSON.parse(JSON.stringify(weightUnitUniqueNames));

    setSelectedWeightUnitList(weightUnitArr);
  };
  console.log("🧠 🧠 🧠 🧠 🧠 🧠 🧠 🧠", selectedDescriptionList);

  // MATERIAL STATES END

  // ASSIGNMENT STATES START
  const [assignment, setAssignment] = useState([]);

  const [selectedAssignmentList, setSelectedAssignmentList] = useState([]);

  const department = [...new Set(assignment?.map((q) => q.department))];

  console.log("👻👻👻👻👻👻👻👻👻👻👻👻👻👻👻👻", department);

  const setSelectedDepartment = (selectedDepartment) => {
    setSelectedAssignmentList([]);

    const otbok =
      typeof selectedDepartment !== "undefined" &&
      assignment
        .filter(({ department }) =>
          selectedDepartment ? department === selectedDepartment : true
        )
        .map((el) => el.assignment);

    const uniqueNames =
      otbok !== false ? Array.from(new Set(otbok?.map((q) => q))) : [];

    const arr = JSON.parse(JSON.stringify(uniqueNames));

    setSelectedAssignmentList(arr);
  };

  console.log("selectedAssignmentList", selectedAssignmentList);
  console.log("selectedSizeList", selectedSizeList);
  const setSelectedAssignment = (selectedAssignment) => {
    const otbok =
      typeof selectedAssignment !== "undefined" &&
      assignment.filter(({ assignment }) =>
        selectedAssignment ? assignment === selectedAssignment : true
      );
  };

  // ASSIGNMENT STATES END
  useEffect(() => {
    if (removedFileName !== "") {
      const otbok2 =
        typeof removedFileName !== "undefined" &&
        attachmentNameUpdatedValue
          .filter((value) =>
            removedFileName[0] ? value !== removedFileName[0] : true
          )
          .map((el) => el);

      setAttachmentNameUpdatedValue(otbok2);

      const otbok3 =
        typeof removedFileName !== "undefined" &&
        attachmentUpdatedValue
          .filter((value) =>
            removedFileName[0] ? !value.includes(removedFileName[0]) : true
          )
          .map((el) => el);

      setAttachmentUpdatedValue(otbok3);

      inputHandler("attachmentname", otbok2, true);
      inputHandler("attachmentpath", otbok3, true);
    }
  }, [removedFileName]);

  let initialFiles = [];

  useEffect(() => {
    if (loadedItem.attachmentname !== undefined) {
      initialFiles = loadedItem.attachmentname;
      setAttachmentInitialValue(initialFiles);
      console.log("AAAAA");
    } else setAttachmentInitialValue("");
  }, [loadedItem.attachmentname]);

  useEffect(() => {
    if (removedAvatarName !== "") {
      inputHandler("avatarname", [], true);
      inputHandler("avatarpath", [], true);
    }
  }, [removedAvatarName]);

  console.log("removedAvatarName!!!!!!!!!!!!!!!!!!!!!!!", removedAvatarName);

  useEffect(() => {
    if (removedImageName !== "") {
      inputHandler("imagename", [], true);
      inputHandler("imagepath", [], true);
    }
  }, [removedImageName]);

  const [dropdownData, setDropDownData] = useState([]);

  const [dropdown, setDropDown] = useState([]);
  const [collectDropDownName, setCollectDropDownName] = useState([]);

  const [location, setLocation] = useState([]);
  const [product, setProduct] = useState([]);

  const [selectedCityList, setSelectedCityList] = useState();
  const [selectedDistrict, setSelectedDistrict] = useState();

  const [selectedDistrictList, setSelectedDistrictList] = useState();

  const country = [...new Set(location?.map((q) => q.country))];

  const setSelectedCountry = (selectedCountry) => {
    const otbok =
      typeof selectedCountry !== "undefined" &&
      location
        .filter(({ country }) =>
          selectedCountry ? country === selectedCountry : true
        )
        .map((el) => el.city);

    const uniqueNames =
      otbok !== false ? Array.from(new Set(otbok?.map((q) => q))) : [];

    const arr = JSON.parse(JSON.stringify(uniqueNames));

    setSelectedCityList(arr);
  };

  const setSelectedCity = (selectedCity) => {
    const otbok =
      typeof selectedCity !== "undefined" &&
      location
        .filter(({ city }) => (selectedCity ? city === selectedCity : true))
        .map((el) => el.district);

    const uniqueNames =
      otbok !== false ? Array.from(new Set(otbok?.map((q) => q))) : [];

    const arr = JSON.parse(JSON.stringify(uniqueNames));

    setSelectedDistrictList(arr);
  };

  let indexDropDown = [];

  useEffect(() => {
    const fetchItem = async () => {
      try {
        const responseData =
          itemId !== undefined
            ? await sendRequest(
              `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/${itemId}`
            )
            : null;

        setLoadedItem(responseData);

        const responseColumns =
          itemId !== undefined
            ? await fetch(`${process.env.REACT_APP_API_URL}/api2/${tableUrl}/column`, {
              method: "GET",
            })
            : null;

        const responseColumnsData = await responseColumns.json();

        if (!responseColumns.ok) {
          throw new Error(responseColumnsData.message);
        }

        setColumns(responseColumnsData);

        //EKLEME BURADAN BAŞLIYOR

        let collectDropDown = [];
        for (let i = 0; i < responseColumnsData.length; i++) {
          if (responseColumnsData[i].ctypeselection === true) {
            collectDropDown = [
              ...collectDropDown,
              responseColumnsData[i].value,
            ];
          }
        }

        console.log("collectDropDown", collectDropDown);
        setCollectDropDownName(collectDropDown);

        const numrows = collectDropDown.length;

        const blah = Array.from(Array(numrows).keys());
        console.log("BLAH", blah);

        for (let i = 0; i < blah.length; i++) {
          const sendRequest = async () => {
            //  setIsLoading(true);

            try {
              const responseColumns2 = await fetch(
                `${process.env.REACT_APP_API_URL}/api2/dropdown/${collectDropDown[i]}`,

                {
                  method: "GET",
                }
              );

              const responseColumnsData2 = await responseColumns2.json();

              if (!responseColumns2.ok) {
                throw new Error(responseColumnsData2.message);
              }

              let changeDropDownName = [];
              let categorymapp = Object.values(responseColumnsData2)
                .reduce(
                  (concatedArr, item) =>
                    concatedArr.concat(Object.entries(item)),
                  []
                )
                .reduce((result, [category, values]) => {
                  result["intvalue"] = result["intvalue"] || [];
                  result["intvalue"] = result["intvalue"].concat({
                    name: values,
                  });

                  changeDropDownName = [
                    ...changeDropDownName,
                    {
                      name: values,
                    },
                  ];

                  return result;
                }, {});

              indexDropDown[i] = changeDropDownName;

              const indexDropDownToJSON = JSON.parse(
                JSON.stringify(indexDropDown)
              );

              setDropDownData(indexDropDownToJSON);

              const numrows2 = indexDropDownToJSON.length;

              const blah2 = Array.from(Array(numrows2).keys());
              console.log("BLAH", blah2);

              setDropDown(blah2);
            } catch (err) { }
            // setIsLoading(false);
          };
          sendRequest();
        }

        // LOCATION BURADAN BASLIYOR

        let collectCascadingPRE = [];
        for (let i = 0; i < responseColumnsData.length; i++) {
          if (responseColumnsData[i].ctypecascading === true) {
            collectCascadingPRE = [
              ...collectCascadingPRE,
              responseColumnsData[i].ctypecascadingfamily,
            ];
          }
        }
        const collectCascading =
          collectCascadingPRE !== false
            ? Array.from(new Set(collectCascadingPRE?.map((q) => q)))
            : [];

        console.log("collectCascading", collectCascading);
        const numrows3 = collectCascading.length;

        const blah3 = Array.from(Array(numrows3).keys());
        console.log("BLAH3", blah3);

        if (collectCascading.length !== 0) {
          for (let i = 0; i < blah3.length; i++) {
            const sendRequest = async () => {
              //  setIsLoading(true);

              try {
                const responseColumns3 = await fetch(
                  `${process.env.REACT_APP_API_URL}/api2/cascading/${collectCascading[i]}`,

                  {
                    method: "GET",
                  }
                );

                const responseColumnsData3 = await responseColumns3.json();

                if (!responseColumns3.ok) {
                  throw new Error(responseColumnsData3.message);
                }
                if (collectCascading[i] === "location") {
                  setLocation(responseColumnsData3);
                } else if (collectCascading[i] === "material") {
                  setMaterial(responseColumnsData3);
                } else if (collectCascading[i] === "assignment") {
                  setAssignment(responseColumnsData3);
                }
              } catch (err) { }
              // setIsLoading(false);
            };
            sendRequest();
          }
        }

        //LOCATION BURADA BITIYOR

        // PRODUCT BURADAN BASLIYOR

        let collectProduct = [];
        for (let i = 0; i < responseColumnsData.length; i++) {
          if (responseColumnsData[i].ctypemultiselect === true) {
            collectProduct = [...collectProduct, responseColumnsData[i].value];
          }
        }

        console.log("collectProduct", collectProduct);

        const numrows2 = collectProduct.length;

        const blah2 = Array.from(Array(numrows2).keys());
        console.log("BLAH2", blah2);

        if (collectProduct.length !== 0) {
          for (let i = 0; i < blah2.length; i++) {
            const sendRequest = async () => {
              // setIsLoading(true);

              try {
                const responseColumns4 = await fetch(
                  `${process.env.REACT_APP_API_URL}/api2/multiselect/${collectProduct[i]}`,

                  {
                    method: "GET",
                  }
                );

                const responseColumnsData4 = await responseColumns4.json();

                if (!responseColumns4.ok) {
                  throw new Error(responseColumnsData4.message);
                }

                setProduct(responseColumnsData4);
              } catch (err) { }
              // setIsLoading(false);
            };
            sendRequest();
          }
        }

        //PRODUCT BURADA BITIYOR

        //EKLEME BURADA BITIYOR

        const ctypeEdit = responseColumnsData
          .filter(({ ctypeedit }) => ctypeedit === true)
          .map((el) => el);

        const uniqueNames =
          ctypeEdit !== false
            ? Array.from(new Set(ctypeEdit?.map((q) => q)))
            : [];

        const arr = JSON.parse(JSON.stringify(uniqueNames));

        let receivedValues = {};
        let sendValues = {};
        let categorymapp = Object.values(arr)
          .reduce(
            (concatedArr, item) => concatedArr.concat(Object.entries(item)),
            []
          )
          .filter((pilot) => pilot[0] === "value")
          .reduce((result, [category, values]) => {
            result["intvalue"] = result["intvalue"] || [];

            result["intvalue"] = result["intvalue"].concat({
              [values]: {
                value: responseData[values],
                isValid: true,
              },
            });

            receivedValues = result;

            return result;
          }, {});

        for (let i = 0; i < categorymapp["intvalue"].length; i++) {
          Object.assign(sendValues, categorymapp["intvalue"][i]);
        }

        setColumnData(sendValues);

        setFormData(sendValues, true);
        console.log("sendvalues", sendValues);
      } catch (err) { }
    };
    fetchItem();
  }, [sendRequest, itemId, setFormData]);

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!loadedItem && !error) {
    return (
      <div className="center">
        <div>
          <h2>Could not find place!</h2>
        </div>
      </div>
    );
  }

  const placeUpdateSubmitHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (
      formState.inputs.attachmentfile?.value !== undefined &&
      formState.inputs.attachmentfile?.value.length > 1
    ) {
      let i;
      try {
        var formData = new FormData();

        for (var key in formState.inputs) {
          console.log(
            "typeof formState.inputs.key?.value",
            typeof formState.inputs[key].value
          );
          if (key === "attachmentfile") {
            for (
              i = 0;
              i < formState.inputs.attachmentfile?.value.length;
              i++
            ) {
              formData.append(key, formState.inputs[key].value[i]);
            }
          } else {
            formData.append(key, formState.inputs[key].value);
          }
        }

        await sendRequest(
          `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/${itemId}/multipleupload`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setIsChecked([]);
        setIsCheckedFile([]);
        setIsCheckedAvatar([]);
        setIsCheckedImage([]);
        setSelectedID();
        //check list false yapmak icin
        console.log(" 👀 👀 👀 👀 👀 👀 👀 👀 👀");
        const arr = Array(checkedlist.length).fill(false);
        console.log(arr);
        setCheckedList(arr);
        //check list false yapmak icin
        setRefreshData(!refreshData);
        setIsLoading(false);
        closeEditDrawerHandler();
      } catch (err) {
        console.log("err", err);
      }
      if (removedAvatarName !== "") {
        try {
          removedAvatarName[0] = removedAvatarName[0].replaceAll("/", "-");
          await sendRequest(
            `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/${itemId}/editfiledelete/${removedAvatarName}`,
            "DELETE",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) { }
      }

      if (removedImageName !== "") {
        try {
          removedImageName[0] = removedImageName[0].replaceAll("/", "-");
          await sendRequest(
            `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/${itemId}/editfiledelete/${removedImageName}`,
            "DELETE",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) { }
      }

      if (book.length !== 0) {
        for (let i = 0; i < book.length; i++) {
          book[i] = book[i].replaceAll("/", "-");
          const fetchItem = async () => {
            try {
              const response = await sendRequest(
                `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/${itemId}/editfiledelete/${book[i]}`,

                "DELETE",
                null,

                {
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {
              console.log(err);
            }
          };
          fetchItem();
        }
      }
    } else if (
      formState.inputs.attachmentfile?.value !== undefined ||
      (formState.inputs.avatarfile?.value !== "undefined" &&
        formState.inputs.avatarfile?.value !== undefined &&
        formState.inputs.avatarfile?.value !== "") ||
      (formState.inputs.imagefile?.value !== "undefined" &&
        formState.inputs.imagefile?.value !== undefined &&
        formState.inputs.avatarfile?.value !== "")
    ) {
      try {
        var formData = new FormData();
        for (var key in formState.inputs) {
          if (key !== "") {
            formData.append(key, formState.inputs[key].value);
          }
        }

        await sendRequest(
          `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/${itemId}/singleupload`,
          "PATCH",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setIsChecked([]);
        setIsCheckedFile([]);
        setIsCheckedAvatar([]);
        setIsCheckedImage([]);
        setSelectedID();
        //check list false yapmak icin
        console.log("🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡🤡");
        const arr = Array(checkedlist.length).fill(false);
        console.log(arr);
        setCheckedList(arr);
        //check list false yapmak icin
        setRefreshData(!refreshData);
        setIsLoading(false);
        closeEditDrawerHandler();
      } catch (err) {
        console.log("err", err);
      }
      if (removedAvatarName !== "") {
        try {
          removedAvatarName[0] = removedAvatarName[0].replaceAll("/", "-");
          await sendRequest(
            `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/${itemId}/editfiledelete/${removedAvatarName}`,
            "DELETE",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) { }
      }

      if (removedImageName !== "") {
        try {
          removedImageName[0] = removedImageName[0].replaceAll("/", "-");
          await sendRequest(
            `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/${itemId}/editfiledelete/${removedImageName}`,
            "DELETE",
            null,
            {
              Authorization: "Bearer " + auth.token,
            }
          );
        } catch (err) { }
      }
      if (book.length !== 0) {
        for (let i = 0; i < book.length; i++) {
          const fetchItem = async () => {
            try {
              book[i] = book[i].replaceAll("/", "-");
              await sendRequest(
                `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/${itemId}/editfiledelete/${book[i]}`,

                "DELETE",
                null,

                {
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {
              console.log(err);
            }
          };
          fetchItem();
        }
      }
    } else {
      try {
        await sendRequest(
          `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/${itemId}/singleupload`,
          "PATCH",

          JSON.stringify(
            Object.values(formState)
              .reduce(
                (concatedArr, item) => concatedArr.concat(Object.entries(item)),
                []
              )

              .reduce((result, [category, values]) => {
                console.log("değerler", category);
                if (
                  typeof values.value === "object" ||
                  category === "avatarname"
                ) {
                  console.log("123");
                  result[category] = result[category] || "";

                  result[category] = values.value;
                } else {
                  result[category] = result[category] || "";

                  result[category] = result[category].concat(values.value);
                }

                console.log("category", result[category]);
                console.log("authtoken", result);
                return result;
              }, {})
          ),

          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        setIsChecked([]);
        setIsCheckedFile([]);
        setIsCheckedAvatar([]);
        setIsCheckedImage([]);
        setSelectedID();
        //check list false yapmak icin
        console.log("🤯🤯🤯🤯🤯🤯🤯🤯🤯🤯🤯🤯🤯🤯🤯🤯");
        const arr = Array(checkedlist.length).fill(false);
        console.log(arr);
        setCheckedList(arr);
        //check list false yapmak icin
        setRefreshData(!refreshData);
        setIsLoading(false);
        closeEditDrawerHandler();
      } catch (err) {
        console.log("err", err);
      }
      if (removedAvatarName !== "") {
        const fetchItem = async () => {
          try {
            removedAvatarName[0] = removedAvatarName[0].replaceAll("/", "-");
            await sendRequest(
              `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/${itemId}/editfiledelete/${removedAvatarName[0]}`,
              "DELETE",
              null,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) { }
        };
        fetchItem();
      }
      if (removedImageName !== "") {
        const fetchItem = async () => {
          try {
            removedImageName[0] = removedImageName[0].replaceAll("/", "-");
            console.log("AOLLOOOOOOOOOOOOoOOO", removedImageName[0]);
            await sendRequest(
              `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/${itemId}/editfiledelete/${removedImageName[0]}`,
              "DELETE",
              null,
              {
                Authorization: "Bearer " + auth.token,
              }
            );
          } catch (err) { }
        };
        fetchItem();
      }
      if (book.length !== 0) {
        for (let i = 0; i < book.length; i++) {
          const fetchItem = async () => {
            try {
              book[i] = book[i].replaceAll("/", "-");

              await sendRequest(
                `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/${itemId}/editfiledelete/${book[i]}`,

                "DELETE",
                null,

                {
                  Authorization: "Bearer " + auth.token,
                }
              );
            } catch (err) {
              console.log(err);
            }
          };
          fetchItem();
        }
      }
    }

    setIsLoading(false);
    // refreshPage();
  };

  let k = 0; // dropdown index
  let d = 0; // date index

  //received dat formating START
  let collectDates = [];

  for (var key in loadedItem) {
    if (
      key.includes("date") &&
      !key.includes("created") &&
      !key.includes("updated")
    ) {
      collectDates = [...collectDates, loadedItem[key]];
    }
  }

  let initialDates = [];

  for (let i = 0; i < collectDates.length; ++i) {
    let receivedDate = collectDates[i];
    let receivedYear = receivedDate.slice(6);
    let receivedMonth = receivedDate.slice(3, 5);
    let receivedDay = receivedDate.slice(0, 2);
    const receivedDateFormatted = receivedYear.concat(
      "-",
      receivedMonth,
      "-",
      receivedDay
    );
    initialDates = [...initialDates, receivedDateFormatted];
  }

  //received dat formating END


  console.log("LOADED ITEM", loadedItem);

  console.log("FORMSTATE INPUTS", formState.inputs);
  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />

      {!isLoading && loadedItem && (
        <form className={stylessEditForm.place} onSubmit={placeUpdateSubmitHandler}>
          {isLoading && <LoadingSpinner asOverlay />}
          {screenWidth < 500 && < span className={stylessEditForm.closeicon} onClick={closeEditDrawerHandler}></span>}
          {columns.map((item, i) => {
            return item.ctypeedit ? (
              item.ctyperelease ? loadedItem["employeestatus"] === false ?

                item.ctypeselection ? (
                  <>
                    <CustomSelect
                      id={item.value}
                      onChange={inputHandler}
                      data={dropdownData[k]}
                      name={item.value}
                      label={item.cname}
                      value={loadedItem[item.value]}
                    ></CustomSelect>
                    <script>{(k = k + 1)}</script>
                  </>
                ) : item.ctypecascadingfamily === "location" &&
                  item.ctypecascadingdegree === "1" ? (
                  <LocationCascading
                    setSelectedCountry={setSelectedCountry}
                    country={country}
                    onChangeCountry={inputHandler}
                    setSelectedCity={setSelectedCity}
                    selectedCityList={selectedCityList}
                    onChangeCity={inputHandler}
                    setSelectedDistrict={setSelectedDistrict}
                    selectedDistrictList={selectedDistrictList}
                    onChangeDistrict={inputHandler}
                    initialCountry={loadedItem.country}
                    initialCity={loadedItem.city}
                    initialDistrict={loadedItem.district}
                  ></LocationCascading>
                ) : item.ctypecascadingfamily === "material" &&
                  item.ctypecascadingdegree === "1" ? (
                  <MaterialCascading
                    setSelectedBrandmark={setSelectedBrandmark}
                    brandmark={brandmark}
                    onChangeBrandmark={inputHandler}
                    setSelectedModel={setSelectedModel}
                    selectedModelList={selectedModelList}
                    onChangeModel={inputHandler}
                    setSelectedSize={setSelectedSize}
                    selectedSizeList={selectedSizeList}
                    onChangeSize={inputHandler}
                    setSelectedDescription={setSelectedDescriptionList}
                    selectedDescriptionList={selectedDescriptionList}
                    onChangeDescription={inputHandler}
                    setSelectedUnit={setSelectedUnitList}
                    selectedUnitList={selectedUnitList}
                    onChangeUnit={inputHandler}
                    setSelectedBarcode={setSelectedBarcodeList}
                    selectedBarcodeList={selectedBarcodeList}
                    onChangeBarcode={inputHandler}
                    setSelectedWeight={setSelectedWeightList}
                    selectedWeightList={selectedWeightList}
                    onChangeWeight={inputHandler}
                    setSelectedWeightUnit={setSelectedWeightUnitList}
                    selectedWeightUnitList={selectedWeightUnitList}
                    onChangeWeightUnit={inputHandler}
                    initialAmount={loadedItem.amount}
                    onChangeAmount={inputHandler}
                    initialBrandmark={loadedItem.brandmark}
                    initialModel={loadedItem.model}
                    initialSize={loadedItem.size}
                    initialDescription={loadedItem.description}
                    initialUnit={loadedItem.unit}
                    initialBarcode={loadedItem.barcode}
                    initialWeight={loadedItem.weight}
                    initialWeightUnit={loadedItem.weightunit}
                  ></MaterialCascading>
                ) : item.ctypecascadingfamily === "assignment" &&
                  item.ctypecascadingdegree === "1" ? (
                  <AssignmentCascading
                    setSelectedDepartment={setSelectedDepartment}
                    department={department}
                    onChangeDepartment={inputHandler}
                    setSelectedAssignment={setSelectedAssignment}
                    selectedAssignmentList={selectedAssignmentList}
                    onChangeAssignment={inputHandler}
                    initialDepartment={loadedItem.department}
                    initialAssignment={loadedItem.assignment}
                  ></AssignmentCascading>
                ) : item.ctypeinput ? (
                  <Input
                    id={item.value}
                    element="input"
                    type="text"
                    label={item.cname}
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid title."
                    onInput={inputHandler}
                    initialValue={loadedItem[item.value]}
                    initialValid={true}
                  />
                ) : item.ctypedate ? (
                  <>
                    <DateComp
                      id={item.value}
                      element="input"
                      type="date"
                      label={item.cname}
                      validators={[VALIDATOR_REQUIRE()]}
                      errorText="Please enter a valid title."
                      onInput={inputHandler}
                      initialValue={initialDates[d]}
                      initialValid={true}
                    />
                    <script>{(d = d + 1)}</script>
                  </>
                ) : item.ctypemultiselect ? (
                  <MultiSelect
                    id={item.value}
                    onChange={inputHandler}
                    data={product}
                    name={item.value}
                    label={item.cname}
                    value={loadedItem[item.value]}
                  />
                ) : item.ctypeattachmentname ? (
                  <AttachmentUpload
                    id="attachmentfile"
                    onInput={inputHandler}
                    errorText="Please provide an attachment."
                    initialValue={attachmentInitialValue}
                    setRemovedFileName={setRemovedFileName}
                    initialValid={true}
                  />
                ) : item.ctypeavatarname ? (
                  <AvatarUpload
                    id="avatarfile"
                    onInput={inputHandler}
                    errorText="Please provide an avatar."
                    initialValue={loadedItem[item.value]}
                    setRemovedAvatarName={setRemovedAvatarName}
                    initialValid={true}
                  />
                ) : item.ctypeimagename ? (
                  <ImageUpload
                    id="imagefile"
                    onInput={inputHandler}
                    errorText="Please provide an image."
                    initialValue={loadedItem[item.value]}
                    setRemovedImageName={setRemovedImageName}
                    initialValid={true}
                  />
                ) : null
                : null :

                item.ctypeselection ? (
                  <>
                    <CustomSelect
                      id={item.value}
                      onChange={inputHandler}
                      data={dropdownData[k]}
                      name={item.value}
                      label={item.cname}
                      value={loadedItem[item.value]}
                    ></CustomSelect>
                    <script>{(k = k + 1)}</script>
                  </>
                ) : item.ctypecascadingfamily === "location" &&
                  item.ctypecascadingdegree === "1" ? (
                  <LocationCascading
                    setSelectedCountry={setSelectedCountry}
                    country={country}
                    onChangeCountry={inputHandler}
                    setSelectedCity={setSelectedCity}
                    selectedCityList={selectedCityList}
                    onChangeCity={inputHandler}
                    setSelectedDistrict={setSelectedDistrict}
                    selectedDistrictList={selectedDistrictList}
                    onChangeDistrict={inputHandler}
                    initialCountry={loadedItem.country}
                    initialCity={loadedItem.city}
                    initialDistrict={loadedItem.district}
                  ></LocationCascading>
                ) : item.ctypecascadingfamily === "material" &&
                  item.ctypecascadingdegree === "1" ? (
                  <MaterialCascading
                    setSelectedBrandmark={setSelectedBrandmark}
                    brandmark={brandmark}
                    onChangeBrandmark={inputHandler}
                    setSelectedModel={setSelectedModel}
                    selectedModelList={selectedModelList}
                    onChangeModel={inputHandler}
                    setSelectedSize={setSelectedSize}
                    selectedSizeList={selectedSizeList}
                    onChangeSize={inputHandler}
                    setSelectedDescription={setSelectedDescriptionList}
                    selectedDescriptionList={selectedDescriptionList}
                    onChangeDescription={inputHandler}
                    setSelectedUnit={setSelectedUnitList}
                    selectedUnitList={selectedUnitList}
                    onChangeUnit={inputHandler}
                    setSelectedBarcode={setSelectedBarcodeList}
                    selectedBarcodeList={selectedBarcodeList}
                    onChangeBarcode={inputHandler}
                    setSelectedWeight={setSelectedWeightList}
                    selectedWeightList={selectedWeightList}
                    onChangeWeight={inputHandler}
                    setSelectedWeightUnit={setSelectedWeightUnitList}
                    selectedWeightUnitList={selectedWeightUnitList}
                    onChangeWeightUnit={inputHandler}
                    initialAmount={loadedItem.amount}
                    onChangeAmount={inputHandler}
                    initialBrandmark={loadedItem.brandmark}
                    initialModel={loadedItem.model}
                    initialSize={loadedItem.size}
                    initialDescription={loadedItem.description}
                    initialUnit={loadedItem.unit}
                    initialBarcode={loadedItem.barcode}
                    initialWeight={loadedItem.weight}
                    initialWeightUnit={loadedItem.weightunit}
                  ></MaterialCascading>
                ) : item.ctypecascadingfamily === "assignment" &&
                  item.ctypecascadingdegree === "1" ? (
                  <AssignmentCascading
                    setSelectedDepartment={setSelectedDepartment}
                    department={department}
                    onChangeDepartment={inputHandler}
                    setSelectedAssignment={setSelectedAssignment}
                    selectedAssignmentList={selectedAssignmentList}
                    onChangeAssignment={inputHandler}
                    initialDepartment={loadedItem.department}
                    initialAssignment={loadedItem.assignment}
                  ></AssignmentCascading>
                ) : item.ctypeinput ? (
                  <Input
                    id={item.value}
                    element="input"
                    type="text"
                    label={item.cname}
                    validators={[VALIDATOR_REQUIRE()]}
                    errorText="Please enter a valid title."
                    onInput={inputHandler}
                    initialValue={loadedItem[item.value]}
                    initialValid={true}
                  />
                ) : item.ctypedate ? (
                  <>
                    <DateComp
                      id={item.value}
                      element="input"
                      type="date"
                      label={item.cname}
                      validators={[VALIDATOR_REQUIRE()]}
                      errorText="Please enter a valid title."
                      onInput={inputHandler}
                      initialValue={initialDates[d]}
                      initialValid={true}
                    />
                    <script>{(d = d + 1)}</script>
                  </>
                ) : item.ctypemultiselect ? (
                  <MultiSelect
                    id={item.value}
                    onChange={inputHandler}
                    data={product}
                    name={item.value}
                    label={item.cname}
                    value={loadedItem[item.value]}
                  />
                ) : item.ctypeattachmentname ? (
                  <AttachmentUpload
                    id="attachmentfile"
                    onInput={inputHandler}
                    errorText="Please provide an attachment."
                    initialValue={attachmentInitialValue}
                    setRemovedFileName={setRemovedFileName}
                    initialValid={true}
                  />
                ) : item.ctypeavatarname ? (
                  <AvatarUpload
                    id="avatarfile"
                    onInput={inputHandler}
                    errorText="Please provide an avatar."
                    initialValue={loadedItem[item.value]}
                    setRemovedAvatarName={setRemovedAvatarName}
                    initialValid={true}
                  />
                ) : item.ctypeimagename ? (
                  <ImageUpload
                    id="imagefile"
                    onInput={inputHandler}
                    errorText="Please provide an image."
                    initialValue={loadedItem[item.value]}
                    setRemovedImageName={setRemovedImageName}
                    initialValid={true}
                  />
                ) : null
            ) : null;
          })}
          <br></br>
          <Button size="3" type="submit" disabled={!formState.isValid}>
            Update ITEM
          </Button>
          <br></br>
          <br></br>

        </form>
      )}
    </React.Fragment>
  );
};

export default EditForm;
