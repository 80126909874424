import React, { useEffect, useState, useContext } from "react";

import Button from "../../shared/components/FormElements/Button";

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";


const LibraryDeleteForm = ({
    departmentname,
    closeDeleteModalHandler,
    isCheckedFolder,
    isCheckedFile,
    setRefreshTable,
    refreshTable,
    setIsCheckedFolder,
    setCheckedListFolder,
    setIsCheckedFile,
    setCheckedListFile
}) => {
    const auth = useContext(AuthContext);
    const { error, sendRequest, clearError } = useHttpClient();
    const [isLoading, setIsLoading] = useState(false);

    const itemDeleteSubmitHandler = async (event) => {
        setIsLoading(true);
        var paths = isCheckedFolder.concat(isCheckedFile);
        let department = departmentname.split("/")[0]
        try {

            await sendRequest(
                `${process.env.REACT_APP_API_URL}/filemanager/delete/${department}`,
                "POST",
                JSON.stringify({

                    paths: paths
                }),
                {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                }
            );
            setIsLoading(false);
            setIsCheckedFolder([]);
            setCheckedListFolder([]);
            setIsCheckedFile([]);
            setCheckedListFile([]);
            setRefreshTable(!refreshTable);
            closeDeleteModalHandler();
        } catch (err) {
            setIsLoading(false);
            alert(err);
            console.log(err);
            closeDeleteModalHandler();
        }

    };

    if (isLoading) {
        return (
            <div className="center">
                <LoadingSpinner />
            </div>
        );
    }

    return (
        <React.Fragment>
            <ErrorModal error={error} onClear={clearError} />
            {!isLoading && (
                <div onSubmit={itemDeleteSubmitHandler}>
                    {isLoading && <LoadingSpinner asOverlay />}
                    {isCheckedFolder.length !== 0 || isCheckedFile.length !== 0 ? (
                        <>
                            {" "}
                            <p>Are you sure to delete selected items? </p>
                            <Button type="submit" onClick={itemDeleteSubmitHandler}>
                                DELETE
                            </Button>
                        </>
                    ) : (
                        <>
                            {" "}
                            <p>Please select an item </p>
                            <Button type="button" onClick={closeDeleteModalHandler}>
                                OK
                            </Button>
                        </>
                    )}
                </div>
            )}
        </React.Fragment>
    );
};

export default LibraryDeleteForm;
