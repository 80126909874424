import React, { useEffect, useState } from "react";

import Material from "./Material";
import Input from "../../FormElements/Input";

import {
  VALIDATOR_REQUIRE,
  VALIDATOR_MINLENGTH,
  VALIDATOR_EMAIL,
} from "../../../util/validators";
import ReadOnlyInput from "../ReadOnlyInput";

const MaterialCascading = ({
  // setSelectedCountry,
  // country,
  // onChangeCountry,
  // setSelectedCity,
  // selectedCityList,
  // onChangeCity,
  // setSelectedDistrict,
  // selectedDistrictList,
  // onChangeDistrict,
  // initialCountry,
  // initialCity,
  //initialDistrict,

  setSelectedBrandmark,
  brandmark,
  onChangeBrandmark,

  setSelectedModel,
  selectedModelList,
  onChangeModel,

  setSelectedSize,
  selectedSizeList,
  onChangeSize,

  setSelectedDescription,
  selectedDescriptionList,
  onChangeDescription,

  setSelectedUnit,
  selectedUnitList,
  onChangeUnit,

  setSelectedBarcode,
  selectedBarcodeList,
  onChangeBarcode,

  setSelectedWeight,
  selectedWeightList,
  onChangeWeight,

  setSelectedWeightUnit,
  selectedWeightUnitList,
  onChangeWeightUnit,
  initialAmount,
  onChangeAmount,

  initialBrandmark,
  initialModel,
  initialSize,
  initialDescription,
  initialUnit,
  initialBarcode,
  initialWeight,
  initialWeightUnit,
}) => {
  const [data, setData] = useState();
  const [dataModel, setDataModel] = useState();
  const [dataSize, setDataSize] = useState();
  const [dataDescription, setDataDescription] = useState();
  const [dataUnit, setDataUnit] = useState();
  const [dataBarcode, setDataBarcode] = useState();
  const [dataWeight, setDataWeight] = useState();
  const [dataWeightUnit, setDataWeightUnit] = useState();

  const [initialValueBrandmark, setInitialValueBrandmark] =
    useState(initialBrandmark);
  const [initialValueModel, setInitialValueModel] = useState(initialModel);
  const [initialValueSize, setInitialValueSize] = useState(initialSize);

  const [initialValueDescription, setInitialValueDescription] =
    useState(initialDescription);
  const [initialValueUnit, setInitialValueUnit] = useState(initialUnit);
  const [initialValueBarcode, setInitialValueBarcode] =
    useState(initialBarcode);
  const [initialValueWeight, setInitialValueWeight] = useState(initialWeight);
  const [initialValueWeightUnit, setInitialValueWeightUnit] =
    useState(initialWeightUnit);

  const [selectedValue, setSelectedValue] = useState();
  const [selectedValueModel, setSelectedValueModel] = useState();
  const [selectedValueSize, setSelectedValueSize] = useState();
  const [selectedValueDescription, setSelectedValueDescription] = useState();
  const [selectedValueUnit, setSelectedValueUnit] = useState();
  const [selectedValueBarcode, setSelectedValueBarcode] = useState();
  const [selectedValueWeight, setSelectedValueWeight] = useState();
  const [selectedValueWeightUnit, setSelectedValueWeightUnit] = useState();

  const [onSelectedDescription, setOnSelectedDescription] = useState();

  useEffect(() => {
    if (brandmark !== undefined) {
      setData(brandmark);
    }
  }, [brandmark]);
  console.log("datause", data);

  useEffect(() => {
    setSelectedDescription(initialDescription);
  }, [initialDescription]);

  useEffect(() => {
    setSelectedUnit(initialUnit);
  }, [initialUnit]);

  useEffect(() => {
    setSelectedBarcode(initialBarcode);
  }, [initialBarcode]);

  useEffect(() => {
    setSelectedWeight(initialWeight);
  }, [initialWeight]);

  useEffect(() => {
    setSelectedWeightUnit(initialWeightUnit);
  }, [initialWeightUnit]);
  useEffect(() => {
    // if (selectedModelList !== undefined) {
    if (selectedModelList.length !== 0) {
      setDataModel(selectedModelList);
      setInitialValueBrandmark("");
      setSelectedValueModel("");
      setSelectedValueSize("");

      setInitialValueModel("");
      setInitialValueSize("");
      setInitialValueDescription("");
      setInitialValueUnit("");
      setInitialValueBarcode("");
      setInitialValueWeight("");
      setInitialValueWeightUnit("");
    }
  }, [selectedModelList]);

  useEffect(() => {
    if (selectedSizeList !== undefined) {
      // if (selectedSizeList.length !== 0) {
      setDataSize(selectedSizeList);

      setSelectedValueSize("");

      setInitialValueBrandmark("");
      setInitialValueModel("");
      setInitialValueSize("");
      setInitialValueDescription("");
      setInitialValueUnit("");
      setInitialValueBarcode("");
      setInitialValueWeight("");
      setInitialValueWeightUnit("");
    }
  }, [selectedSizeList]);

  useEffect(() => {
    if (selectedDescriptionList !== undefined) {
      onChangeDescription("description", selectedDescriptionList[0], true);
    }
  }, [selectedDescriptionList]);

  useEffect(() => {
    if (selectedUnitList !== undefined) {
      onChangeUnit("unit", selectedUnitList[0], true);
    }
  }, [selectedUnitList]);

  useEffect(() => {
    if (selectedBarcodeList !== undefined) {
      onChangeBarcode("barcode", selectedBarcodeList[0], true);
    }
  }, [selectedBarcodeList]);

  useEffect(() => {
    if (selectedWeightList !== undefined) {
      onChangeWeight("weight", selectedWeightList[0], true);
    }
  }, [selectedWeightList]);

  useEffect(() => {
    if (selectedWeightUnitList !== undefined) {
      onChangeWeightUnit("weightunit", selectedWeightUnitList[0], true);
    }
  }, [selectedWeightUnitList]);
  console.log("initialValueDescription", initialValueDescription);
  console.log("initialDescription", initialDescription);
  console.log("selectedDescriptionList", selectedDescriptionList);
  return (
    <React.Fragment>
      <Material
        id="brandmark"
        setFunc={setSelectedBrandmark}
        onChange={onChangeBrandmark}
        name="brandmark"
        initialValue={initialValueBrandmark}
        data={data}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        label="Brandmark"
      ></Material>

      <Material
        id="model"
        setFunc={setSelectedModel}
        onChange={onChangeModel}
        name="model"
        initialValue={initialValueModel}
        data={dataModel}
        selectedValue={selectedValueModel}
        setSelectedValue={setSelectedValueModel}
        label="Model"
      ></Material>

      <Material
        id="size"
        setFunc={setSelectedSize}
        onChange={onChangeSize}
        name="size"
        initialValue={initialValueSize}
        data={dataSize}
        selectedValue={selectedValueSize}
        setSelectedValue={setSelectedValueSize}
        label="Size"
      ></Material>

      <ReadOnlyInput
        id="description"
        type="text"
        label="Description"
        value={selectedDescriptionList}
        name="description"
        errorText="Please enter a valid title."
      ></ReadOnlyInput>
      <Input
        id="amount"
        element="input"
        type="text"
        label="Amount"
        errorText="Please enter a valid title."
        onInput={onChangeAmount}
        initialValue={initialAmount}
        initialValid={true}
        validators={[VALIDATOR_REQUIRE()]}
      />

      <ReadOnlyInput
        id="unit"
        type="text"
        label="Unit"
        value={selectedUnitList}
        errorText="Please enter a valid title."
        readOnly
      ></ReadOnlyInput>

      <ReadOnlyInput
        id="barcode"
        type="text"
        label="Barcode"
        value={selectedBarcodeList}
        errorText="Please enter a valid title."
        readOnly
      ></ReadOnlyInput>

      <ReadOnlyInput
        id="weight"
        type="text"
        label="Weight"
        value={selectedWeightList}
        errorText="Please enter a valid title."
        readOnly
      ></ReadOnlyInput>

      <ReadOnlyInput
        id="weightunit"
        type="text"
        label="Weight Unit"
        value={selectedWeightUnitList}
        readOnly
      ></ReadOnlyInput>
    </React.Fragment>
  );
};

export default MaterialCascading;
