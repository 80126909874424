import React, { useState, useRef, useEffect, useCallback } from "react";

import Dropdown from "./DropDown";
import stylessDropDownCustomSelect from "./DropDownCustomSelect.module.css";

const CustomSelect = ({
  label,
  data,
  value,
  name,
  onChange,
  error,
  defaultOptionLabel,
  searchPlaceholder,
  difference
}) => {
  const [selectedValue, setSelectedValue] = useState(value);
  const [selectedIndex, setSelectedIndex] = useState();
  // value !== "" ? data?.indexOf(value) : null
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState();
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownEl = useRef();
  console.log("selectedIndex", value);
  console.log("Options", options);
  console.log("data", data);

  useEffect(() => {
    setOptions(data);
  }, []);

  // Hide dropdown if clicked outside of dropdown
  const handleClickOutside = useCallback(
    (e) => {
      if (
        showDropdown &&
        e.target.closest(".dropdown") !== dropdownEl.current
      ) {
        setShowDropdown(false);
        setSearch("");
        setOptions(data);
      }
    },
    [showDropdown, setShowDropdown, dropdownEl, data]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);

  const changeSelectedHandler = (item, name, index, isValid) => {
    setSelectedValue(item);
    setSelectedIndex(index);
    setShowDropdown(false);
    onChange(name, item, isValid);
  };

  const searchChangeHandler = (e) => {
    setSearch(e.target.value);
    const filteredOptions = data.filter((opt) => {
      return opt.name
        .toLowerCase()
        .includes(e.target.value.trim().toLowerCase());
    });
    setOptions(filteredOptions);
  };

  return (
    <div className="form-control.false" class={difference ? stylessDropDownCustomSelect.form_control_difference : stylessDropDownCustomSelect.form_control}>
      {!difference && <br></br>}
      {!difference && <label className={stylessDropDownCustomSelect.label} >{label}</label>}
      <div className="dropdown" ref={dropdownEl}>
        <input
          type="hidden"
          name={name}
          value={value}
          onChange={(e) => onChange(value, name)}
        />
        <div
          className={difference ? stylessDropDownCustomSelect.dropdown__selected_equipment : stylessDropDownCustomSelect.dropdown__selected}
          onClick={() => {
            setOptions(data);
            setShowDropdown(!showDropdown);
          }}
        >
          {selectedValue
            ? selectedValue
            : defaultOptionLabel
              ? defaultOptionLabel
              : difference ? "Select Area   " : "Please select one option"}
        </div>
        {showDropdown && (
          <Dropdown
            searchPlaceholder={searchPlaceholder}
            search={search}
            searchChangeHandler={searchChangeHandler}
            options={options}
            selectedValue={selectedValue}
            selectedIndex={selectedIndex}
            changeSelectedHandler={changeSelectedHandler}
            name={name}
            data={data}
          />
        )}
      </div>
      {error && <div className="error">{error}</div>}
    </div>
  );
};

export default CustomSelect;
