import React from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import stylessProfileSide from "./ProfileSide.module.css";

const ProfileSideDrawer = (props) => {
  const content = (
    <CSSTransition
      anchor={"left"}
      in={props.show}
      timeout={200}
      mountOnEnter
     
      unmountOnExit
    >
      <aside className={stylessProfileSide.side_drawer} onClick={props.onClick}>
        {props.children}
      </aside>
    </CSSTransition>
  );

  return ReactDOM.createPortal(content, document.getElementById("drawer-hook"));
};

export default ProfileSideDrawer;
