import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";

import TableColumnDrop from "./TableColumnMenuDrop";

import "./TableColumn.css";

const TableColumnMenu = ({
  columns,
  filterChoosen,
  setSelectedColumn,
  handleSorting,
  tableUrl,
  setCheckedList,
  checkedlist,
  handleCheckAll
}) => {
  const [columnMenuIsOpen, setColumnMenuIsOpen] = useState(false);

  const [sortField, setSortField] = useState("");

  const [order, setOrder] = useState("asc");

  const [drop, setDrop] = useState("asc");

  const catMenu = useRef(null);

  const [checkAllStatus, setCheckAllStatus] = useState(false);

  const handleSortingAZ = (aaa) => {
    const sortOrder = "asc";

    setSortField(aaa);
    setOrder(sortOrder);
    console.log(aaa);
    handleSorting(aaa, sortOrder);
    setColumnMenuIsOpen(false);
  };

  const handleSortingZA = (aaa) => {
    const sortOrder = "desc";

    setSortField(aaa);
    setOrder(sortOrder);
    console.log(aaa);
    handleSorting(aaa, sortOrder);
    setColumnMenuIsOpen(false);
  };

  const handleFilterChange = (aaa, ctypemultiselect) => {
    setSelectedColumn(aaa, ctypemultiselect);
    setColumnMenuIsOpen(false);
  };

  const handleColumnMenu = (value) => {
    setColumnMenuIsOpen(true);
    setDrop(value);
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref.current &&
          columnMenuIsOpen &&
          !ref.current.contains(event.target)
        ) {
          setColumnMenuIsOpen(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, columnMenuIsOpen]);
  }
  /*  function handleCheckAll() {
      setCheckAllStatus(!checkAllStatus);
  
      setCheckedList(new Array(checkedlist.length).fill(!checkAllStatus));
    }*/
  useOutsideAlerter(catMenu);

  return (
    <React.Fragment>
      <tr>
        <th>  <input type="checkbox" onChange={handleCheckAll}></input></th>
        {tableUrl === "employee" &&
          <th> <div> <span>{"Stat."}</span></div></th>
        }
        {columns.map(({ cname, value, ctypeshowpublic, ctypemultiselect }) => {
          if (ctypeshowpublic === true) {
            const cl =
              sortField === value && order === "asc"
                ? filterChoosen.includes(value)
                  ? "up-filter"
                  : "up"
                : sortField === value && order === "desc"
                  ? filterChoosen.includes(value)
                    ? "down-filter"
                    : "down"
                  : filterChoosen.includes(value)
                    ? "default-filter"
                    : "default";

            return (
              <th key={value} class={"th"}>
                <div>
                  <span className="clmnname">{cname}</span>
                  {filterChoosen.includes(value) && <span className="clmnfiltericon"></span>}
                  {sortField === value && order === "asc" && <span className="clmnupicon"></span>}
                  {sortField === value && order === "desc" && <span className="clmndownicon"></span>}
                  <span onClick={() => handleColumnMenu(value)} className="clmnicon"></span>
                </div>

                <div>
                  {value === drop && columnMenuIsOpen && (
                    <TableColumnDrop
                      key={drop}
                      handleSortingAZ={handleSortingAZ}
                      handleSortingZA={handleSortingZA}
                      handleFilterChange={handleFilterChange}
                      drop={drop}
                      catMenu={catMenu}
                      ctypemultiselect={ctypemultiselect}
                    />
                  )}
                </div>
              </th>
            );
          }
        })}
      </tr>
    </React.Fragment>
  );
};

export default TableColumnMenu;
