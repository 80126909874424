import React, { useEffect, useState, useContext } from "react";

import Button from "../../shared/components/FormElements/Button";

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
//import "./NewForm.css";

const DeleteForm = ({
  selectedID,
  tableUrl,
  isChecked,
  closeDeleteModalHandler,
  isCheckedFile,
  isCheckedAvatar,
  isCheckedImage,
  setRefreshData,
  refreshData,
  setIsChecked,
  setIsCheckedFile,
  setIsCheckedAvatar,
  setIsCheckedImage,
}) => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const itemId = `${selectedID}`;

  console.log("itemID", itemId);

  console.log("isChecked", isChecked);

  const itemDeleteSubmitHandler = async (event) => {
    event.preventDefault();

    let fileArray = [];

    console.log("isCheckedFile", isCheckedFile);

    console.log("isCheckedAvatar", isCheckedAvatar);

    console.log("isCheckedImage", isCheckedImage);

    if (isCheckedFile.length !== 0 && isCheckedFile[0] !== null) {
      fileArray = fileArray.concat(isCheckedFile[0]);
    }
    console.log("😈😈😈😈😈😈😈😈😈😈😈😈", fileArray);
    if (
      isCheckedAvatar.length !== 0 &&
      isCheckedAvatar[0][0] !== "" &&
      isCheckedAvatar[0][0] !== null &&
      isCheckedFile.length !== 0
    ) {
      fileArray = fileArray[0].concat(isCheckedAvatar[0]);
    } else if (
      isCheckedAvatar.length !== 0 &&
      isCheckedAvatar[0][0] !== "" &&
      isCheckedAvatar[0][0] !== null
    ) {
      fileArray = fileArray.concat(isCheckedAvatar[0]);
    }
    console.log("🧵🧵🧵🧵🧵🧵🧵🧵🧵🧵🧵🧵🧵", fileArray);
    if (
      isCheckedImage.length !== 0 &&
      isCheckedImage[0][0] !== "" &&
      isCheckedImage[0][0] !== null &&
      isCheckedFile.length !== 0 &&
      isCheckedAvatar.length === 0
    ) {
      fileArray = fileArray[0].concat(isCheckedImage[0]);
    } else if (
      isCheckedImage.length !== 0 &&
      isCheckedImage[0][0] !== "" &&
      isCheckedImage[0][0] !== null
    ) {
      fileArray = fileArray.concat(isCheckedImage[0]);
    }

    console.log("👚👚👚👚👚👚👚👚👚👚👚👚👚👚", fileArray);
    console.log("👚👚👚", fileArray[0]?.length);
    console.log("👚", fileArray?.length);

    if (fileArray[0]?.length !== 0) {
      console.log("💩💩💩💩💩💩💩💩💩💩💩💩💩💩💩💩💩💩💩", isChecked.length);
      console.log("isChecked2", isChecked);
      try {
        for (let i = 0; i < fileArray?.length; i++) {
          console.log("🤢🤢🤢🤢🤢🤢🤢🤢🤢🤢🤢🤢", fileArray[i]);
          fileArray[i] = fileArray[i]?.replaceAll("/", "-");
          console.log("🤢🤢🤢🤢🤢🤢", fileArray[i]);
        }
        console.log("isChecked3", isChecked);
        await sendRequest(
          `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/${isChecked}/${fileArray}`,
          "DELETE",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setRefreshData(!refreshData);
        closeDeleteModalHandler();
      } catch (err) { }
    } else {
      try {
        await sendRequest(
          `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/${isChecked}`,
          "DELETE",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setRefreshData(!refreshData);
        closeDeleteModalHandler();
      } catch (err) { }
    }
    setIsChecked([]);
    setIsCheckedFile([]);
    setIsCheckedAvatar([]);
    setIsCheckedImage([]);
    //refreshPage();
    //}
    /*else {
      alert("please Select at least one check box !");
    }*/
  };

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {!isLoading && (
        <div onSubmit={itemDeleteSubmitHandler}>
          {isLoading && <LoadingSpinner asOverlay />}
          {isChecked.length !== 0 ? (
            <>
              {" "}
              <p>Are you sure to delete selected items? </p>
              <Button size="2" type="submit" onClick={itemDeleteSubmitHandler}>
                DELETE
              </Button>
              <Button type="button" onClick={closeDeleteModalHandler}>
                CANCEL
              </Button>
            </>
          ) : (
            <>
              {" "}
              <p>Please select an item </p>
              <Button type="button" onClick={closeDeleteModalHandler}>
                OK
              </Button>
            </>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default DeleteForm;
