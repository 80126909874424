import React, { useEffect, useState, useContext } from "react";
import { Link, NavLink } from "react-router-dom";

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import { useHttpClient } from "../../shared/hooks/http-hook";
import stylessDisplayForm from "./DisplayForm.module.css";

const DisplayForm = ({ selectedID, tableUrl, closeDisplayDrawerHandler }) => {
  const [columns, setColumns] = useState([]);
  const [attachList, setAttachList] = useState([]);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  const { sendRequest, clearError } = useHttpClient();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [loadedItem, setLoadedItem] = useState([]);
  const itemId = `${selectedID}`;

  useEffect(() => {
    const fetchItem = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/${itemId}`
        );

        setLoadedItem(responseData);

        const responseColumns = await fetch(
          `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/column`,
          {
            method: "GET",
          }
        );

        const responseColumnsData = await responseColumns.json();

        if (!responseColumns.ok) {
          throw new Error(responseColumnsData.message);
        }

        setColumns(responseColumnsData);
      } catch (err) { }
    };
    fetchItem();
  }, [sendRequest, itemId]);

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!loadedItem && !error) {
    return (
      <div className="center">
        <div>
          <h2>Could not find place!</h2>
        </div>
      </div>
    );
  }

  var getInitials2 = function (string) {
    var names = string.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      for (let i = 1; i < names.length; i++)
        initials += names[i].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  console.log(getInitials2("TURGUT ERDEM ALAY"));

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <>
        {!isLoading && loadedItem && (
          <>
            {isLoading && <LoadingSpinner asOverlay />}
            {screenWidth < 500 && < span className={stylessDisplayForm.closeicon} onClick={closeDisplayDrawerHandler}></span>}
            {columns.map((item, i) => {
              return (
                <>
                  {item.ctypedisplay ? (
                    item.value === "attachmentpath" ? (
                      <>
                        <br></br>
                        <b> {item.cname + ":"} </b>
                        <br></br>
                        {loadedItem[item.value]?.map((i) => {
                          return (
                            <Link
                              //id={item.value}
                              onClick={() => window.open(`${i}`, "_blank")}
                            >
                              {" "}
                              {i}
                            </Link>
                          );
                        })}
                      </>
                    ) : item.value === "avatarpath" ||
                      item.value === "imagepath" ? (
                      <>
                        <br></br>
                        <b> {item.cname + ":"} </b>
                        <br></br>
                        <div className={stylessDisplayForm.file_picker_file_row}>
                          <Link
                            id={item.value}
                            onClick={() =>
                              window.open(`${loadedItem[item.value]}`, "_blank")
                            }
                          >
                            {" "}
                            {loadedItem[item.value]}
                          </Link>
                        </div>
                        <br></br>
                        <br></br>
                        <br></br>
                      </>
                    ) : (
                      <>
                        <br></br>
                        <b> {item.cname + ":"} </b>
                        <br></br>
                        <div className={stylessDisplayForm.file_picker_file_row} id={item.value}> {loadedItem[item.value]}</div>
                      </>

                    )
                  ) : null}
                </>
              );
            })}
          </>
        )}
      </>
    </React.Fragment >
  );
};

export default DisplayForm;
