import React from "react";
import { useState, useEffect, useContext } from "react";
import Button from "../../shared/components/FormElements/Button";
import stylesTableHeader from "./TableHeader.module.css";
import "./TableColumnMenuDrop.css";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../shared/context/auth-context";
const TableHeader = ({
  openNewDrawerHandler,
  openEditDrawerHandler,
  openDeleteModalHandler,
  openEditAllHandler,
  closeEditAllHandler,
  openTechApproveModalHandler,
  openCreateRqcModalHandler,
  openSelectSupplierModalHandler,
  openFinancialApproveModalHandler,
  openCreateTrqModalHandler,
  tableUrl,
  runExport,
  showNewButton,
  showDeleteButton,
  showEditButton,
  showTechApproveButton,
  showCreateRqcButton,
  showSelectSupplierButton,
  showFinancialApproveButton,
  showCreateTrqButton,
  showRestoreButton,
  openRestoreModalHandler,
  openReleaseDrawerHandler,
  showReleaseEmployeeButton,
  setShowType,
  showEditInGridview,
  showSaveAll,
  showUpdateOrderStatusButton,
  showUpdateExpReceDateButton,
  openUpdateOrderStatusModalHandler,
  openUpdateExpReceDateModalHandler,

}) => {
  const auth = useContext(AuthContext);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const navigate = useNavigate();
  return (
    <div>
      <div>
        {" "}
        <div>
          <div>
            <div className={stylesTableHeader.row}></div>
          </div>
        </div>
        <div className={stylesTableHeader.card2}>
          <div className={stylesTableHeader.buttonsdiv}>
            {showNewButton && (
              <Button onClick={openNewDrawerHandler}>New</Button>
            )}
            {screenWidth > 1030 && showEditButton && (
              <Button size="2" onClick={openEditDrawerHandler}>
                <div className={stylesTableHeader.rowbuton}>
                  <div className={stylesTableHeader.edit}></div>
                  <div
                    className={stylesTableHeader.row_ikon_buton_bosluk}
                  ></div>{" "}
                  Edit{" "}
                </div>
              </Button>
            )}
            <Button size="2" id="export" onClick={() => runExport()}>
              <div className={stylesTableHeader.rowbuton}>
                <div className={stylesTableHeader.exel}></div>
                <div
                  className={stylesTableHeader.row_ikon_buton_bosluk}
                ></div>{" "}
                Export to Excel{" "}
              </div>
            </Button>
            {screenWidth > 850 && showDeleteButton && (
              <Button size="2" onClick={openDeleteModalHandler}>
                <div className={stylesTableHeader.rowbuton}>
                  <div className={stylesTableHeader.cop}></div>
                  <div
                    className={stylesTableHeader.row_ikon_buton_bosluk}
                  ></div>{" "}
                  Delete Item
                </div>
              </Button>
            )}
            {screenWidth > 850 && showRestoreButton && (
              <Button size="2" onClick={openRestoreModalHandler}>
                <div className={stylesTableHeader.rowbuton}>
                  <div className={stylesTableHeader.cop}></div>
                  <div
                    className={stylesTableHeader.row_ikon_buton_bosluk}
                  ></div>{" "}
                  Restore Item
                </div>
              </Button>
            )}
            {screenWidth > 1030 && showEditInGridview && (
              <Button size="2" onClick={openEditAllHandler}>
                <div className={stylesTableHeader.rowbuton}>
                  <div className={stylesTableHeader.edit}></div>
                  <div
                    className={stylesTableHeader.row_ikon_buton_bosluk}
                  ></div>{" "}
                  Edit in Grid View
                </div>
              </Button>
            )}
            {screenWidth > 740 && showSaveAll && (
              <Button size="2" onClick={closeEditAllHandler}>
                <div className={stylesTableHeader.rowbuton}>
                  <div className={stylesTableHeader.savei}></div>
                  <div
                    className={stylesTableHeader.row_ikon_buton_bosluk}
                  ></div>{" "}
                  Save All
                </div>
              </Button>
            )}
            {screenWidth > 740 && showTechApproveButton && (
              <Button size="2" onClick={openTechApproveModalHandler}>
                <div className={stylesTableHeader.rowbuton}>
                  <div className={stylesTableHeader.savei}></div>
                  <div
                    className={stylesTableHeader.row_ikon_buton_bosluk}
                  ></div>{" "}
                  Tech Approve
                </div>
              </Button>
            )}
            {screenWidth > 740 && showCreateRqcButton && (
              <Button size="2" onClick={openCreateRqcModalHandler}>
                <div className={stylesTableHeader.rowbuton}>
                  <div className={stylesTableHeader.savei}></div>
                  <div
                    className={stylesTableHeader.row_ikon_buton_bosluk}
                  ></div>{" "}
                  Create RQC
                </div>
              </Button>
            )}
            {screenWidth > 740 && showSelectSupplierButton && (
              <Button size="2" onClick={openSelectSupplierModalHandler}>
                <div className={stylesTableHeader.rowbuton}>
                  <div className={stylesTableHeader.savei}></div>
                  <div
                    className={stylesTableHeader.row_ikon_buton_bosluk}
                  ></div>{" "}
                  Select Supplier
                </div>
              </Button>
            )}
            {screenWidth > 740 && showFinancialApproveButton && (
              <Button size="2" onClick={openFinancialApproveModalHandler}>
                <div className={stylesTableHeader.rowbuton}>
                  <div className={stylesTableHeader.savei}></div>
                  <div
                    className={stylesTableHeader.row_ikon_buton_bosluk}
                  ></div>{" "}
                  Financial Approve
                </div>
              </Button>
            )}
            {screenWidth > 740 && showCreateTrqButton && (
              <Button size="2" onClick={openCreateTrqModalHandler}>
                <div className={stylesTableHeader.rowbuton}>
                  <div className={stylesTableHeader.savei}></div>
                  <div
                    className={stylesTableHeader.row_ikon_buton_bosluk}
                  ></div>{" "}
                  Create TRQ
                </div>
              </Button>
            )}
            {screenWidth > 740 && auth.userType === "Admin" && (
              <Button
                size="2"
                onClick={() =>
                  navigate(
                    "/admin/details/Recycle" +
                    tableUrl.charAt(0).toUpperCase() +
                    tableUrl.slice(1)
                  )
                }
              >
                <div className={stylesTableHeader.rowbuton}>
                  <div className={stylesTableHeader.recycle}></div>
                  <div
                    className={stylesTableHeader.row_ikon_buton_bosluk}
                  ></div>{" "}
                  Recycle Bin
                </div>
              </Button>
            )}
            {screenWidth > 1030 &&
              showReleaseEmployeeButton &&
              tableUrl === "employee" && (
                <Button size="2" onClick={openReleaseDrawerHandler}>
                  <div className={stylesTableHeader.rowbuton}>
                    <div className={stylesTableHeader.layoff}></div>
                    <div
                      className={stylesTableHeader.row_ikon_buton_bosluk}
                    ></div>{" "}
                    Release User{" "}
                  </div>
                </Button>
              )}
            {screenWidth > 1030 && tableUrl === "employee" && (
              <Button
                size="2"
                onClick={() => setShowType(["employeestatus", "all"])}
              >
                <div className={stylesTableHeader.rowbuton}>
                  <div className={stylesTableHeader.layoff}></div>
                  <div
                    className={stylesTableHeader.row_ikon_buton_bosluk}
                  ></div>{" "}
                  Show All{" "}
                </div>
              </Button>
            )}
            {screenWidth > 1030 && tableUrl === "employee" && (
              <Button
                size="2"
                onClick={() => setShowType(["employeestatus", true])}
              >
                <div className={stylesTableHeader.rowbuton}>
                  <div className={stylesTableHeader.layoff}></div>
                  <div
                    className={stylesTableHeader.row_ikon_buton_bosluk}
                  ></div>{" "}
                  Show Only Active{" "}
                </div>
              </Button>
            )}
            {screenWidth > 1030 && tableUrl === "employee" && (
              <Button
                size="2"
                onClick={() => setShowType(["employeestatus", false])}
              >
                <div className={stylesTableHeader.rowbuton}>
                  <div className={stylesTableHeader.layoff}></div>
                  <div
                    className={stylesTableHeader.row_ikon_buton_bosluk}
                  ></div>{" "}
                  Show Only Inactive{" "}
                </div>
              </Button>
            )}
            {screenWidth > 1030 && showUpdateOrderStatusButton && (
              <Button size="2" onClick={openUpdateOrderStatusModalHandler}>
                <div className={stylesTableHeader.rowbuton}>
                  <div className={stylesTableHeader.savei}></div>
                  <div
                    className={stylesTableHeader.row_ikon_buton_bosluk}
                  ></div>{" "}
                  Update Order Status
                </div>
              </Button>
            )}
            {screenWidth > 1030 && showUpdateExpReceDateButton && (
              <Button size="2" onClick={openUpdateExpReceDateModalHandler}>
                <div className={stylesTableHeader.rowbuton}>
                  <div className={stylesTableHeader.savei}></div>
                  <div
                    className={stylesTableHeader.row_ikon_buton_bosluk}
                  ></div>{" "}
                  Update Exp Rece Date
                </div>
              </Button>
            )}
            {screenWidth < 1030 && (
              <Button size="2" onClick={handleOpen}>
                {" "}
                <div className={stylesTableHeader.rowbuton}>
                  <div className={stylesTableHeader.candy_box}></div>
                  <div
                    className={stylesTableHeader.row_ikon_buton_bosluk}
                  ></div>
                </div>
                {open ? (
                  <ul className={stylesTableHeader.menu_links}>
                    {screenWidth < 1030 && showEditButton && (
                      <li>
                        <Button size="6" onClick={openEditDrawerHandler}>
                          Edit
                        </Button>
                      </li>
                    )}
                    {screenWidth < 1030 && showEditInGridview && (
                      <li>
                        <Button size="6" onClick={openEditAllHandler}>
                          Edit in Grid View{" "}
                        </Button>
                      </li>
                    )}
                    {screenWidth < 850 && showDeleteButton && (
                      <li>
                        <Button size="6" onClick={openDeleteModalHandler}>
                          Delete
                        </Button>
                      </li>
                    )}
                    {screenWidth < 850 && showRestoreButton && (
                      <li>
                        <Button size="6" onClick={openRestoreModalHandler}>
                          Restore
                        </Button>
                      </li>
                    )}
                    {screenWidth < 740 && showSaveAll && (
                      <li>
                        <Button size="6" onClick={closeEditAllHandler}>
                          Save All
                        </Button>
                      </li>
                    )}
                    {screenWidth < 740 && auth.userType === "Admin" && (
                      <li>
                        <Button
                          size="6"
                          onClick={() =>
                            navigate(
                              "/admin/details/Recycle" +
                              tableUrl.charAt(0).toUpperCase() +
                              tableUrl.slice(1)
                            )
                          }
                        >
                          Recycle Bin
                        </Button>
                      </li>
                    )}
                    {screenWidth < 1030 &&
                      showReleaseEmployeeButton &&
                      tableUrl === "employee" && (
                        <li>
                          <Button size="6" onClick={openReleaseDrawerHandler}>
                            Release User
                          </Button>
                        </li>
                      )}
                    {screenWidth < 1030 && showEditButton && (
                      <li>
                        <Button size="6" onClick={openUpdateOrderStatusModalHandler}>

                          Update Order Status

                        </Button>
                      </li>
                    )}
                    {screenWidth < 1030 && showEditButton && (
                      <li>
                        <Button size="6" onClick={openUpdateExpReceDateModalHandler}>

                          Update Exp Rece Date

                        </Button>
                      </li>
                    )}
                  </ul>
                ) : null}
                <div className={stylesTableHeader.rowbuton}> </div>
              </Button>
            )}
            <div className={stylesTableHeader.row}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableHeader;
