import React, { useState, useEffect, useContext } from "react";

import SideBarLogo from "./SideBarLogo";
import SideBarName from "./SideBarName";
import SideBarAvatar from "./SideBarAvatar";
import SideBarProfile from "./SideBarProfile";
import SideBarProfileLinks from "./SideBarProfileLinks";

import { AuthContext } from "../../../context/auth-context";

import styleSideBarMain from "./SideBarMain.module.css";

const SideBarMain = ({}) => {
  const auth = useContext(AuthContext);

  let departmentRouteName = auth.employeeDepartment;
  let employeeRouteName = auth.userName;

  let productRouteName = auth.employeeProduct;

  departmentRouteName = departmentRouteName.replace(/ /g, "-").toLowerCase();

  let productRouteNameArr = [];

  productRouteNameArr = productRouteName.split(",");
  return (
    <React.Fragment>
      <div className={styleSideBarMain.sidebar__main}>
        <SideBarLogo />
        <br></br>
        <SideBarAvatar />
        <br></br>
        <SideBarName />
        <br></br>
        <SideBarProfile />
      </div>
    </React.Fragment>
  );
};

export default SideBarMain;
