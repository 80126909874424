import React, { useContext, useState, useEffect } from "react";

import Input from "../../shared/components/FormElements/Input";
import Button from "../../shared/components/FormElements/Button";
import CustomSelect from "../../shared/components/FormElements/CustomSelect/CustomSelect";
import LocationCascading from "../../shared/components/FormElements/Location/LocationCascading";
import MaterialCascading from "../../shared/components/FormElements/Material/MaterialCascading";
import AssignmentCascading from "../../shared/components/FormElements/Assignment/AssignmentCascading";
import MultiSelect from "../../shared/components/FormElements/MultiSelect/MultiSelect";
import Date from "../../shared/components/FormElements/Date";
import AttachmentUpload from "../../shared/components/FormElements/FileUpload/AttachmentUpload";
import AvatarUpload from "../../shared/components/FormElements/FileUpload/AvatarUpload";
import ImageUpload from "../../shared/components/FormElements/FileUpload/ImageUpload";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

import {
    VALIDATOR_REQUIRE,
    VALIDATOR_MINLENGTH,
    VALIDATOR_EMAIL,
} from "../../shared/util/validators";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import stylessNewForm from "./NewForm.module.css";

const NewForm = ({
    projectname,
    tableUrl,
    setRefreshData,
    refreshData,
    closeNewDrawerHandler,
}) => {
    const auth = useContext(AuthContext);
    const [columns, setColumns] = useState([]);
    const [columnData, setColumnData] = useState({});
    const { sendRequest, clearError } = useHttpClient();
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [formState, inputHandler, setFormData] = useForm(columnData, false);
    const [dropdownData, setDropDownData] = useState([]);
    const [multiSelect, setMultiSelect] = useState([]);
    const [data, setData] = useState([]);
    let indexDropDown = [];
    let indexMultiSelect = [];
    useEffect(() => {
        const sendRequest = async () => {
            setIsLoading(true);




            // COLUMN DATA START
            try {


                //all data

                const responseVeri = await fetch(
                    `${process.env.REACT_APP_API_URL}/equipmentlibrary/project/${projectname}`,
                    {
                        method: "GET",
                        headers: { "Content-Type": "application/json" },
                    }
                );

                const responseVeriData = await responseVeri.json();

                setData(responseVeriData);

                //all data

                const responseColumns = await fetch(
                    `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/column`,
                    {
                        method: "GET",
                    }
                );

                const responseColumnsData = await responseColumns.json();
                console.log("responseColumnsData", responseColumnsData);
                if (!responseColumns.ok) {
                    throw new Error(responseColumnsData.message);
                }
                setColumns(responseColumnsData);

                const ctypeNew = responseColumnsData
                    .filter(({ ctypenew }) => ctypenew === true)
                    .map((el) => el);

                const uniqueNames =
                    ctypeNew !== false
                        ? Array.from(new Set(ctypeNew?.map((q) => q)))
                        : [];

                const arr = JSON.parse(JSON.stringify(uniqueNames));

                let receivedValues = {};
                let sendValues = {};
                let categorymapp = Object.values(arr)
                    .reduce(
                        (concatedArr, item) => concatedArr.concat(Object.entries(item)),
                        []
                    )
                    .filter((pilot) => pilot[0] === "value")
                    .reduce((result, [category, values]) => {
                        result["intvalue"] = result["intvalue"] || [];

                        result["intvalue"] = result["intvalue"].concat({
                            [values]: {
                                value: "",
                                isValid: false,
                            },
                        });

                        receivedValues = result;

                        return result;
                    }, {});

                for (let i = 0; i < categorymapp["intvalue"].length; i++) {
                    Object.assign(sendValues, categorymapp["intvalue"][i]);
                }

                setColumnData(sendValues);
                console.log("🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶", sendValues);

                setFormData(sendValues, true);
                // COLUMN DATA END

                // DROPDOWN DATA START
                let collectDropDown = [];
                for (let i = 0; i < responseColumnsData.length; i++) {
                    if (responseColumnsData[i].ctypeselection === true) {
                        collectDropDown = [
                            ...collectDropDown,
                            responseColumnsData[i].value,
                        ];
                    }
                }

                const numrows = collectDropDown.length;

                const blah = Array.from(Array(numrows).keys());

                for (let i = 0; i < blah.length; i++) {
                    const sendRequest = async () => {
                        console.log("👺 👺 👺 👺 👺 👺 👺 👺 👺 👺 👺 👺 👺 👺 👺 👺 👺 👺 ", collectDropDown[i]);
                        setIsLoading(true);

                        try {
                            var responseColumns2;
                            if (collectDropDown[i] === "spacename") {
                                var istenilen = projectname + "Space";
                                responseColumns2 = await fetch(
                                    `${process.env.REACT_APP_API_URL}/api2/dropdown/${istenilen}`,

                                    {
                                        method: "GET",
                                    }
                                );
                            } else {
                                responseColumns2 = await fetch(
                                    `${process.env.REACT_APP_API_URL}/api2/dropdown/${collectDropDown[i]}`,

                                    {
                                        method: "GET",
                                    }
                                );
                            }


                            const responseColumnsData2 = await responseColumns2.json();

                            if (!responseColumns2.ok) {
                                throw new Error(responseColumnsData2.message);
                            }

                            let changeDropDownName = [];
                            let categorymapp = Object.values(responseColumnsData2)
                                .reduce(
                                    (concatedArr, item) =>
                                        concatedArr.concat(Object.entries(item)),
                                    []
                                )
                                .reduce((result, [category, values]) => {

                                    result["intvalue"] = result["intvalue"] || [];

                                    result["intvalue"] = result["intvalue"].concat({
                                        name: values,
                                    });

                                    changeDropDownName = [
                                        ...changeDropDownName,
                                        {
                                            name: values,
                                        },
                                    ];

                                    return result;
                                }, {});

                            indexDropDown[i] = changeDropDownName;

                            const indexDropDownToJSON = JSON.parse(
                                JSON.stringify(indexDropDown)
                            );

                            setDropDownData(indexDropDownToJSON);
                        } catch (err) {
                            setError(err.message);
                        }
                        setIsLoading(false);
                    };
                    sendRequest();
                }
                // DROPDOWN DATA END


            } catch (err) {
                setError(err.message);
            }
            setIsLoading(false);
        };
        sendRequest();
    }, [setFormData]);

    console.log("formState.inputs", formState.inputs);



    const placeSubmitHandler = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        if (
            formState.inputs.attachmentfile?.value !== undefined &&
            formState.inputs.attachmentfile?.value.length > 1
        ) {
            try {
                let i;
                var formData = new FormData();

                for (var key in formState.inputs) {
                    if (key === "attachmentfile") {
                        for (
                            i = 0;
                            i < formState.inputs.attachmentfile?.value.length;
                            i++
                        ) {
                            console.log("🎃🎃🎃🎃", formState.inputs[key].value[i]);
                            formData.append(key, formState.inputs[key].value[i]);
                        }
                    } else {
                        formData.append(key, formState.inputs[key].value);
                    }
                }
                console.log("formData MULTIUPLOAD", formData);
                for (var pair of formData.entries()) {
                    console.log(pair[0] + ', ' + pair[1]);
                }
                await sendRequest(
                    `${process.env.REACT_APP_API_URL}/equipmentlibrary/singleupload/${projectname}`,
                    "POST",
                    formData,
                    {
                        Authorization: "Bearer " + auth.token,
                    }
                );
                setRefreshData(!refreshData);
                setIsLoading(false);
                closeNewDrawerHandler();
            } catch (err) { }
        } else if (
            formState.inputs.attachmentfile?.value !== undefined
        ) {
            try {
                var formData = new FormData();
                for (var key in formState.inputs) {
                    formData.append(key, formState.inputs[key].value);
                }

                await sendRequest(
                    `${process.env.REACT_APP_API_URL}/equipmentlibrary/singleupload/${projectname}`,
                    "POST",
                    formData,
                    {
                        Authorization: "Bearer " + auth.token,
                    }
                );
                setRefreshData(!refreshData);
                setIsLoading(false);
                closeNewDrawerHandler();
            } catch (err) {
                console.log(err);
            }
        } else {
            const withoutFile = async () => {
                try {
                    await sendRequest(
                        `${process.env.REACT_APP_API_URL}/equipmentlibrary/singleupload/${projectname}`,
                        "POST",

                        JSON.stringify(
                            Object.values(formState)
                                .reduce(
                                    (concatedArr, item) =>
                                        concatedArr.concat(Object.entries(item)),
                                    []
                                )
                                .reduce((result, [category, values]) => {
                                    console.log("değerler", category);

                                    result[category] = result[category] || "";
                                    result[category] = result[category].concat(values.value);
                                    console.log("authtoken", result);
                                    return result;
                                }, {})
                        ),

                        {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + auth.token,
                        }
                    );

                    setRefreshData(!refreshData);
                    setIsLoading(false);
                    closeNewDrawerHandler();
                } catch (err) {
                    console.log("err", err);
                }
            };
            withoutFile();
        }
    };

    let k = 0; // dropdown index
    let m = 0; // dropdown index

    return (
        <React.Fragment>
            <ErrorModal error={error} onClear={clearError} />
            <form className={stylessNewForm.place} onSubmit={placeSubmitHandler}>
                {isLoading && <LoadingSpinner asOverlay />}
                <div>{data[data.length - 1]?.itemnr === undefined ? formState.inputs.jdoc?.value.split(" ")[0] + formState.inputs.division?.value + "0001" : formState.inputs.jdoc?.value.split(" ")[0] + formState.inputs.division?.value + String(parseInt(data[data.length - 1]?.itemnr.substring(5, 9)) + 1).padStart(4, '0')}</div>

                {columns.map((item, i) => {
                    return item.ctypenew ? (
                        item.ctypeselection ? (
                            <>
                                <CustomSelect
                                    id={item.value}
                                    onChange={inputHandler}
                                    data={dropdownData[k]}
                                    name={item.value}
                                    label={item.cname}
                                ></CustomSelect>
                                <script>{(k = k + 1)}</script>
                            </>
                        ) : item.ctypeinput ? (
                            <Input
                                id={item.value}
                                element="input"
                                type="text"
                                label={item.cname}
                                validators={
                                    item.value === "email"
                                        ? [VALIDATOR_EMAIL()]
                                        : item.value === "password"
                                            ? [VALIDATOR_MINLENGTH(5)]
                                            : [VALIDATOR_REQUIRE()]
                                }
                                errorText="Please enter a valid title."
                                onInput={inputHandler}
                            />
                        ) : item.ctypeattachmentfile ? (
                            <AttachmentUpload
                                id="attachmentfile"
                                onInput={inputHandler}
                                errorText="Please provide an image."
                                initialValue=""
                            />
                        ) : null
                    ) : null;
                })}

                <Button size="3" type="submit">
                    Add New Item
                </Button>
            </form>
        </React.Fragment>
    );
};

export default NewForm;

/*

                <Button size="3" type="submit" disabled={!formState.isValid}>
                    Add New Item
                </Button>
*/
