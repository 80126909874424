import React, { useContext, useState, useEffect } from "react";

import Input from "../../shared/components/FormElements/Input";
import Button from "../../shared/components/FormElements/Button";
import CustomSelect from "../../shared/components/FormElements/CustomSelect/CustomSelect";
import LocationCascading from "../../shared/components/FormElements/Location/LocationCascading";
import MaterialCascading from "../../shared/components/FormElements/Material/MaterialCascading";
import AssignmentCascading from "../../shared/components/FormElements/Assignment/AssignmentCascading";
import MultiSelect from "../../shared/components/FormElements/MultiSelect/MultiSelect";
import DateComp from "../../shared/components/FormElements/Date";
// import PoNumber from "../../shared/components/FormElements/PoNumber";
import AttachmentUpload from "../../shared/components/FormElements/FileUpload/AttachmentUpload";
import AvatarUpload from "../../shared/components/FormElements/FileUpload/AvatarUpload";
import ImageUpload from "../../shared/components/FormElements/FileUpload/ImageUpload";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

import {
    VALIDATOR_REQUIRE,
    VALIDATOR_MINLENGTH,
    VALIDATOR_EMAIL,
} from "../../shared/util/validators";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import stylessNewForm from "./NewForm.module.css";


const ReleaseForm = ({
    selectedID,
    tableUrl,
    setRefreshData,
    refreshData,
    closeReleaseDrawerHandler,
}) => {
    const auth = useContext(AuthContext);
    const { sendRequest, clearError } = useHttpClient();
    const [columns, setColumns] = useState([]);
    const [columnData, setColumnData] = useState({});
    const [error, setError] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [dropdownData, setDropDownData] = useState([]);
    const [multiSelect, setMultiSelect] = useState([]);
    const [formState, inputHandler, setFormData] = useForm(columnData, false);
    const [loadedItem, setLoadedItem] = useState([]);
    let indexDropDown = [];
    let indexMultiSelect = [];
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            // COLUMN DATA START
            try {

                const responseItemm =
                    selectedID !== undefined
                        ? await sendRequest(
                            `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/${selectedID}`
                        )
                        : null;

                setLoadedItem(responseItemm);

                const responseColumns = await fetch(
                    `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/column`,
                    {
                        method: "GET",
                    }
                );

                const responseColumnsData = await responseColumns.json();

                if (!responseColumns.ok) {
                    throw new Error(responseColumnsData.message);
                }


                setColumns(responseColumnsData);

                const ctypeNew = responseColumnsData
                    .filter(({ ctyperelease }) => ctyperelease === true)
                    .map((el) => el);

                const uniqueNames =
                    ctypeNew !== false
                        ? Array.from(new Set(ctypeNew?.map((q) => q)))
                        : [];

                const arr = JSON.parse(JSON.stringify(uniqueNames));

                let receivedValues = {};
                let sendValues = {};
                let categorymapp = Object.values(arr)
                    .reduce(
                        (concatedArr, item) => concatedArr.concat(Object.entries(item)),
                        []
                    )
                    .filter((pilot) => pilot[0] === "value")
                    .reduce((result, [category, values]) => {
                        result["intvalue"] = result["intvalue"] || [];

                        result["intvalue"] = result["intvalue"].concat({
                            [values]: {
                                value: "",
                                isValid: false,
                            },
                        });

                        receivedValues = result;

                        return result;
                    }, {});

                for (let i = 0; i < categorymapp["intvalue"].length; i++) {
                    Object.assign(sendValues, categorymapp["intvalue"][i]);
                }

                setColumnData(sendValues);
                console.log("🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶", sendValues);

                setFormData(sendValues, true);
                // COLUMN DATA END

                // DROPDOWN DATA START
                let collectDropDown = [];
                for (let i = 0; i < responseColumnsData.length; i++) {
                    if (responseColumnsData[i].ctyperelease && responseColumnsData[i].ctypeselection === true) {
                        collectDropDown = [
                            ...collectDropDown,
                            responseColumnsData[i].value,
                        ];
                    }
                }

                const numrows = collectDropDown.length;

                const blah = Array.from(Array(numrows).keys());
                for (let i = 0; i < blah.length; i++) {
                    const sendRequest = async () => {
                        setIsLoading(true);

                        try {
                            const responseColumns2 = await fetch(
                                `${process.env.REACT_APP_API_URL}/api2/dropdown/${collectDropDown[i]}`,

                                {
                                    method: "GET",
                                }
                            );

                            const responseColumnsData2 = await responseColumns2.json();

                            if (!responseColumns2.ok) {
                                throw new Error(responseColumnsData2.message);
                            }
                            console.log(
                                "🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏",
                                responseColumnsData2
                            );
                            let changeDropDownName = [];
                            let categorymapp = Object.values(responseColumnsData2)
                                .reduce(
                                    (concatedArr, item) =>
                                        concatedArr.concat(Object.entries(item)),
                                    []
                                )
                                .reduce((result, [category, values]) => {
                                    result["intvalue"] = result["intvalue"] || [];
                                    result["intvalue"] = result["intvalue"].concat({
                                        name: values,
                                    });

                                    changeDropDownName = [
                                        ...changeDropDownName,
                                        {
                                            name: values,
                                        },
                                    ];

                                    return result;
                                }, {});

                            indexDropDown[i] = changeDropDownName;

                            const indexDropDownToJSON = JSON.parse(
                                JSON.stringify(indexDropDown)
                            );

                            setDropDownData(indexDropDownToJSON);
                        } catch (err) {
                            setError(err.message);
                        }
                        setIsLoading(false);
                    };
                    sendRequest();
                }
                // DROPDOWN DATA END

                // MULTISELECT DATA START

                let collectMultiSelect = [];
                for (let i = 0; i < responseColumnsData.length; i++) {
                    if (responseColumnsData[i].ctypemultiselect === true) {
                        collectMultiSelect = [
                            ...collectMultiSelect,
                            responseColumnsData[i].value,
                        ];
                    }
                }

                const numrows2 = collectMultiSelect.length;

                const blah2 = Array.from(Array(numrows2).keys());

                if (collectMultiSelect.length !== 0) {
                    for (let i = 0; i < blah2.length; i++) {
                        const sendRequest = async () => {
                            setIsLoading(true);
                            console.log("collectMultiSelect[i]", collectMultiSelect[i]);
                            try {
                                const responseColumns4 = await fetch(
                                    `${process.env.REACT_APP_API_URL}/api2/multiselect/${collectMultiSelect[i]}`,

                                    {
                                        method: "GET",
                                    }
                                );

                                const responseColumnsData4 = await responseColumns4.json();

                                console.log(
                                    "🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️",
                                    responseColumnsData4
                                );

                                if (!responseColumns4.ok) {
                                    throw new Error(responseColumnsData4.message);
                                }

                                let changeMultiSelectName = [];
                                let categorymapp = Object.values(responseColumnsData4)
                                    .reduce(
                                        (concatedArr, item) =>
                                            concatedArr.concat(Object.entries(item)),
                                        []
                                    )
                                    .reduce((result, [category, values]) => {
                                        result["intvalue"] = result["intvalue"] || [];
                                        result["intvalue"] = result["intvalue"].concat({
                                            name: values,
                                        });

                                        changeMultiSelectName = [
                                            ...changeMultiSelectName,
                                            {
                                                name: values,
                                            },
                                        ];

                                        return result;
                                    }, {});

                                indexMultiSelect[i] = changeMultiSelectName;

                                const indexMultiSelectToJSON = JSON.parse(
                                    JSON.stringify(indexMultiSelect)
                                );

                                // setDropDownData(indexDropDownToJSON);

                                setMultiSelect(indexMultiSelectToJSON);
                            } catch (err) {
                                setError(err.message);
                            }
                            setIsLoading(false);
                        };
                        sendRequest();
                    }
                }

                // MULTISELECT DATA END
            } catch (err) {
                setError(err.message);
            }
            setIsLoading(false);
        };
        fetchData();
    }, [setFormData]);

    console.log("formState.inputs", formState.inputs);


    const releaseSubmitHandler = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        if (
            formState.inputs.attachmentfile?.value !== undefined &&
            formState.inputs.attachmentfile?.value.length > 1
        ) {
            try {
                let i;
                var formData = new FormData();

                for (var key in formState.inputs) {
                    if (key === "attachmentfile") {
                        formData.append("name", loadedItem.name);
                        for (
                            i = 0;
                            i < formState.inputs.attachmentfile?.value.length;
                            i++
                        ) {
                            console.log("🎃🎃🎃🎃", formState.inputs[key].value[i]);
                            formData.append(key, formState.inputs[key].value[i]);
                        }
                    } else {
                        formData.append(key, formState.inputs[key].value);
                    }
                }
                console.log("formData MULTIUPLOAD", formData);
                for (var pair of formData.entries()) {
                    console.log(pair[0] + ', ' + pair[1]);
                }


                await sendRequest(
                    `${process.env.REACT_APP_API_URL}/api2/releaseuser/${tableUrl}/${selectedID}`,
                    "POST",
                    formData,
                    {
                        Authorization: "Bearer " + auth.token,
                    }
                );
                setRefreshData(!refreshData);
                setIsLoading(false);
                closeReleaseDrawerHandler();
            } catch (err) { }
        } else if (
            formState.inputs.attachmentfile?.value !== undefined
        ) {
            try {
                var formData = new FormData();
                for (var key in formState.inputs) {
                    if (key === "releasedate") {
                        formData.append("name", loadedItem.name);
                        formData.append(key, formState.inputs[key].value);
                    }
                    else {
                        formData.append(key, formState.inputs[key].value);
                    }
                }

                await sendRequest(
                    `${process.env.REACT_APP_API_URL}/api2/releaseuser/${tableUrl}/${selectedID}`,
                    "POST",
                    formData,
                    {
                        Authorization: "Bearer " + auth.token,
                    }
                );
                setRefreshData(!refreshData);
                setIsLoading(false);
                closeReleaseDrawerHandler();
            } catch (err) {
                console.log(err);
            }
        } else {
            const withoutFile = async () => {
                try {
                    await sendRequest(
                        `${process.env.REACT_APP_API_URL}/api2/releaseuser/${tableUrl}/${selectedID}`,
                        "POST",

                        JSON.stringify(
                            Object.values(formState)
                                .reduce(
                                    (concatedArr, item) =>
                                        concatedArr.concat(Object.entries(item)),
                                    []
                                )
                                .reduce((result, [category, values]) => {
                                    console.log("değerler", category);

                                    result[category] = result[category] || "";
                                    result[category] = result[category].concat(values.value);
                                    console.log("authtoken", result);
                                    return result;
                                }, {})
                        ),

                        {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + auth.token,
                        }
                    );

                    setRefreshData(!refreshData);
                    setIsLoading(false);
                    closeReleaseDrawerHandler();
                } catch (err) {
                    console.log("err", err);
                }
            };
            withoutFile();
        }
    };

    let k = 0; // dropdown index
    let m = 0; // dropdown index
    return (
        <React.Fragment>
            <ErrorModal error={error} onClear={clearError} />
            <form className={stylessNewForm.place} onSubmit={releaseSubmitHandler}>
                {isLoading && <LoadingSpinner asOverlay />}

                {columns.map((item, i) => {
                    return item.ctyperelease ? (
                        item.ctypeselection ? (
                            <>
                                <CustomSelect
                                    id={item.value}
                                    onChange={inputHandler}
                                    data={dropdownData[k]}
                                    name={item.value}
                                    label={item.cname}
                                ></CustomSelect>
                                <script>{(k = k + 1)}</script>
                            </>
                        ) : item.ctypeinput &&
                            item.value !== "po" &&
                            item.value !== "rqcnumber" ? (
                            <Input
                                id={item.value}
                                element="input"
                                type="text"
                                label={item.cname}
                                validators={
                                    item.value === "email"
                                        ? [VALIDATOR_EMAIL()]
                                        : item.value === "password"
                                            ? [VALIDATOR_MINLENGTH(5)]
                                            : [VALIDATOR_REQUIRE()]
                                }
                                errorText="Please enter a valid title."
                                onInput={inputHandler}
                            />
                        ) : item.ctypedate ? (
                            <DateComp
                                id={item.value}
                                element="input"
                                type="date"
                                label={item.cname}
                                validators={[VALIDATOR_REQUIRE()]}
                                errorText="Please enter a date."
                                onInput={inputHandler}
                            />
                        ) : item.ctypemultiselect ? (
                            <>
                                <MultiSelect
                                    id={item.value}
                                    onChange={inputHandler}
                                    data={multiSelect[m]}
                                    name={item.value}
                                    label={item.cname}
                                />
                                <script>{(m = m + 1)}</script>
                            </>
                        ) : item.ctypeattachmentfile ? (
                            <AttachmentUpload
                                id="attachmentfile"
                                onInput={inputHandler}
                                initialValue=""
                            />
                        ) : null
                    ) : null;
                })}
                <br></br>
                <Button size="3" type="submit" disabled={!formState.isValid}>
                    Release Employee
                </Button>
                <br></br>
            </form>
        </React.Fragment>
    );
};

export default ReleaseForm;
