import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import LibraryTableBody from "./Components/LibraryTableBody";
import LibraryTableHeader from "./Components/LibraryTableHeader";
import LibraryNewForm from "./Forms/LibraryNewForm";
import BackDrop from "../shared/components/UIElements/BackDrop";

import Modal from "../shared/components/UIElements/Modal";
import LibraryDeleteForm from "./Forms/LibraryDeleteForm";
import LibraryRenameForm from "./Forms/LibraryRenameForm";
const DocumentLibrary = () => {
    const [folderName, setFolderName] = useState('');
    const [folder, setFolder] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    var [departmentname, setDepartmentname] = useState("PM/");
    var [refreshTable, setRefreshTable] = useState(false);
    const [isCheckedFolder, setIsCheckedFolder] = useState([]);
    const [checkedListFolder, setCheckedListFolder] = useState([]);
    const [isCheckedFile, setIsCheckedFile] = useState([]);
    const [checkedListFile, setCheckedListFile] = useState([]);

    const [newModalIsOpen, setNewModalIsOpen] = useState(false);
    const openNewModalHandler = () => {
        setNewModalIsOpen(true);
    };

    const closeNewModalHandler = () => {
        setNewModalIsOpen(false);
    };

    const [showDeleteButton, setShowDeleteButton] = useState(false);
    useEffect(() => {

        if (isCheckedFolder.length !== 0 || isCheckedFile.length !== 0) {
            setShowDeleteButton(true);
        } else {
            setShowDeleteButton(false);
        }
        console.log(isCheckedFolder);
        console.log(isCheckedFile);

    }, [isCheckedFolder, isCheckedFile]);

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const openDeleteModalHandler = () => {
        setDeleteModalIsOpen(true);
    };

    const closeDeleteModalHandler = () => {
        setDeleteModalIsOpen(false);
    };

    const [renameModalIsOpen, setRenameModalIsOpen] = useState(false);
    const openRenameModalHandler = () => {
        setRenameModalIsOpen(true);
    };

    const closeRenameModalHandler = () => {
        setRenameModalIsOpen(false);
    };
    return (
        <React.Fragment>
            <h3>Documents</h3>
            <LibraryTableHeader
                openNewModalHandler={openNewModalHandler}
                departmentname={departmentname}
                setRefreshTable={setRefreshTable}
                refreshTable={refreshTable}
                showDeleteButton={showDeleteButton}
                openDeleteModalHandler={openDeleteModalHandler}
                openRenameModalHandler={openRenameModalHandler}
            ></LibraryTableHeader>
            <LibraryTableBody
                departmentname={departmentname}
                setDepartmentname={setDepartmentname}
                refreshTable={refreshTable}
                setIsCheckedFolder={setIsCheckedFolder}
                isCheckedFolder={isCheckedFolder}
                setCheckedListFolder={setCheckedListFolder}
                checkedListFolder={checkedListFolder}
                setIsCheckedFile={setIsCheckedFile}
                isCheckedFile={isCheckedFile}
                setCheckedListFile={setCheckedListFile}
                checkedListFile={checkedListFile}
            ></LibraryTableBody>
            {newModalIsOpen && <BackDrop onClick={closeNewModalHandler} />}
            <Modal show={newModalIsOpen}>
                <LibraryNewForm
                    departmentname={departmentname} setDepartmentname={setDepartmentname} closeNewModalHandler={closeNewModalHandler} setRefreshTable={setRefreshTable} refreshTable={refreshTable}
                />
            </Modal>
            {deleteModalIsOpen && <BackDrop onClick={closeDeleteModalHandler} />}
            <Modal show={deleteModalIsOpen}>
                <LibraryDeleteForm departmentname={departmentname} closeDeleteModalHandler={closeDeleteModalHandler} isCheckedFolder={isCheckedFolder} isCheckedFile={isCheckedFile} setRefreshTable={setRefreshTable}
                    refreshTable={refreshTable}
                    setIsCheckedFolder={setIsCheckedFolder}
                    setCheckedListFolder={setCheckedListFolder}
                    setIsCheckedFile={setIsCheckedFile}
                    setCheckedListFile={setCheckedListFile}
                />
            </Modal>
            {renameModalIsOpen && <BackDrop onClick={closeRenameModalHandler} />}
            <Modal show={renameModalIsOpen}>
                <LibraryRenameForm
                    departmentname={departmentname} setDepartmentname={setDepartmentname} closeRenameModalHandler={closeRenameModalHandler} setRefreshTable={setRefreshTable} refreshTable={refreshTable}
                    isCheckedFolder={isCheckedFolder} isCheckedFile={isCheckedFile}
                />
            </Modal>
        </React.Fragment>
    );


};

export default DocumentLibrary;