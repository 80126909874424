import React, { useContext, useState, useEffect } from "react";
import FilterComponentCss from "./filterComponent.module.css";

const FilterComponent = ({ title, options, inputHandler }) => {
    const [isCheckedDivision, setIsCheckedDivision] = useState([]);
    const [checkedListDivision, setCheckedListDivision] = useState([]);
    const [open, setOpen] = useState(true);

    function handleCheckDivision(e, faf) {

        var { value, checked } = e.target;

        let updatedArr = [];
        if (title === "Status") {
            if (checked) {
                if (value === "Active") { value = true } else if (value === "Inactive") { value = false }
                setIsCheckedDivision([...isCheckedDivision, value]);
                inputHandler(title, [...isCheckedDivision, value], true);
                updatedArr = checkedListDivision.map((val, idx) =>
                    idx === faf.findIndex((el) => el.name === value) ? true : val
                );
            } else {
                if (value === "Active") { value = true } else if (value === "Inactive") { value = false }
                setIsCheckedDivision(isCheckedDivision.filter((e) => e !== value));
                inputHandler(title, isCheckedDivision.filter((e) => e !== value), true);
                updatedArr = checkedListDivision.map((val, idx) =>
                    idx === faf.findIndex((el) => el.name === value) ? false : val
                );
            }
        } else if (title === "Space") {
            if (checked) {
                value = e.target.id;
                setIsCheckedDivision([...isCheckedDivision, value]);
                inputHandler(title, [...isCheckedDivision, value], true);
                updatedArr = checkedListDivision.map((val, idx) =>
                    idx === faf.findIndex((el) => el.name === value) ? true : val
                );
            } else {
                value = e.target.id;
                setIsCheckedDivision(isCheckedDivision.filter((e) => e !== value));
                inputHandler(title, isCheckedDivision.filter((e) => e !== value), true);
                updatedArr = checkedListDivision.map((val, idx) =>
                    idx === faf.findIndex((el) => el.name === value) ? false : val
                );
            }
        } else {
            if (checked) {

                setIsCheckedDivision([...isCheckedDivision, value]);
                inputHandler(title, [...isCheckedDivision, value], true);
                updatedArr = checkedListDivision.map((val, idx) =>
                    idx === faf.findIndex((el) => el.name === value) ? true : val
                );
            } else {
                setIsCheckedDivision(isCheckedDivision.filter((e) => e !== value));
                inputHandler(title, isCheckedDivision.filter((e) => e !== value), true);
                updatedArr = checkedListDivision.map((val, idx) =>
                    idx === faf.findIndex((el) => el.name === value) ? false : val
                );
            }
        }


        setCheckedListDivision(updatedArr);
    };



    return (


        <>
            <br></br>
            <div class={FilterComponentCss.filter_div} >
                <h4 class={open ? FilterComponentCss.h4_opened : FilterComponentCss.h4_closed} onClick={() => setOpen(!open)}>
                    <span>{title}</span>

                </h4>
                {open && options && options.map((opt, index) => (
                    <>

                        <div>
                            <input
                                type="checkbox"
                                value={opt.name}
                                // checked={checkedListDivision[index]}
                                onChange={(event) => handleCheckDivision(event, opt.name)}
                                id={title === "Space" ? opt.number : opt.name}
                            ></input>
                            <label>{opt.name}</label>

                        </div>
                    </>

                ))}
            </div>

            <br></br>
        </>
    );
};
export default FilterComponent;