import React, { useContext } from "react";

import styleSideBarAvatar from "./SideBarMain.module.css";
import { AuthContext } from "../../../context/auth-context";

const SideBarAvatar = () => {
  const auth = useContext(AuthContext);
  return (
    <div>
      {" "}
      <img
        src={auth.avatarLink}
        className={styleSideBarAvatar.sidebar__avatar}
      />
    </div>
  );
};

export default SideBarAvatar;
