import React, { useEffect, useState, useContext } from "react";

import Button from "../../shared/components/FormElements/Button";

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
//import "./NewForm.css";

const LibraryNewForm = ({ departmentname, setDepartmentname, closeNewModalHandler, setRefreshTable, refreshTable }) => {
    const [folderName, setFolderName] = useState('');
    const { error, sendRequest, clearError } = useHttpClient();
    const [isLoading, setIsLoading] = useState(false);

    const createFolder = async () => {
        setIsLoading(true);
        console.log(departmentname);
        var new_folder_path;
        if (!departmentname.includes("/")) {
            new_folder_path = departmentname + "/" + folderName + "/";
        } else {
            new_folder_path = departmentname + folderName + "/";
        }

        try {

            await sendRequest(
                `${process.env.REACT_APP_API_URL}/filemanager/createFolder`,
                "POST",
                JSON.stringify({
                    name: folderName,
                    path: new_folder_path
                }),
                {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                }
            );
            setIsLoading(false);
            setFolderName('');
            setDepartmentname(departmentname);
            setRefreshTable(!refreshTable);
            closeNewModalHandler();
        } catch (err) {
            setIsLoading(false);
            alert(err);
            console.log(err);
            closeNewModalHandler();
        }


        /* fetch('http://localhost:5200/filemanager/createFolder', {
             method: 'post',
             headers: {
                 'Content-Type': 'application/json',
                 'Authorization': 'Bearer ' + localStorage.getItem('jwt')
             },
             body: JSON.stringify({
                 name: folderName,
                 path: new_folder_path
             })
         }).then(res => res.json()).then((data) => {
             console.log(data);
             const { err, folder } = data;
             if (err) {
                 setIsLoading(false);
                 alert(err);
                 console.log(err);
 
             }
             else {
                 setIsLoading(false);
                 alert("Folder created Successfully");
 
                 console.log(folder);
 
                 setFolderName('');
                 setDepartmentname(departmentname);
 
             }
         })*/
    }
    if (isLoading) {
        return (
            <div className="center">
                <LoadingSpinner />
            </div>
        );
    }

    return (
        <React.Fragment>

            {!isLoading && (
                <div >

                    <input type="text" value={folderName} placeholder="Folder name.." id="folder-name-input"
                        onChange={(e) => setFolderName(e.target.value)}
                    />
                    <button onClick={createFolder}>Oluştur</button>
                    {isLoading && <LoadingSpinner asOverlay />}
                </div>
            )}
        </React.Fragment>
    );
};

export default LibraryNewForm;