import { createContext } from "react";

export const AuthContext = createContext({
  isLoggedIn: false,
  userId: null,
  token: null,
  username: null,
  usertype: null,
  department: null,
  assginment: null,
  product: null,
  enabledlist: null,
  name: null,
  avatar: null,
  login: () => { },
  logout: () => { },
});
