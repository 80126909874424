import React, { useEffect, useState, useContext } from "react";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import DateComp from "../../shared/components/FormElements/Date";
import {
  VALIDATOR_REQUIRE
} from "../../shared/util/validators";
import { useForm } from "../../shared/hooks/form-hook";
const TableUpdateExpReceDateForm = ({ selectedID, tableUrl, isChecked, closeUpdateExpReceDateModalHandler, setRefreshData, refreshData, setIsChecked }) => {
  const auth = useContext(AuthContext);
  const [formState, inputHandler] = useForm(
    {
      expreceivedate: {
        value: '',
        isValid: false
      },

    },
    false
  );
  const { error, sendRequest, clearError } = useHttpClient();
  const [isLoading, setIsLoading] = useState(false);



  async function submitApprove() {
    setIsLoading(true);

    console.log("selectedID", selectedID);


    try {

      await sendRequest(
        `${process.env.REACT_APP_API_URL}/api2/updateorder `,
        "POST",
        JSON.stringify({
          id: isChecked,
          value: formState.inputs.expreceivedate.value,
          field: "expreceivedate",
          tableUrl: tableUrl,
        }),
        {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + auth.token,
        }
      );
      setIsLoading(false);
      // setDate('');
      setRefreshData(!refreshData);
      closeUpdateExpReceDateModalHandler();
    } catch (err) {
      setIsLoading(false);
      alert(err);
      console.log(err);
      closeUpdateExpReceDateModalHandler();
    }


  }
  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }
  return (<React.Fragment>
    <div>Update Exp. Receive Date</div>
    {!isLoading && (
      <div >
        <DateComp
          id={"expreceivedate"}
          element="input"
          type="date"
          label={"Exp. Receive Date"}
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Please enter a date."
          onInput={inputHandler}
        />
        <button onClick={submitApprove}>Update</button>
        {isLoading && <LoadingSpinner asOverlay />}
      </div>
    )}
  </React.Fragment>);
};

export default TableUpdateExpReceDateForm;
