import React, { useState, useContext } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import "./LoginPage.css";
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
} from "../../shared/util/validators";
import Input from "../../shared/components/FormElements/Input";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import Button from "../../shared/components/FormElements/Button";
import { useForm } from "../../shared/hooks/form-hook";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
const LoginPage = () => {
  const auth = useContext(AuthContext);
  const [isLoginMode, setIsLoginMode] = useState(true);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const navigate = useNavigate();

  const [formState, inputHandler, setFormData] = useForm(
    {
      username: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  const switchModeHandler = () => {
    if (!isLoginMode) {
      setFormData(
        {
          ...formState.inputs,
          name: undefined,
        },
        formState.inputs.email.isValid && formState.inputs.password.isValid
      );
    } else {
      setFormData(
        {
          ...formState.inputs,
          name: {
            value: "",
            isValid: false,
          },
        },
        false
      );
    }
    setIsLoginMode((prevMode) => !prevMode);
  };

  const authSubmitHandler = async (event) => {
    event.preventDefault();

    // if (isLoginMode) {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/api2/login`,
        "POST",
        JSON.stringify({
          username: formState.inputs.username.value,
          password: formState.inputs.password.value,
        }),
        {
          "Content-Type": "application/json",
        }
      );

      auth.login(
        responseData.userId,
        responseData.token,
        responseData.username,
        responseData.usertype,
        responseData.department,
        responseData.assignment,
        responseData.product,
        responseData.enabledlist,
        responseData.name,
        responseData.avatarpath
      );

      console.log("usertype", responseData.usertype);
      /*
      responseData.usertype === "Admin"
        ? navigate("/admin")
        : responseData.usertype !== "Admin" &&
          responseData.usertype === "Internal"
        ? navigate("/employee/" + responseData.username)
        : navigate("/");
*/

      navigate("/employee/" + responseData.username);
      // navigate("/");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />

      <div className={"login-page"}>
        <div className="logo_image"> </div>
        <div className="form">
          <form className="login-form" onSubmit={authSubmitHandler}>
            <Input
              element="input"
              id="username"
              type="username"
              label="User Name"
              placeholder="User Name"
              validators={[VALIDATOR_REQUIRE()]}
              errorText="Please enter a valid User Name."
              onInput={inputHandler}
            />
            <Input
              className="form input"
              element="input"
              id="password"
              type="password"
              label="Password"
              placeholder="Password"
              validators={[VALIDATOR_MINLENGTH(5)]}
              errorText="Please enter a valid password, at least 5 characters."
              onInput={inputHandler}
            />
            <Button type="submit" disabled={!formState.isValid}>
              LOGIN
            </Button>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LoginPage;
