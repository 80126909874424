import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";

import Input from "../../shared/components/FormElements/Input";
import Button from "../../shared/components/FormElements/Button";
import CustomSelect from "../../shared/components/FormElements/CustomSelect/CustomSelect";
import LocationCascading from "../../shared/components/FormElements/Location/LocationCascading";
import MultiSelect from "../../shared/components/FormElements/MultiSelect/MultiSelect";
import AttachmentUpload from "../../shared/components/FormElements/FileUpload/AttachmentUpload";
import AvatarUpload from "../../shared/components/FormElements/FileUpload/AvatarUpload";
import ImageUpload from "../../shared/components/FormElements/FileUpload/ImageUpload";
import Date from "../../shared/components/FormElements/Date";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import Modal from "../../shared/components/UIElements/Modal";
import {
    VALIDATOR_REQUIRE,
    VALIDATOR_MINLENGTH,
} from "../../shared/util/validators";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import stylesEditForm from "./EqipmentLibraryEditForm.module.css";
const EditForm = ({
    projectname,
    selectedID,
    tableUrl,
    setRefreshData,
    refreshData,
    closeEditDrawerHandler,
    setIsChecked,
    setIsCheckedFile,
    setSelectedID,
    checkedlist,
    setCheckedList,

}) => {
    const auth = useContext(AuthContext);
    const [columns, setColumns] = useState([]);
    const [columnData, setColumnData] = useState({});
    const { error, sendRequest, clearError } = useHttpClient();
    const [isLoading, setIsLoading] = useState(false);
    const [loadedItem, setLoadedItem] = useState([]);
    const itemId = `${selectedID}`;

    const [formState, inputHandler, setFormData] = useForm(columnData, false);

    const [removedFileName, setRemovedFileName] = useState("");
    const [attachmentInitialValue, setAttachmentInitialValue] = useState([]);
    const [book, setBook] = useState([]);
    const [jdocs, setJDoc] = useState([]);
    const [attachmentUpdatedValue, setAttachmentUpdatedValue] = useState([]);

    const [attachmentNameUpdatedValue, setAttachmentNameUpdatedValue] = useState(
        []
    );

    const [removedAvatarName, setRemovedAvatarName] = useState();
    const [removedImageName, setRemovedImageName] = useState();

    let choosen = [];
    let choosenAtt = [];

    useEffect(() => {
        if (loadedItem.attachmentname !== undefined) {
            choosen = loadedItem.attachmentname;
            setAttachmentNameUpdatedValue(choosen);
        }
    }, [loadedItem.attachmentname]);

    useEffect(() => {
        if (loadedItem.attachmentpath !== undefined) {
            choosenAtt = loadedItem.attachmentpath;
            setAttachmentUpdatedValue(choosenAtt);
        }
    }, [loadedItem.attachmentpath]);

    useEffect(() => {
        if (removedFileName !== "") {
            setBook((searches) => searches.concat(removedFileName));
        }
    }, [removedFileName]);

    console.log("BOOOOOK", book);

    useEffect(() => {
        if (removedFileName !== "") {
            const otbok2 =
                typeof removedFileName !== "undefined" &&
                attachmentNameUpdatedValue
                    .filter((value) =>
                        removedFileName[0] ? value !== removedFileName[0] : true
                    )
                    .map((el) => el);

            setAttachmentNameUpdatedValue(otbok2);

            const otbok3 =
                typeof removedFileName !== "undefined" &&
                attachmentUpdatedValue
                    .filter((value) =>
                        removedFileName[0] ? !value.includes(removedFileName[0]) : true
                    )
                    .map((el) => el);

            setAttachmentUpdatedValue(otbok3);

            inputHandler("attachmentname", otbok2, true);
            inputHandler("attachmentpath", otbok3, true);
        }
    }, [removedFileName]);

    let initialFiles = [];

    useEffect(() => {
        if (loadedItem.attachmentname !== undefined) {
            initialFiles = loadedItem.attachmentname;
            setAttachmentInitialValue(initialFiles);

        } else setAttachmentInitialValue("");
    }, [loadedItem.attachmentname]);





    const [dropdownData, setDropDownData] = useState([]);

    const [dropdown, setDropDown] = useState([]);
    const [collectDropDownName, setCollectDropDownName] = useState([]);


    let indexDropDown = [];

    useEffect(() => {
        var abc = projectname + tableUrl;
        console.log("tableUrl", abc);
        console.log("itemId", itemId);
        const fetchItem = async () => {
            try {

                const responseData =
                    itemId !== undefined
                        ? await sendRequest(
                            `${process.env.REACT_APP_API_URL}/equipmentlibrary/getbyid/${abc}/data/${itemId}`
                        )
                        : null;


                setLoadedItem(responseData);

                const responseColumns =
                    itemId !== undefined
                        ? await fetch(`${process.env.REACT_APP_API_URL}/api2/${tableUrl}/column`, {
                            method: "GET",
                        })
                        : null;

                const responseColumnsData = await responseColumns.json();

                if (!responseColumns.ok) {
                    throw new Error(responseColumnsData.message);
                }


                setColumns(responseColumnsData);

                //EKLEME BURADAN BAŞLIYOR

                let collectDropDown = [];
                for (let i = 0; i < responseColumnsData.length; i++) {
                    if (responseColumnsData[i].ctypeselection === true) {
                        collectDropDown = [
                            ...collectDropDown,
                            responseColumnsData[i].value,
                        ];
                    }
                }

                console.log("collectDropDown", collectDropDown);
                setCollectDropDownName(collectDropDown);

                const numrows = collectDropDown.length;

                const blah = Array.from(Array(numrows).keys());
                console.log("BLAH", blah);

                for (let i = 0; i < blah.length; i++) {


                    const sendRequest = async () => {
                        //  setIsLoading(true);

                        try {


                            var responseColumns2;
                            if (collectDropDown[i] === "spacename") {
                                var istenilen = projectname + "Space";
                                responseColumns2 = await fetch(
                                    `${process.env.REACT_APP_API_URL}/api2/dropdown/${istenilen}`,

                                    {
                                        method: "GET",
                                    }
                                );
                            } else {
                                responseColumns2 = await fetch(
                                    `${process.env.REACT_APP_API_URL}/api2/dropdown/${collectDropDown[i]}`,

                                    {
                                        method: "GET",
                                    }
                                );
                            }








                            const responseColumnsData2 = await responseColumns2.json();
                            if (collectDropDown[i] === "jdoc") {

                                setJDoc(responseColumnsData2)
                            }
                            if (!responseColumns2.ok) {
                                throw new Error(responseColumnsData2.message);
                            }

                            let changeDropDownName = [];
                            let categorymapp = Object.values(responseColumnsData2)
                                .reduce(
                                    (concatedArr, item) =>
                                        concatedArr.concat(Object.entries(item)),
                                    []
                                )
                                .reduce((result, [category, values]) => {
                                    result["intvalue"] = result["intvalue"] || [];
                                    result["intvalue"] = result["intvalue"].concat({
                                        name: values,
                                    });

                                    changeDropDownName = [
                                        ...changeDropDownName,
                                        {
                                            name: values,
                                        },
                                    ];

                                    return result;
                                }, {});

                            indexDropDown[i] = changeDropDownName;

                            const indexDropDownToJSON = JSON.parse(
                                JSON.stringify(indexDropDown)
                            );

                            setDropDownData(indexDropDownToJSON);

                            const numrows2 = indexDropDownToJSON.length;

                            const blah2 = Array.from(Array(numrows2).keys());
                            console.log("BLAH", blah2);

                            setDropDown(blah2);
                        } catch (err) { }
                        // setIsLoading(false);
                    };
                    sendRequest();
                }





                //EKLEME BURADA BITIYOR

                const ctypeEdit = responseColumnsData
                    .filter(({ ctypeedit }) => ctypeedit === true)
                    .map((el) => el);

                const uniqueNames =
                    ctypeEdit !== false
                        ? Array.from(new Set(ctypeEdit?.map((q) => q)))
                        : [];

                const arr = JSON.parse(JSON.stringify(uniqueNames));

                let receivedValues = {};
                let sendValues = {};
                let categorymapp = Object.values(arr)
                    .reduce(
                        (concatedArr, item) => concatedArr.concat(Object.entries(item)),
                        []
                    )
                    .filter((pilot) => pilot[0] === "value")
                    .reduce((result, [category, values]) => {
                        result["intvalue"] = result["intvalue"] || [];

                        result["intvalue"] = result["intvalue"].concat({
                            [values]: {
                                value: responseData[values],
                                isValid: true,
                            },
                        });

                        receivedValues = result;

                        return result;
                    }, {});

                for (let i = 0; i < categorymapp["intvalue"].length; i++) {
                    Object.assign(sendValues, categorymapp["intvalue"][i]);
                }

                setColumnData(sendValues);

                setFormData(sendValues, true);
                console.log("sendvalues", sendValues);
            } catch (err) { }
        };
        if (itemId !== "undefined") {
            fetchItem();
        }

    }, [sendRequest, itemId, setFormData]);

    if (isLoading) {
        return (
            <div className="center">
                <LoadingSpinner />
            </div>
        );
    }

    if (!loadedItem && !error) {
        return (
            <div className="center">
                <div>
                    <h2>Could not find place!</h2>
                </div>
            </div>
        );
    }

    const placeUpdateSubmitHandler = async (event) => {
        event.preventDefault();

        var abc = projectname + tableUrl;
        setIsLoading(true);
        if (
            formState.inputs.attachmentfile?.value !== undefined
        ) {
            let i;
            try {
                var formData = new FormData();

                for (var key in formState.inputs) {

                    if (key === "attachmentfile") {

                        if (formState.inputs.attachmentfile?.value.length === undefined) {
                            formData.append(key, formState.inputs[key].value);
                        } else {
                            for (
                                i = 0;
                                i < formState.inputs.attachmentfile?.value.length;
                                i++
                            ) {

                                formData.append(key, formState.inputs[key].value[i]);
                            }
                        }

                    } else {

                        if (key === "jdoc") {

                            var jdocname = formState.inputs[key].value;
                            if (jdocname.split(" ").length > 1) {
                                formData.append(key, formState.inputs[key].value);

                            } else {
                                const result = jdocs.find(({ name }) => name.includes(formState.inputs[key].value));

                                formData.append(key, result.name);

                            }

                        } else {
                            formData.append(key, formState.inputs[key].value);
                        }



                    }
                }
                formData.append("itemnr", loadedItem.itemnr);
                formData.append("itemnameold", loadedItem.itemname);
                formData.append("path", loadedItem.path);
                /*   if (loadedItem.attachmentpath !== undefined && loadedItem.attachmentpath !== "") {
                       formData.append("attachmentpath", loadedItem.attachmentpath);
                   }*/
                await sendRequest(
                    `${process.env.REACT_APP_API_URL}/equipmentlibrary/${projectname}/${tableUrl}/data/${itemId}/multipleupload`,
                    "PATCH",
                    formData,
                    {
                        Authorization: "Bearer " + auth.token,
                    }
                );
                setIsChecked([]);
                setIsCheckedFile([]);

                setSelectedID();
                //check list false yapmak icin

                const arr = Array(checkedlist.length).fill(false);
                console.log("AAARRRRRRR", arr);
                setCheckedList(arr);

                //check list false yapmak icin
                setRefreshData(!refreshData);
                setIsLoading(false);
                closeEditDrawerHandler();
            } catch (err) {
                console.log("err", err);
            }


            if (book.length !== 0) {
                for (let i = 0; i < book.length; i++) {
                    book[i] = book[i].replaceAll("/", "-");
                    const fetchItem = async () => {
                        try {
                            const response = await sendRequest(
                                `${process.env.REACT_APP_API_URL}/equipmentlibrary/${projectname}/${tableUrl}/data/${itemId}/editfiledelete/${book[i]}`,

                                "DELETE",
                                null,

                                {
                                    Authorization: "Bearer " + auth.token,
                                }
                            );
                        } catch (err) {
                            console.log(err);
                        }
                    };
                    fetchItem();
                }
            }
        } else {
            console.log("🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃 EEEEEEEELLLLLLLLLLLLLLLLLLSSSSSSSSSSSSSEEEEEEEEEEEEEEE");
            try {


                let abc = Object.values(formState)
                    .reduce(
                        (concatedArr, item) => concatedArr.concat(Object.entries(item)),
                        []
                    )

                    .reduce((result, [category, values]) => {
                        console.log("değerler", category);
                        if (
                            typeof values.value === "object" ||
                            category === "avatarname"
                        ) {
                            console.log("123");
                            result[category] = result[category] || "";

                            result[category] = values.value;
                        } else {
                            result[category] = result[category] || "";

                            result[category] = result[category].concat(values.value);
                        }

                        console.log("category", result[category]);
                        console.log("authtoken", result);
                        return result;
                    }, {});
                abc["itemnr"] = loadedItem.itemnr;
                abc["itemnameold"] = loadedItem.itemname;
                abc["path"] = loadedItem.path;

                console.log("🩸 🩸 🩸 🩸 🩸 🩸 🩸 🩸 🩸 🩸 🩸 🩸 ", abc);
                await sendRequest(
                    `${process.env.REACT_APP_API_URL}/equipmentlibrary/${projectname}/${tableUrl}/data/${itemId}/justedit`,
                    "PATCH",
                    JSON.stringify(
                        abc
                    ),
                    {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + auth.token,
                    }
                );

                setIsChecked([]);
                setIsCheckedFile([]);

                setSelectedID();
                //check list false yapmak icin

                const arr = Array(checkedlist.length).fill(false);
                console.log("AAARRRRRRR", arr);
                setCheckedList(arr);
                //check list false yapmak icin
                setRefreshData(!refreshData);
                setIsLoading(false);
                closeEditDrawerHandler();
            } catch (err) {
                console.log("err", err);
            }

            if (book.length !== 0) {
                for (let i = 0; i < book.length; i++) {
                    const fetchItem = async () => {
                        try {
                            book[i] = book[i].replaceAll("/", "-");

                            await sendRequest(
                                `${process.env.REACT_APP_API_URL}/equipmentlibrary/${projectname}/${tableUrl}/data/${itemId}/editfiledelete/${book[i]}`,

                                "DELETE",
                                null,

                                {
                                    Authorization: "Bearer " + auth.token,
                                }
                            );
                        } catch (err) {
                            console.log(err);
                        }
                    };
                    fetchItem();
                }
            }
        }

        setIsLoading(false);
        // refreshPage();
    };

    let k = 0; // dropdown index



    console.log("LOADED ITEM", loadedItem);

    console.log("FORMSTATE INPUTS", formState.inputs);
    return (
        <React.Fragment>
            <ErrorModal error={error} onClear={clearError} />
            {!isLoading && loadedItem && (
                <>
                    <div>{formState.inputs.jdoc?.value.split(" ").length > 1 ? formState.inputs.jdoc?.value.split(" ")[0] + formState.inputs.division?.value + loadedItem["itemnr"]?.substring(5, 9) : formState.inputs.jdoc?.value + formState.inputs.division?.value + loadedItem["itemnr"]?.substring(5, 9)}</div>

                    <form className="place-form" onSubmit={placeUpdateSubmitHandler}>
                        {isLoading && <LoadingSpinner asOverlay />}
                        {columns.map((item, i) => {
                            return item.ctypeedit ? (
                                item.ctypeselection ? (
                                    <>
                                        <CustomSelect
                                            id={item.value}
                                            onChange={inputHandler}
                                            data={dropdownData[k]}
                                            name={item.value}
                                            value={loadedItem[item.value]}
                                            label={item.cname}
                                        ></CustomSelect>
                                        <script>{(k = k + 1)}</script>
                                    </>
                                ) : item.ctypeinput ? (
                                    <Input
                                        id={item.value}
                                        element="input"
                                        type="text"
                                        label={item.cname}
                                        validators={[VALIDATOR_REQUIRE()]}
                                        errorText="Please enter a valid title."
                                        onInput={inputHandler}
                                        initialValue={loadedItem[item.value]}
                                        initialValid={true}
                                    />
                                ) : item.ctypeattachmentname ? (
                                    <AttachmentUpload
                                        id="attachmentfile"
                                        onInput={inputHandler}
                                        errorText="Please provide an attachment."
                                        initialValue={attachmentInitialValue}
                                        setRemovedFileName={setRemovedFileName}
                                        initialValid={true}
                                    />
                                ) : item.isstatus ? (
                                    <> <label>{item.cname + "    "}</label>

                                        <label class={stylesEditForm.switch}>
                                            <input
                                                onChange={() =>
                                                    inputHandler(item.value, !formState.inputs[item.value].value, true)
                                                }
                                                id={item.value}
                                                type="checkbox"
                                                defaultChecked={loadedItem[item.value]}

                                            />
                                            <span class={stylesEditForm.slider}></span>

                                        </label></>
                                ) : null
                            ) : null;
                        })}
                        <Button size="3" type="submit" disabled={!formState.isValid}>
                            Update ITEM
                        </Button>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                    </form></>
            )}
        </React.Fragment>
    );
};

export default EditForm;
