import { useState, useCallback, useEffect } from "react";

let logoutTimer;

export const useAuth = () => {
  const [token, setToken] = useState(false);
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [userId, setUserId] = useState(false);
  const [userName, setUserName] = useState(false);
  const [userType, setUserType] = useState(false);
  const [employeeDepartment, setEmployeeDepartment] = useState(false);
  const [employeeAssignment, setEmployeeAssignment] = useState(false);
  const [employeeProduct, setEmployeeProduct] = useState(false);

  const [employeeEnabledList, setEmployeeEnabledList] = useState(false);

  const [employeeName, setEmployeeName] = useState(false);

  const [avatarLink, setAvatarLink] = useState(false);

  const login = useCallback(
    (
      uid,
      token,
      username,
      usertype,
      department,
      assignment,
      product,
      enabledlist,
      name,
      avatar,
      expirationDate
    ) => {
      setToken(token);
      setUserId(uid);
      setUserName(username);
      setUserType(usertype);
      setEmployeeDepartment(department);
      setEmployeeAssignment(assignment);
      setEmployeeProduct(product);
      setEmployeeEnabledList(enabledlist);
      setEmployeeName(name);
      setAvatarLink(avatar);

      const tokenExpirationDate =
        expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60);
      setTokenExpirationDate(tokenExpirationDate);
      localStorage.setItem(
        "userData",
        JSON.stringify({
          userId: uid,
          token: token,
          username: username,
          usertype: usertype,
          department: department,
          assignment: assignment,
          product: product,
          enabledlist: enabledlist,
          name: name,
          avatar: avatar,
          expiration: tokenExpirationDate.toISOString(),
        })
      );
    },
    []
  );
  console.log("username", userName);
  console.log("assignment", employeeAssignment);
  console.log("department", employeeDepartment);

  console.log("product", employeeProduct);

  const logout = useCallback(() => {
    setToken(null);
    setTokenExpirationDate(null);
    setUserId(null);
    setUserName(null);
    setUserType(null);
    setEmployeeDepartment(null);
    setEmployeeAssignment(null);
    setEmployeeProduct(null);
    setEmployeeEnabledList(null);
    setAvatarLink(null);
    localStorage.removeItem("userData");
  }, []);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));

    if (
      storedData &&
      storedData.token &&
      storedData.username &&
      storedData.usertype &&
      storedData.department &&
      storedData.assignment &&
      storedData.product &&
      storedData.enabledlist &&
      storedData.name &&
      storedData.avatar &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(
        storedData.userId,
        storedData.token,
        storedData.username,
        storedData.usertype,
        storedData.department,
        storedData.assignment,
        storedData.product,
        storedData.enabledlist,
        storedData.name,
        storedData.avatar,
        new Date(storedData.expiration)
      );
    }
    console.log("storeddata", storedData);
  }, [login]);

  return {
    token,
    login,
    logout,
    userId,
    userName,
    userType,
    employeeDepartment,
    employeeAssignment,
    employeeProduct,
    employeeEnabledList,
    employeeName,
    avatarLink,
  };
};
