import React, { useEffect, useState, useContext } from "react";

import Button from "../../shared/components/FormElements/Button";
import MultiSelect from "../../shared/components/FormElements/MultiSelect/MultiSelect";
import AttachmentUpload from "../../shared/components/FormElements/FileUpload/AttachmentUpload";

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { useForm } from "../../shared/hooks/form-hook";
import { AuthContext } from "../../shared/context/auth-context";
//import "./NewForm.css";

const TableCreateRqcForm = ({
  selectedID,
  tableUrl,
  isChecked,
  isCheckedOrderType,
  isCheckedWoNumber,
  closeCreateRqcModalHandler,
  setRefreshData,
  refreshData,
  setIsChecked,
  setIsCheckedOrderType,
  setIsCheckedWoNumber,
}) => {
  const auth = useContext(AuthContext);
  const [columns, setColumns] = useState([]);
  const [columnData, setColumnData] = useState({});
  const [formState, inputHandler, setFormData] = useForm(columnData, false);
  const [multiSelect, setMultiSelect] = useState([]);

  const { sendRequest, clearError } = useHttpClient();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const itemId = `${selectedID}`;
  //const tableUrl = "budgetapprovallist";

  console.log("itemID", itemId);

  console.log("🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸", isChecked);

  console.log("🩸🩸🩸🩸🩸🩸🩸", formState.inputs.attachmentfile?.value?.length);

  console.log("🩸", isCheckedOrderType);
  console.log("🩸🩸", isCheckedWoNumber);

  const [recentRqc, setRecentRqc] = useState([]);

  let indexMultiSelect = [];
  useEffect(() => {
    const sendRequest = async () => {
      setIsLoading(true);
      // COLUMN DATA START
      try {
        const responseColumns = await fetch(
          `${process.env.REACT_APP_API_URL}/api2/budgetapprovallist/column`,
          {
            method: "GET",
          }
        );

        const responseColumnsData = await responseColumns.json();

        if (!responseColumns.ok) {
          throw new Error(responseColumnsData.message);
        }
        setColumns(responseColumnsData);

        const ctypeCreateRqc = responseColumnsData
          .filter(({ ctypecreaterqc }) => ctypecreaterqc === true)
          .map((el) => el);

        const uniqueNames =
          ctypeCreateRqc !== false
            ? Array.from(new Set(ctypeCreateRqc?.map((q) => q)))
            : [];

        const arr = JSON.parse(JSON.stringify(uniqueNames));

        let receivedValues = {};
        let sendValues = {};
        let categorymapp = Object.values(arr)
          .reduce(
            (concatedArr, item) => concatedArr.concat(Object.entries(item)),
            []
          )
          .filter((pilot) => pilot[0] === "value")
          .reduce((result, [category, values]) => {
            result["intvalue"] = result["intvalue"] || [];

            result["intvalue"] = result["intvalue"].concat({
              [values]: {
                value: "",
                isValid: false,
              },
            });

            receivedValues = result;

            return result;
          }, {});

        for (let i = 0; i < categorymapp["intvalue"].length; i++) {
          Object.assign(sendValues, categorymapp["intvalue"][i]);
        }

        setColumnData(sendValues);
        console.log("🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶", sendValues);

        setFormData(sendValues, true);
        // COLUMN DATA END

        // MULTISELECT DATA START

        let collectMultiSelect = [];
        for (let i = 0; i < responseColumnsData.length; i++) {
          if (responseColumnsData[i].ctypemultiselect === true) {
            collectMultiSelect = [
              ...collectMultiSelect,
              responseColumnsData[i].value,
            ];
          }
        }

        const numrows2 = collectMultiSelect.length;

        const blah2 = Array.from(Array(numrows2).keys());

        if (collectMultiSelect.length !== 0) {
          for (let i = 0; i < blah2.length; i++) {
            const sendRequest = async () => {
              setIsLoading(true);
              console.log("collectMultiSelect[i]", collectMultiSelect[i]);
              try {
                const responseColumns4 = await fetch(
                  `${process.env.REACT_APP_API_URL}/api2/multiselect/${collectMultiSelect[i]}`,

                  {
                    method: "GET",
                  }
                );

                const responseColumnsData4 = await responseColumns4.json();

                if (!responseColumns4.ok) {
                  throw new Error(responseColumnsData4.message);
                }

                let changeMultiSelectName = [];
                let categorymapp = Object.values(responseColumnsData4)
                  .reduce(
                    (concatedArr, item) =>
                      concatedArr.concat(Object.entries(item)),
                    []
                  )
                  .reduce((result, [category, values]) => {
                    result["intvalue"] = result["intvalue"] || [];
                    result["intvalue"] = result["intvalue"].concat({
                      name: values,
                    });

                    changeMultiSelectName = [
                      ...changeMultiSelectName,
                      {
                        name: values,
                      },
                    ];

                    return result;
                  }, {});

                indexMultiSelect[i] = changeMultiSelectName;

                const indexMultiSelectToJSON = JSON.parse(
                  JSON.stringify(indexMultiSelect)
                );

                // setDropDownData(indexDropDownToJSON);

                setMultiSelect(indexMultiSelectToJSON);
              } catch (err) {
                setError(err.message);
              }
              setIsLoading(false);
            };
            sendRequest();
          }
        }

        // MULTISELECT DATA END
      } catch (err) {
        setError(err.message);
      }
      setIsLoading(false);
    };
    sendRequest();
  }, [setFormData]);

  useEffect(() => {
    if (tableUrl === "purchaseorderlist") {
      const sendRequest = async () => {
        setIsLoading(true);
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api2/budgetapprovallist/data`,
            {
              method: "GET",
            }
          );

          const responseData = await response.json();

          if (!response.ok) {
            throw new Error(responseData.message);
          }

          const uniqueNames =
            responseData !== false
              ? Array.from(new Set(responseData?.map((q) => q.rqcnumber)))
              : [];

          const rqcArr = JSON.parse(JSON.stringify(uniqueNames));
          setRecentRqc(rqcArr);
        } catch (err) {
          setError(err.message);
        }
        setIsLoading(false);
      };
      sendRequest();
    }
  }, [tableUrl]);

  let rqcNum;
  let producedRqc;
  let recentOrdertype;

  if (tableUrl === "purchaseorderlist") {
    console.log(
      "🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️",
      formState.inputs.supplieroptions
    );
    let initials2 = auth.employeeName;

    if (formState.inputs.supplieroptions?.value !== undefined) {
      recentOrdertype = formState.inputs.supplieroptions?.value[0]?.replaceAll(
        " ",
        ""
      );
    }

    console.log("🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️", recentOrdertype);

    var getInitials2 = function (string) {
      var names = string?.split(" "),
        initials = names[0]?.substring(0, 1).toUpperCase();

      if (names.length > 1) {
        for (let i = 1; i < names.length; i++)
          initials += names[i]?.substring(0, 1).toUpperCase();
      }
      return initials;
    };

    const formatDate2 = () => {
      var d = new Date(),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [day, month, year].join("");
    };

    producedRqc = "RQC-" + getInitials2(initials2) + "-" + formatDate2() + "-";

    console.log("💪💪", producedRqc);
    console.log("💪💪💪", recentRqc);

    let rqcArr2 = [];

    for (let i = 0; i < recentRqc.length; i++) {
      if (recentRqc[i].includes(producedRqc)) {
        rqcArr2 = [...rqcArr2, recentRqc[i]];
      }
    }
    console.log("💪💪💪💪💪💪💪💪💪", rqcArr2);
    var numberArr = [];

    for (let i = 0; i < rqcArr2.length; i++) {
      numberArr = [
        ...numberArr,
        parseInt(rqcArr2[i].substring(rqcArr2[i].length - 3), 10),
      ];

      console.log("💪", rqcArr2[i]);
    }

    const indexRqc = Math.max(...numberArr.map((o) => o)) + 1;

    console.log("💪💪💪💪", indexRqc);

    const indexRqcString = indexRqc.toString();

    if (numberArr.length === 0) {
      rqcNum = "001";
    } else if (indexRqcString.length === 1) {
      rqcNum = "00" + indexRqcString;
    } else {
      rqcNum = "0" + indexRqcString;
    }
  }

  const producedRqcwithIndex = producedRqc + rqcNum;

  useEffect(() => {
    inputHandler("rqcnumber", producedRqcwithIndex, true);
  }, [recentOrdertype]);

  const orderTypes = [...new Set(isCheckedOrderType?.map((q) => q))];
  const woNumbers = [...new Set(isCheckedWoNumber?.map((q) => q))];

  console.log("🩸🩸🩸", orderTypes);
  console.log("🩸🩸🩸🩸", woNumbers);

  useEffect(() => {
    inputHandler("ordertype", orderTypes, true);
  }, [recentOrdertype]);

  useEffect(() => {
    inputHandler("wonumber", woNumbers, true);
  }, [recentOrdertype]);

  console.log("formState.inputs", formState.inputs);
  const itemCreateRqcSubmitHandler = async (event) => {
    event.preventDefault();
    console.log("🩸🩸🩸🩸🩸🩸🩸", isChecked);
    try {
      await sendRequest(
        `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/createrqc/${isChecked}`,
        "PATCH",

        JSON.stringify({
          rqcnumber: producedRqcwithIndex,
          orderstatus: "Waiting for Payment",
        }),

        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
      // setRefreshData(!refreshData);
      // closeCreateRqcModalHandler();
    } catch (err) { }
    if (
      formState.inputs.attachmentfile?.value !== undefined &&
      formState.inputs.attachmentfile?.value.length > 1
    ) {
      try {
        var formData = new FormData();

        for (var key in formState.inputs) {
          if (key === "attachmentfile") {
            for (
              let i = 0;
              i < formState.inputs.attachmentfile?.value.length;
              i++
            ) {
              formData.append(key, formState.inputs[key].value[i]);
            }
          } else {
            formData.append(key, formState.inputs[key].value);
          }
        }

        await sendRequest(
          `${process.env.REACT_APP_API_URL}/api2/budgetapprovallist/data/multipleupload`,
          "POST",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setRefreshData(!refreshData);
        setIsLoading(false);
        closeCreateRqcModalHandler();
        setIsChecked([]);
        setIsCheckedOrderType([]);
        setIsCheckedWoNumber([]);
      } catch (err) { }
    } else if (formState.inputs.attachmentfile?.value !== undefined) {
      try {
        var formData = new FormData();
        for (var key in formState.inputs) {
          formData.append(key, formState.inputs[key].value);
        }

        await sendRequest(
          `${process.env.REACT_APP_API_URL}/api2/budgetapprovallist/data/singleupload`,
          "POST",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setRefreshData(!refreshData);
        setIsLoading(false);
        closeCreateRqcModalHandler();
        setIsChecked([]);
        setIsCheckedOrderType([]);
        setIsCheckedWoNumber([]);
      } catch (err) {
        console.log(err);
      }
    }
  };

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }
  let m = 0;

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isChecked.length !== 0 ? (
        <>
          <form onSubmit={itemCreateRqcSubmitHandler}>
            {isLoading && <LoadingSpinner asOverlay />}

            {columns.map((item, i) => {
              return item.ctypecreaterqc ? (
                item.ctypeinput && item.value === "rqcnumber" ? (
                  <p> {producedRqcwithIndex}</p>
                ) : item.ctypemultiselect && item.value !== "ordertype" ? (
                  <>
                    <MultiSelect
                      id={item.value}
                      onChange={inputHandler}
                      data={multiSelect[m]}
                      name={item.value}
                      label={item.cname}
                    />
                    <script>{(m = m + 1)}</script>
                  </>
                ) : item.ctypeattachmentfile ? (
                  <AttachmentUpload
                    id="attachmentfile"
                    onInput={inputHandler}
                    initialValue=""
                  />
                ) : null
              ) : null;
            })}
            <br></br>
            <Button size="3" type="submit" disabled={!formState.isValid}>
              Add New
            </Button>
            <br></br>
          </form>
        </>
      ) : (
        <>
          {" "}
          <p>Please select an item </p>
          <Button type="button" onClick={closeCreateRqcModalHandler}>
            OK
          </Button>
        </>
      )}
    </React.Fragment>
  );
};

export default TableCreateRqcForm;
