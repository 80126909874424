import React, { useContext } from "react";

import { useParams, NavLink, Outlet } from "react-router-dom";
import { AuthContext } from "../../shared/context/auth-context";
import stylessProductPage from "./ProductPage.module.css";
import ProfilSide from "../../shared/components/Navigation/ProfileSide";

const ProductPage = () => {
  const auth = useContext(AuthContext);

  let product = useParams().product;
  // department = department.replace(/-/g, " ");

  let productRouteName = auth.employeeProduct;

  let productRouteNameArr = [];

  productRouteNameArr = productRouteName.split(",");

  console.log("product PRODUCTPAGE", product);

  console.log("app - product PRODUCTPAGE", auth.employeeProduct);

  const hashRemoved = product.replaceAll("-", "");
  console.log("hashRemoved", hashRemoved);
  const tableUrlproduct = "Ml" + hashRemoved;
  console.log("tableUrlproduct", tableUrlproduct);

  if (productRouteNameArr.includes(product)) {
    return (
      <React.Fragment>
      
         <div className ={stylessProductPage.body}>
         <div className ={stylessProductPage.body_row}>
         <div className = {stylessProductPage.profil}> <ProfilSide/></div>
         <div className = {stylessProductPage.body_productarea}><div className ={stylessProductPage.project_area}>
          
        <li>
          <NavLink to={`masterlist/${tableUrlproduct}`}>
            {" "}
            <div className={stylessProductPage .project_card} >
              Go to Master List Page
              <div></div>
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink to={`spaces`}>
            {" "}
            <div className={stylessProductPage .project_card}>
              Go to Spaces Page
              <div></div>
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink to={`divisions`}>
            {" "}
            <div className={stylessProductPage .project_card}>
              Go to Divisions Page
              <div></div>
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink to={`equipment-library`}>
            {" "}
            <div className={stylessProductPage .project_card}>
              Go to Equipment Library Page
              <div></div>
            </div>
          </NavLink>
        </li>
         </div>
         
         <nav className={stylessProductPage.card}>ProductPage</nav>
         
         <div>
      
         <div></div>
    
        </div>
        </div>
        </div>
        </div>
      </React.Fragment>
    );
  } else {
    return <div>Not Authorized!!! </div>;
  }
};

export default ProductPage;