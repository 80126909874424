import React, { useContext } from "react";

import { useParams, NavLink } from "react-router-dom";
import { AuthContext } from "../../shared/context/auth-context";
import stylessEmployePage from "./EmployePage.module.css";
import ProfilSide from "../../shared/components/Navigation/ProfileSide";

import SideBarMain from "../../shared/components/Navigation/SideBarNavigation/SideBarMain";

const EmployeePage = () => {
  const auth = useContext(AuthContext);

  const username = useParams().employee;
  console.log("username EMPLOYEEPAGE", username);

  console.log("app - department EMPLOYEEPAGE", auth.employeeDepartment);
  console.log("app - department EMPLOYEEPAGE", auth.userName);
  console.log("app - department EMPLOYEEPAGE", auth.employeeAssignment);
  console.log("app - department EMPLOYEEPAGE", auth.employeeProduct);

  let departmentRouteName = auth.employeeDepartment;

  let productRouteName = auth.employeeProduct;

  departmentRouteName = departmentRouteName.replace(/ /g, "-").toLowerCase();

  let productRouteNameArr = [];

  productRouteNameArr = productRouteName.split(",");

  if (username === auth.userName) {
    return (
      <React.Fragment>
        <div className={stylessEmployePage.body}>
          <div className={stylessEmployePage.body_row}>
            <div>
              {" "}
              <SideBarMain
                productRouteNameArr={productRouteNameArr}
                departmentRouteName={departmentRouteName}
              />
            </div>
            <div>
              <nav className={stylessEmployePage.card}>EmployeePage</nav>
              <div className={stylessEmployePage.project_area}>
                <NavLink to={"/department/" + departmentRouteName} exact>
                  <div> My Department</div>
                </NavLink>
                <div className={stylessEmployePage.header_text}> Projects</div>
                {productRouteNameArr.map((item) => (
                  <NavLink to={"/product/" + item} exact>
                    <div className={stylessEmployePage.project_card}>
                      {" "}
                      <div className={stylessEmployePage.project_header}>
                        {item}
                      </div>
                    </div>
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  } else {
    return <div>Not Authorized!!! </div>;
  }
};

export default EmployeePage;
