import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import stylessTableReadOnlyRow from "./TableReadOnlyRow.module.css";

const TableReadOnlyRow = ({
  columns,
  handleCheck,
  checked,
  rows,
  openDisplayDrawerHandler,
  setSelectedID,
  tableUrl,
}) => {
  const [checkBoxColor, setCheckBoxColor] = useState([]);

  useEffect(() => {
    setCheckBoxColor(Array(checked.length).fill(false));

  }, [checked]);


  return rows.map((row, index) => (
    <tr className={checked[index] ? stylessTableReadOnlyRow.trTrue : stylessTableReadOnlyRow.trFalse} >
      <td className={stylessTableReadOnlyRow.checkboxcell}>
        <input
          type="checkbox"
          value={row._id}
          checked={checked[index]}
          onChange={(event) => handleCheck(event, row)}
        ></input>
      </td>

      {tableUrl === "employee" ? (
        row.employeestatus === false ? (
          <td>
            {" "}
            <img src={require("../../assest/layoff.png")} />
          </td>
        ) : (
          <td>
            {" "}
            <img src={require("../../assest/active-user.png")} />
          </td>
        )
      ) : null}

      {columns.map((column, index) => {
        if (column.ctypeshowpublic === true) {
          if (index === 0) {
            return (
              <td className={stylessTableReadOnlyRow.cell}>
                <Link
                  onClick={() => {
                    setSelectedID(row._id);
                    openDisplayDrawerHandler();
                  }}
                >
                  {row[`${column.value}`]}
                </Link>
              </td>
            );
          } else if (column.ctypeimage === true) {
            return (
              <td className={stylessTableReadOnlyRow.cell}>
                <img src={row[`${column.value}`]} width="200" height="60" />
              </td>
            );
          } else {
            return (
              <td className={stylessTableReadOnlyRow.cell}>
                {row[`${column.value}`]}
              </td>
            );
          }
        }
      })}
    </tr>
  ));
};

export default TableReadOnlyRow;
