import React, { useState, useCallback } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import HomePage from "./pages/homepage/HomePage";
import EmployeePage from "./pages/employeepages/EmployeePage";
import DepartmentPage from "./pages/departmentpages/DepartmentPage";
import ProductPage from "./pages/productpages/ProductPage";
import ProductMasterListPage from "./pages/productpages/ProductMasterListPage";
import PurchaseOrderListPage from "./pages/departmentpages/PurchaseOrderListPage";

import LoginPage from "./pages/loginpage/LoginPage";
import NotAuthorizedPage from "./shared/components/Navigation/NotAuthorizedPage";

import { AuthContext } from "./shared/context/auth-context";
import { useAuth } from "./shared/hooks/auth-hook";

import AdminPage from "./adminpages/AdminPage";

import DetailsPage from "./adminpages/DetailsPage";
import DocumentLibrary from "./documentlibrary/documentlibrary";
import EquipmentLibrary from "./equipmentLibrary/equipmentLibrary";
import ProductSpaces from "./pages/productpages/ProductSpaces";
import ProductDivisions from "./pages/productpages/ProductDivisons";
import Barcode from "./barcode";
import NewEmployeePage from "./pages/employeepages/NewEmployeePage";
import NewDepartmentPage from "./pages/departmentpages/NewDepartmentPage";

function App() {
  const {
    token,
    login,
    logout,
    userId,
    userName,
    userType,
    employeeDepartment,
    employeeAssignment,
    employeeProduct,
    employeeEnabledList,
    employeeName,
    avatarLink,
  } = useAuth();
  //const recentUser = `${userName}`;
  /*
  let routes;
  if (token && userName === "Admin") {
    routes = (
      <Routes>
        <Route
          path="/admin"
          element={token && userName === "Admin" ? <AdminPage /> : <HomePage />}
        />
      </Routes>
    );
  } else {
    routes = (
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
      </Routes>
    );
  }*/

  console.log("app - token", token);
  console.log("app - department", employeeDepartment);
  console.log("app - assignment", employeeAssignment);
  console.log("app - userName", userName);
  console.log("app - employeeName", employeeName);
  console.log("app - product", employeeProduct);
  console.log("app - avatarLink", avatarLink);
  console.log("employeeEnabledLists", employeeEnabledList);

  // let employeeEnabledListsArr = [];

  // employeeEnabledListsArr = employeeEnabledLists.split(",");
  /*
  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        userName: userName,
        userType: userType,
        employeeDepartment: employeeDepartment,
        employeeAssignment: employeeAssignment,
        employeeProduct: employeeProduct,
        employeeEnabledList: employeeEnabledList,
        employeeName: employeeName,
        avatarLink: avatarLink,
        login: login,
        logout: logout,
      }}
    >
      <BrowserRouter>
        <main>
          <Routes>
            <Route
              path="/admin"
              element={
                token && userType === "Admin" ? <AdminPage /> : <HomePage />
              }
            />
            <Route
              path="/admin/details/:detail"
              element={
                token && userType === "Admin" ? <DetailsPage /> : <HomePage />
              }
            />
            <Route
              path="/employee/:employee"
              element={
                token && userType !== "Admin" ? <EmployeePage /> : <HomePage />
              }
            />

            <Route
              path="/department/:department"
              element={
                token && userType !== "Admin" ? (
                  <DepartmentPage />
                ) : (
                  <HomePage />
                )
              }
            />

            <Route
              path="/department/purchase-department/:purchaselist"
              element={token ? <PurchaseOrderListPage /> : <HomePage />}
            />
            <Route path="/product/:product">
              <Route
                index
                element={
                  token && userType !== "Admin" ? <ProductPage /> : <HomePage />
                }
              />
              <Route
                path="masterlist/:tableUrl"
                element={token ? <ProductMasterListPage /> : <HomePage />}
              />
              <Route
                path="spaces"
                element={token ? <ProductSpaces /> : <HomePage />}
              />
              <Route
                path="divisions"
                element={token ? <ProductDivisions /> : <HomePage />}
              />
              <Route
                path="equipment-library"
                element={token ? <EquipmentLibrary /> : <HomePage />}
              />
            </Route>
            <Route path="/" element={<HomePage />} />
            <Route
              path="/login"
              element={!token ? <LoginPage /> : <HomePage />}
            />
            <Route path="/PM" element={<DocumentLibrary />} />
            <Route path="/EL" element={<EquipmentLibrary />} />
            <Route path="/barcode" element={<Barcode />} />
          </Routes>
        </main>
      </BrowserRouter>
    </AuthContext.Provider>
  );
  */

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        userName: userName,
        userType: userType,
        employeeDepartment: employeeDepartment,
        employeeAssignment: employeeAssignment,
        employeeProduct: employeeProduct,
        employeeEnabledList: employeeEnabledList,
        employeeName: employeeName,
        avatarLink: avatarLink,
        login: login,
        logout: logout,
      }}
    >
      <BrowserRouter>
        <main>
          <Routes>
            <Route
              path="/admin"
              element={
                token && userType === "Admin" ? (
                  <AdminPage />
                ) : (
                  <NotAuthorizedPage />
                )
              }
            />
            <Route
              path="/admin/details/:detail"
              element={
                token && userType === "Admin" ? (
                  <DetailsPage />
                ) : (
                  <NotAuthorizedPage />
                )
              }
            />
            <Route
              path="/employee/:employee"
              element={token ? <EmployeePage /> : <NotAuthorizedPage />}
            />

            <Route
              path="/department/:department"
              element={token ? <DepartmentPage /> : <NotAuthorizedPage />}
            />

            <Route
              path="/department/purchase-department/:purchaselist"
              element={
                token ? <PurchaseOrderListPage /> : <NotAuthorizedPage />
              }
            />
            <Route path="/product/:product">
              <Route
                index
                element={token ? <ProductPage /> : <NotAuthorizedPage />}
              />
              <Route
                path="masterlist/:tableUrl"
                element={
                  token ? <ProductMasterListPage /> : <NotAuthorizedPage />
                }
              />
              <Route
                path="spaces"
                element={token ? <ProductSpaces /> : <NotAuthorizedPage />}
              />
              <Route
                path="divisions"
                element={token ? <ProductDivisions /> : <NotAuthorizedPage />}
              />
              <Route
                path="equipment-library"
                element={token ? <EquipmentLibrary /> : <NotAuthorizedPage />}
              />
            </Route>
            <Route
              path="/login"
              element={
                !token ? (
                  <LoginPage />
                ) : (
                  <Navigate to={`/employee/${userName}`} />
                )
              }
            />
            <Route path="/" element={<Navigate to="/login" />} />

            <Route path="/PM" element={<DocumentLibrary />} />
            <Route path="/EL" element={<EquipmentLibrary />} />
            <Route path="/barcode" element={<Barcode />} />
          </Routes>
        </main>
      </BrowserRouter>
    </AuthContext.Provider>
  );
}

export default App;
