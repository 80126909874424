import React, { useEffect, useState, useContext } from "react";

import Button from "../../shared/components/FormElements/Button";

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
//import "./NewForm.css";

const LibraryRenameForm = ({ departmentname, setDepartmentname, closeRenameModalHandler, setRefreshTable, refreshTable, isCheckedFolder, isCheckedFile }) => {
    const [rename, setRename] = useState('');
    const { error, sendRequest, clearError } = useHttpClient();
    const [isLoading, setIsLoading] = useState(false);

    const renameObject = async () => {
        setIsLoading(true);
        console.log(departmentname);
        let renamethis;
        console.log(isCheckedFolder);
        console.log(isCheckedFile);
        if (isCheckedFolder.length !== 0) {
            console.log("FOLDER RENAME");
            renamethis = isCheckedFolder[0];

            try {

                await sendRequest(
                    `${process.env.REACT_APP_API_URL}/filemanager/renameFolder`,
                    "POST",
                    JSON.stringify({
                        name: rename,
                        path: renamethis
                    }),
                    {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                    }
                );
                setIsLoading(false);
                setRename('');
                setDepartmentname(departmentname);
                setRefreshTable(!refreshTable);
                closeRenameModalHandler();
            } catch (err) {
                setIsLoading(false);
                alert(err);
                console.log(err);
                closeRenameModalHandler();
            }
        }
        if (isCheckedFile.length !== 0) {
            console.log("FİLE RENAME");
            renamethis = isCheckedFile[0];

            try {

                await sendRequest(
                    `${process.env.REACT_APP_API_URL}/filemanager/rename`,
                    "POST",
                    JSON.stringify({
                        name: rename,
                        path: renamethis
                    }),
                    {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                    }
                );
                setIsLoading(false);
                setRename('');
                setDepartmentname(departmentname);
                setRefreshTable(!refreshTable);
                closeRenameModalHandler();
            } catch (err) {
                setIsLoading(false);
                alert(err);
                console.log(err);
                closeRenameModalHandler();
            }

        }




        /* fetch('http://localhost:5200/filemanager/createFolder', {
             method: 'post',
             headers: {
                 'Content-Type': 'application/json',
                 'Authorization': 'Bearer ' + localStorage.getItem('jwt')
             },
             body: JSON.stringify({
                 name: folderName,
                 path: new_folder_path
             })
         }).then(res => res.json()).then((data) => {
             console.log(data);
             const { err, folder } = data;
             if (err) {
                 setIsLoading(false);
                 alert(err);
                 console.log(err);
 
             }
             else {
                 setIsLoading(false);
                 alert("Folder created Successfully");
 
                 console.log(folder);
 
                 setFolderName('');
                 setDepartmentname(departmentname);
 
             }
         })*/
    }
    if (isLoading) {
        return (
            <div className="center">
                <LoadingSpinner />
            </div>
        );
    }

    return (
        <React.Fragment>

            {!isLoading && (
                <>   <label>Yeniden Adlandır</label>
                    <div >

                        <input type="text" value={rename} placeholder="Rename" id="rename-input"
                            onChange={(e) => setRename(e.target.value)}
                        />
                        <button onClick={renameObject}>Yeniden Adlandır</button>
                        {isLoading && <LoadingSpinner asOverlay />}
                    </div></>
            )}
        </React.Fragment>
    );
};

export default LibraryRenameForm;