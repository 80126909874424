import React, { useRef, useState, useEffect } from "react";

import Button from "../Button";
import "./ImageUpload.css";

const ImageUpload = (props) => {
  const [isValid, setIsValid] = useState(false);

  const filePickerRef = useRef();

  const [image, setImage] = useState("");
  const [imageName, setImageName] = useState("");
  const [initialImageName, SetInitialImageName] = useState("");
  const [previewUrl, setPreviewUrl] = useState();

  const [showButton, setShowButton] = useState(false);

  const initialValue = props.initialValue;

  const initialValid = props.initialValid;

  console.log("initialValue", initialValue);

  useEffect(() => {
    SetInitialImageName(initialValue);
    setIsValid(initialValid);
  }, [initialValue, initialValid]);

  useEffect(() => {
    if (imageName !== "" || initialImageName !== "") {
      setShowButton(true);
    }
  }, [imageName, initialImageName]);

  useEffect(() => {
    if (!image) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
    };
    fileReader.readAsDataURL(image);
  }, [image]);

  useEffect(() => {
    if (image === undefined) {
      props.onInput(props.id, "undefined", isValid);
    } else {
      props.onInput(props.id, image, isValid);
    }
  }, [image, isValid]);

  const pickedHandler = (event) => {
    let pickedFile;
    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      setImage(pickedFile);
      setIsValid(true);
      setImageName(event.target.files[0].name);
      // fileIsValid = true;
    } else {
      setIsValid(false);
      // fileIsValid = false;
    }
  };

  const pickImageHandler = () => {
    filePickerRef.current.click();
  };

  const removeImageHandler = (event) => {
    props.setRemovedImageName(initialImageName);
    SetInitialImageName("");
    setShowButton(false);
  };

  const removeNewImageHandler = (event) => {
    filePickerRef.current.value = "";
    setImageName("");
    setImage("");
    setIsValid(false);
    setShowButton(false);
  };

  return (
    <React.Fragment>
      <div className="form-control">
        <input
          id={props.id}
          name="imagefile"
          ref={filePickerRef}
          style={{ display: "none" }}
          type="file"
          accept=".jpg,.png,.jpeg"
          onChange={pickedHandler}
        />
        {showButton && imageName !== "" && (
          <>
            <br></br>
            <div className="image_box" >{previewUrl && <img style={{ width: 200, height:200 }} src={previewUrl} alt="Preview" />}</div>
            <div className="file_picker_out_row">
                <div className="file_picker_file_row">
            <label>{imageName}</label>
            </div>
            <div className="file_picker_remove_row">
            <button
              type="button"
              style={{ display: imageName === "" ? "none" : "block" }}
              onClick={removeNewImageHandler}
            >
              X
            </button>
            </div>
            <br></br>
            </div>
          </>
        )}
        {showButton &&
          initialImageName !== "" &&
          initialImageName?.length !== 0 && (
            <>
              <label>
                {
                  initialImageName[0].split("/")[
                    initialImageName[0].split("/").length - 1
                  ]
                }
              </label>
              <div className="file_picker_remove_row">
              <button
                type="button"
                style={{ display: initialImageName === "" ? "none" : "block" }}
                onClick={removeImageHandler}
              >
                X
              </button>
              </div>
            </>
          )}

        {!isValid && <p>{props.errorText}</p>}
      </div>
      <div>
        <Button size="3"
          type="button"
          // style={{ display: initialAvatarName === "" ? "none" : "block" }}
          onClick={pickImageHandler}
        >
          Pick Image
        </Button>
      </div>
      <br></br>
    </React.Fragment>
  );
};

export default ImageUpload;
