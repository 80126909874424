import React, { useState, useRef, useEffect, useCallback } from "react";

import Dropdown from "./DropDownMultiiSelect";

import stylessDropDownMultiselect from "./DropDownMultiSelect.module.css";

const MultiSelect = ({
  label,
  data,
  value,
  name,
  onChange,
  error,
  defaultOptionLabel,
  searchPlaceholder,
}) => {
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState();
  // value !== "" ? data?.indexOf(value) : null
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState();
  const [showDropdown, setShowDropdown] = useState(false);

  const [isValid, setIsValid] = useState(true);
  const dropdownEl = useRef();
  console.log("selectedIndex", value);
  console.log("Options", options);
  console.log("data", data);

  useEffect(() => {
    if (selectedValue === undefined && value === undefined) {
      setIsValid(false);
      console.log("AAAAAAAAAAAAAA");
    } else setIsValid(true);
  }, [selectedValue, value]);

  useEffect(() => {
    setOptions(data);
    setSelectedValue(value);
    onChange(name, value, isValid);
  }, []);

  console.log("🏃🏃🏃🏃🏃🏃🏃🏃🏃🏃🏃🏃", isValid);

  // Hide dropdown if clicked outside of dropdown
  const handleClickOutside = useCallback(
    (e) => {
      if (
        showDropdown &&
        e.target.closest(".dropdown") !== dropdownEl.current
      ) {
        setShowDropdown(false);
        setSearch("");
        setOptions(data);
      }
    },
    [showDropdown, setShowDropdown, dropdownEl, data]
  );

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);

  const changeSelectedHandler = (item, name, index, isValid) => {
    setSelectedValue(item);
    setSelectedIndex(index);

    // setShowDropdown(false);
    onChange(name, item, isValid);
    console.log("item", item);
  };

  const searchChangeHandler = (e) => {
    setSearch(e.target.value);
    const filteredOptions = data.filter((opt) => {
      return opt.name
        .toLowerCase()
        .includes(e.target.value.trim().toLowerCase());
    });
    setOptions(filteredOptions);
  };

  return (
    <div className="form-control.false">
      <br></br>
      <label className={stylessDropDownMultiselect.label}>{label}</label>
      <div className="dropdown" ref={dropdownEl}>
        <input
          type="hidden"
          name={name}
          value={value}
          onChange={(e) => onChange(value, name)}
        />
        <div
          className={stylessDropDownMultiselect.dropdown__selected}
          onClick={() => {
            setOptions(data);
            setShowDropdown(!showDropdown);
          }}
        >
          {selectedValue
            ? selectedValue
            : defaultOptionLabel
              ? defaultOptionLabel
              : "Please select one option"}
        </div>
        {showDropdown && (
          <Dropdown
            searchPlaceholder={searchPlaceholder}
            search={search}
            searchChangeHandler={searchChangeHandler}
            options={options}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            selectedIndex={selectedIndex}
            changeSelectedHandler={changeSelectedHandler}
            name={name}
            data={data}
            value={value}
            isValid={isValid}
          />
        )}
      </div>
      {error && <div className="error">{error}</div>}
    </div>
  );
};

export default MultiSelect;
