import React, { useRef, useEffect } from "react";
import stylessDropDownAssingment from "./DropDownAssignment.module.css";
const DropdownAssignment = ({
  searchPlaceholder,
  search,
  searchChangeHandler,
  options,
  selectedValue,
  changeSelectedHandler,
  name,
  selectedIndex,
  data,
  value,
}) => {
  const searchInputEl = useRef();
  const itemsEl = useRef();

  useEffect(() => {
    searchInputEl.current.focus();
    /* if (selectedValue) {
      itemsEl.current.scrollTop =
        itemsEl.current.querySelector(`.item-${selectedIndex}`).offsetTop - 42;
    }*/
    // eslint-disable-next-line
  }, []);

  console.log("data.indexOf(item.name)", selectedValue);

  console.log("options", options);
  return (
    <div className={stylessDropDownAssingment.dropdown__menu}>
      <input
        type="text"
        placeholder={searchPlaceholder ? searchPlaceholder : "Search..."}
        className={stylessDropDownAssingment.dropdown__menu_search}
        value={search}
        onChange={searchChangeHandler}
        ref={searchInputEl}
      />
        <br></br>
      <div className={stylessDropDownAssingment.dropdown__menu_items} ref={itemsEl}>
        {options?.map((item, index) => (
          <div
            className={
              selectedValue === item
                ? `dropdown__menu_item item-${data.indexOf(item)} selected`
                : `dropdown__menu_item item-${data.indexOf(item)}`
            }
            key={index}
            onClick={() => {
              changeSelectedHandler(item, name, index, true);
            }}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DropdownAssignment;
