import React, { useReducer, useEffect } from "react";

import { validate } from "../../util/validators";
import styles from "./ReadOnlyInput.module.css";

const ReadOnlyInput = ({
    id,
    type,
    label,
    value,
    name,
    errorText }) => {

    return <>
        <div className={styles.div}>
            <label className={styles.label}>{label}</label>
            <input
                className={styles.input}
                id={id}
                type={type}
                label={label}
                value={value}
                name={name}
                errorText={errorText}
                readOnly
            ></input>
        </div>
    </>
};

export default ReadOnlyInput;