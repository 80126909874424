import React, { useEffect, useState, useContext } from "react";

import Button from "../../shared/components/FormElements/Button";

import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";

import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
//import "./NewForm.css";

const TableTechApproveForm = ({
  selectedID,
  tableUrl,
  isChecked,
  closeTechApproveModalHandler,
  setRefreshData,
  refreshData,
  setIsChecked,
}) => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [techApporveNote, setTechApproveNote] = useState();

  const itemId = `${selectedID}`;

  console.log("itemID", itemId);

  console.log("🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸🩸", isChecked);

  const inputChange = (e) => {
    setTechApproveNote(e.target.value);
  };

  const itemTechApproveSubmitHandler = async (event) => {
    event.preventDefault();
    console.log("🩸🩸🩸🩸🩸🩸🩸", isChecked);
    try {
      await sendRequest(
        `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/techapprove/${isChecked}`,
        "PATCH",

        JSON.stringify({
          techapprover: auth.employeeName,
          techapprovernote: techApporveNote,
        }),

        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
      setRefreshData(!refreshData);
      closeTechApproveModalHandler();
    } catch (err) { }
    setIsChecked([]);
  };

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {!isLoading && (
        <div onSubmit={itemTechApproveSubmitHandler}>
          {isLoading && <LoadingSpinner asOverlay />}
          {isChecked.length !== 0 ? (
            <>
              {" "}
              <p>Are you sure to approve selected items? </p>
              <input
                type="text"
                id="techapprovenotes"
                onChange={(e) => inputChange(e)}
              />
              <Button type="submit" onClick={itemTechApproveSubmitHandler}>
                Tech Approve
              </Button>
            </>
          ) : (
            <>
              {" "}
              <p>Please select an item </p>
              <Button type="button" onClick={closeTechApproveModalHandler}>
                OK
              </Button>
            </>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default TableTechApproveForm;
