import React, { useContext, useState, useEffect } from "react";

import Input from "../../shared/components/FormElements/Input";
import Button from "../../shared/components/FormElements/Button";
import CustomSelect from "../../shared/components/FormElements/CustomSelect/CustomSelect";
import LocationCascading from "../../shared/components/FormElements/Location/LocationCascading";
import MaterialCascading from "../../shared/components/FormElements/Material/MaterialCascading";
import AssignmentCascading from "../../shared/components/FormElements/Assignment/AssignmentCascading";
import MultiSelect from "../../shared/components/FormElements/MultiSelect/MultiSelect";
import DateComp from "../../shared/components/FormElements/Date";
// import PoNumber from "../../shared/components/FormElements/PoNumber";
import AttachmentUpload from "../../shared/components/FormElements/FileUpload/AttachmentUpload";
import AvatarUpload from "../../shared/components/FormElements/FileUpload/AvatarUpload";
import ImageUpload from "../../shared/components/FormElements/FileUpload/ImageUpload";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";

import {
  VALIDATOR_REQUIRE,
  VALIDATOR_MINLENGTH,
  VALIDATOR_EMAIL,
  VALIDATOR_NONE,
} from "../../shared/util/validators";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import stylessNewForm from "./NewForm.module.css";

const NewForm = ({
  tableUrl,
  setRefreshData,
  refreshData,
  closeNewDrawerHandler,
}) => {
  const auth = useContext(AuthContext);
  const [columns, setColumns] = useState([]);
  const [columnData, setColumnData] = useState({});

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  const { sendRequest, clearError } = useHttpClient();
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [formState, inputHandler, setFormData] = useForm(columnData, false);
  const [dropdownData, setDropDownData] = useState([]);
  const [multiSelect, setMultiSelect] = useState([]);

  //GET DATA STARTS

  const [recentPos, setRecentPos] = useState([]);
  const [recentRqc, setRecentRqc] = useState([]);

  useEffect(() => {
    if (tableUrl === "purchaseorderlist") {
      const sendRequest = async () => {
        setIsLoading(true);
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data`,
            {
              method: "GET",
            }
          );

          const responseData = await response.json();

          if (!response.ok) {
            throw new Error(responseData.message);
          }

          const uniqueNames =
            responseData !== false
              ? Array.from(new Set(responseData?.map((q) => q.po)))
              : [];

          const poArr = JSON.parse(JSON.stringify(uniqueNames));
          setRecentPos(poArr);
        } catch (err) {
          setError(err.message);
        }
        setIsLoading(false);
      };
      sendRequest();
    }
  }, [tableUrl]);

  let poNum;
  let producedPO;

  if (tableUrl === "purchaseorderlist") {
    let initials2 = auth.employeeName;

    let recentOrdertype = formState.inputs.ordertype?.value?.replaceAll(
      " ",
      ""
    );

    var getInitials2 = function (string) {
      var names = string?.split(" "),
        initials = names[0]?.substring(0, 1).toUpperCase();

      if (names.length > 1) {
        for (let i = 1; i < names.length; i++)
          initials += names[i]?.substring(0, 1).toUpperCase();
      }
      return initials;
    };

    const formatDate2 = () => {
      var d = new Date(),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [day, month, year].join("");
    };

    producedPO =
      "PO-" +
      getInitials2(initials2) +
      "-" +
      recentOrdertype +
      "-" +
      formatDate2() +
      "-";

    let poArr2 = [];

    for (let i = 0; i < recentPos.length; i++) {
      if (recentPos[i].includes(producedPO)) {
        poArr2 = [...poArr2, recentPos[i]];
      }
    }

    var numberArr = [];

    for (let i = 0; i < poArr2.length; i++) {
      numberArr = [
        ...numberArr,
        parseInt(poArr2[i].substring(poArr2[i].length - 3), 10),
      ];

      console.log("💪", poArr2[i]);
    }

    const indexPo = Math.max(...numberArr.map((o) => o)) + 1;

    const indexPoString = indexPo.toString();

    if (numberArr.length === 0) {
      poNum = "001";
    } else if (indexPoString.length === 1) {
      poNum = "00" + indexPoString;
    } else {
      poNum = "0" + indexPoString;
    }
  }

  const producedPOwithIndex = producedPO + poNum;

  useEffect(() => {
    inputHandler("po", producedPOwithIndex, true);
  }, [producedPOwithIndex]);

  useEffect(() => {
    if (tableUrl === "budgetapprovallist") {
      const sendRequest = async () => {
        setIsLoading(true);
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/api2/budgetapprovallist/data`,
            {
              method: "GET",
            }
          );

          const responseData = await response.json();

          if (!response.ok) {
            throw new Error(responseData.message);
          }

          const uniqueNames =
            responseData !== false
              ? Array.from(new Set(responseData?.map((q) => q.rqcnumber)))
              : [];

          const rqcArr = JSON.parse(JSON.stringify(uniqueNames));
          setRecentRqc(rqcArr);
        } catch (err) {
          setError(err.message);
        }
        setIsLoading(false);
      };
      sendRequest();
    }
  }, [tableUrl]);

  let rqcNum;
  let producedRqc;
  let recentOrdertype;

  if (tableUrl === "budgetapprovallist") {
    let initials2 = auth.employeeName;
    recentOrdertype = formState.inputs.supplieroptions?.value[0]?.replaceAll(
      " ",
      ""
    );

    console.log(
      "🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️",
      formState.inputs.supplieroptions
    );

    console.log("🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️", recentOrdertype);

    var getInitials2 = function (string) {
      var names = string?.split(" "),
        initials = names[0]?.substring(0, 1).toUpperCase();

      if (names.length > 1) {
        for (let i = 1; i < names.length; i++)
          initials += names[i]?.substring(0, 1).toUpperCase();
      }
      return initials;
    };

    const formatDate2 = () => {
      var d = new Date(),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [day, month, year].join("");
    };

    producedRqc = "RQC-" + getInitials2(initials2) + "-" + formatDate2() + "-";

    let rqcArr2 = [];

    for (let i = 0; i < recentRqc.length; i++) {
      if (recentRqc[i].includes(producedRqc)) {
        rqcArr2 = [...rqcArr2, recentRqc[i]];
      }
    }

    var numberArr = [];

    for (let i = 0; i < rqcArr2.length; i++) {
      numberArr = [
        ...numberArr,
        parseInt(rqcArr2[i].substring(rqcArr2[i].length - 3), 10),
      ];

      console.log("💪", rqcArr2[i]);
    }

    const indexRqc = Math.max(...numberArr.map((o) => o)) + 1;

    const indexRqcString = indexRqc.toString();

    if (numberArr.length === 0) {
      rqcNum = "001";
    } else if (indexRqcString.length === 1) {
      rqcNum = "00" + indexRqcString;
    } else {
      rqcNum = "0" + indexRqcString;
    }
  }

  const producedRqcwithIndex = producedRqc + rqcNum;

  useEffect(() => {
    inputHandler("rqcnumber", producedRqcwithIndex, true);
  }, [recentOrdertype]);

  // GET DATA ENDS

  // LOCATION STATES START
  const [location, setLocation] = useState([]);
  const [selectedCityList, setSelectedCityList] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState([]);
  const [selectedDistrictList, setSelectedDistrictList] = useState();

  const country = [...new Set(location?.map((q) => q.country))];

  const setSelectedCountry = (selectedCountry) => {
    setSelectedCityList([]);
    setSelectedDistrictList([]);
    const otbok =
      typeof selectedCountry !== "undefined" &&
      location
        .filter(({ country }) =>
          selectedCountry ? country === selectedCountry : true
        )
        .map((el) => el.city);

    const uniqueNames =
      otbok !== false ? Array.from(new Set(otbok?.map((q) => q))) : [];

    const arr = JSON.parse(JSON.stringify(uniqueNames));

    setSelectedCityList(arr);
  };

  const setSelectedCity = (selectedCity) => {
    const otbok =
      typeof selectedCity !== "undefined" &&
      location
        .filter(({ city }) => (selectedCity ? city === selectedCity : true))
        .map((el) => el.district);

    const uniqueNames =
      otbok !== false ? Array.from(new Set(otbok?.map((q) => q))) : [];

    const arr = JSON.parse(JSON.stringify(uniqueNames));

    setSelectedDistrictList(arr);
  };
  // LOCATION STATES END

  // MATERIAL STATES START
  const [material, setMaterial] = useState([]);

  const [selectedModelList, setSelectedModelList] = useState([]);
  // const [selectedSize, setSelectedSize] = useState([]);
  const [selectedSizeList, setSelectedSizeList] = useState();

  // const [selectedDescription, setSelectedDescription] = useState([]);
  const [selectedDescriptionList, setSelectedDescriptionList] = useState();

  // const [selectedUnit, setSelectedUnit] = useState([]);
  const [selectedUnitList, setSelectedUnitList] = useState();

  // const [selectedBarcode, setSelectedBarcode] = useState([]);
  const [selectedBarcodeList, setSelectedBarcodeList] = useState();

  // const [selectedWeight, setSelectedWeight] = useState([]);
  const [selectedWeightList, setSelectedWeightList] = useState();

  const [selectedWeightUnit, setSelectedWeightUnit] = useState([]);
  const [selectedWeightUnitList, setSelectedWeightUnitList] = useState();

  const brandmark = [...new Set(material?.map((q) => q.brandmark))];

  const setSelectedBrandmark = (selectedBrandmark) => {
    setSelectedModelList([]);
    setSelectedSizeList([]);
    setSelectedDescriptionList([]);
    setSelectedUnitList([]);
    setSelectedBarcodeList([]);
    setSelectedWeightList([]);
    setSelectedWeightUnitList([]);
    const otbok =
      typeof selectedBrandmark !== "undefined" &&
      material
        .filter(({ brandmark }) =>
          selectedBrandmark ? brandmark === selectedBrandmark : true
        )
        .map((el) => el.model);

    const uniqueNames =
      otbok !== false ? Array.from(new Set(otbok?.map((q) => q))) : [];

    const arr = JSON.parse(JSON.stringify(uniqueNames));

    setSelectedModelList(arr);
  };

  console.log(
    "🫁 🧠🫁 🧠🫁 🧠🫁 🧠🫁 🧠🫁 🧠🫁 🧠🫁 🧠🫁 🧠🫁 🧠🫁 🧠🫁 🧠🫁 🧠🫁 🧠",
    selectedModelList
  );

  const setSelectedModel = (selectedModel) => {
    const otbok =
      typeof selectedModel !== "undefined" &&
      material
        .filter(({ model }) => (selectedModel ? model === selectedModel : true))
        .map((el) => el.size);

    const uniqueNames =
      otbok !== false ? Array.from(new Set(otbok?.map((q) => q))) : [];

    const arr = JSON.parse(JSON.stringify(uniqueNames));

    setSelectedSizeList(arr);
  };
  console.log("👻👻👻👻👻👻👻👻👻👻👻👻👻👻👻👻", selectedSizeList);

  const setSelectedSize = (selectedSize) => {
    const description =
      typeof selectedSize !== "undefined" &&
      material
        .filter(({ size }) => (selectedSize ? size === selectedSize : true))
        .map((el) => el.description);

    const descriptionUniqueNames =
      description !== false
        ? Array.from(new Set(description?.map((q) => q)))
        : [];

    const descriptionArr = JSON.parse(JSON.stringify(descriptionUniqueNames));

    setSelectedDescriptionList(descriptionArr);

    const unit =
      typeof selectedSize !== "undefined" &&
      material
        .filter(({ size }) => (selectedSize ? size === selectedSize : true))
        .map((el) => el.unit);

    const unitUniqueNames =
      unit !== false ? Array.from(new Set(unit?.map((q) => q))) : [];

    const unitArr = JSON.parse(JSON.stringify(unitUniqueNames));

    setSelectedUnitList(unitArr);

    const barcode =
      typeof selectedSize !== "undefined" &&
      material
        .filter(({ size }) => (selectedSize ? size === selectedSize : true))
        .map((el) => el.barcode);

    const barcodeUniqueNames =
      barcode !== false ? Array.from(new Set(barcode?.map((q) => q))) : [];

    const barcodeArr = JSON.parse(JSON.stringify(barcodeUniqueNames));

    setSelectedBarcodeList(barcodeArr);

    const weight =
      typeof selectedSize !== "undefined" &&
      material
        .filter(({ size }) => (selectedSize ? size === selectedSize : true))
        .map((el) => el.weight);

    const weightUniqueNames =
      weight !== false ? Array.from(new Set(weight?.map((q) => q))) : [];

    const weightArr = JSON.parse(JSON.stringify(weightUniqueNames));

    setSelectedWeightList(weightArr);

    const weightUnit =
      typeof selectedSize !== "undefined" &&
      material
        .filter(({ size }) => (selectedSize ? size === selectedSize : true))
        .map((el) => el.weightunit);

    const weightUnitUniqueNames =
      weightUnit !== false
        ? Array.from(new Set(weightUnit?.map((q) => q)))
        : [];

    const weightUnitArr = JSON.parse(JSON.stringify(weightUnitUniqueNames));

    setSelectedWeightUnitList(weightUnitArr);
  };
  console.log("🧠 🧠 🧠 🧠 🧠 🧠 🧠 🧠", selectedDescriptionList);

  // MATERIAL STATES END

  // ASSIGNMENT STATES START
  const [assignment, setAssignment] = useState([]);

  const [selectedAssignmentList, setSelectedAssignmentList] = useState([]);

  const department = [...new Set(assignment?.map((q) => q.department))];

  console.log("👻👻👻👻👻👻👻👻👻👻👻👻👻👻👻👻", department);

  const setSelectedDepartment = (selectedDepartment) => {
    setSelectedAssignmentList([]);

    const otbok =
      typeof selectedDepartment !== "undefined" &&
      assignment
        .filter(({ department }) =>
          selectedDepartment ? department === selectedDepartment : true
        )
        .map((el) => el.assignment);

    const uniqueNames =
      otbok !== false ? Array.from(new Set(otbok?.map((q) => q))) : [];

    const arr = JSON.parse(JSON.stringify(uniqueNames));

    setSelectedAssignmentList(arr);
  };

  console.log("selectedAssignmentList", selectedAssignmentList);

  const setSelectedAssignment = (selectedAssignment) => {
    const otbok =
      typeof selectedAssignment !== "undefined" &&
      assignment.filter(({ assignment }) =>
        selectedAssignment ? assignment === selectedAssignment : true
      );
  };

  // ASSIGNMENT STATES END

  let indexDropDown = [];
  let indexMultiSelect = [];
  useEffect(() => {
    const sendRequest = async () => {
      setIsLoading(true);
      // COLUMN DATA START
      try {
        const responseColumns = await fetch(
          `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/column`,
          {
            method: "GET",
          }
        );

        const responseColumnsData = await responseColumns.json();

        if (!responseColumns.ok) {
          throw new Error(responseColumnsData.message);
        }
        setColumns(responseColumnsData);

        const ctypeNew = responseColumnsData
          .filter(({ ctypenew }) => ctypenew === true)
          .map((el) => el);

        const uniqueNames =
          ctypeNew !== false
            ? Array.from(new Set(ctypeNew?.map((q) => q)))
            : [];

        const arr = JSON.parse(JSON.stringify(uniqueNames));

        let receivedValues = {};
        let sendValues = {};
        let categorymapp = Object.values(arr)
          .reduce(
            (concatedArr, item) => concatedArr.concat(Object.entries(item)),
            []
          )
          .filter((pilot) => pilot[0] === "value")
          .reduce((result, [category, values]) => {
            result["intvalue"] = result["intvalue"] || [];

            result["intvalue"] = result["intvalue"].concat({
              [values]: {
                value: "",
                isValid: false,
              },
            });

            receivedValues = result;

            return result;
          }, {});

        for (let i = 0; i < categorymapp["intvalue"].length; i++) {
          Object.assign(sendValues, categorymapp["intvalue"][i]);
        }

        setColumnData(sendValues);
        console.log("🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶🥶", sendValues);

        setFormData(sendValues, true);
        // COLUMN DATA END

        // DROPDOWN DATA START
        let collectDropDown = [];
        for (let i = 0; i < responseColumnsData.length; i++) {
          if (responseColumnsData[i].ctypeselection === true) {
            collectDropDown = [
              ...collectDropDown,
              responseColumnsData[i].value,
            ];
          }
        }

        const numrows = collectDropDown.length;

        const blah = Array.from(Array(numrows).keys());

        for (let i = 0; i < blah.length; i++) {
          const sendRequest = async () => {
            setIsLoading(true);

            try {
              const responseColumns2 = await fetch(
                `${process.env.REACT_APP_API_URL}/api2/dropdown/${collectDropDown[i]}`,

                {
                  method: "GET",
                }
              );

              const responseColumnsData2 = await responseColumns2.json();

              if (!responseColumns2.ok) {
                throw new Error(responseColumnsData2.message);
              }
              console.log(
                "🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏",
                responseColumnsData2
              );
              let changeDropDownName = [];
              let categorymapp = Object.values(responseColumnsData2)
                .reduce(
                  (concatedArr, item) =>
                    concatedArr.concat(Object.entries(item)),
                  []
                )
                .reduce((result, [category, values]) => {
                  result["intvalue"] = result["intvalue"] || [];
                  result["intvalue"] = result["intvalue"].concat({
                    name: values,
                  });

                  changeDropDownName = [
                    ...changeDropDownName,
                    {
                      name: values,
                    },
                  ];

                  return result;
                }, {});

              indexDropDown[i] = changeDropDownName;

              const indexDropDownToJSON = JSON.parse(
                JSON.stringify(indexDropDown)
              );

              setDropDownData(indexDropDownToJSON);
            } catch (err) {
              setError(err.message);
            }
            setIsLoading(false);
          };
          sendRequest();
        }
        // DROPDOWN DATA END

        // LOCATION DATA START

        let collectCascadingPRE = [];
        for (let i = 0; i < responseColumnsData.length; i++) {
          if (responseColumnsData[i].ctypecascading === true) {
            collectCascadingPRE = [
              ...collectCascadingPRE,
              responseColumnsData[i].ctypecascadingfamily,
            ];
          }
        }
        const collectCascading =
          collectCascadingPRE !== false
            ? Array.from(new Set(collectCascadingPRE?.map((q) => q)))
            : [];

        const numrows3 = collectCascading.length;

        const blah3 = Array.from(Array(numrows3).keys());

        if (collectCascading.length !== 0) {
          for (let i = 0; i < blah3.length; i++) {
            const sendRequest = async () => {
              setIsLoading(true);
              console.log("collectCascading[i]", collectCascading[i]);
              try {
                const responseColumns3 = await fetch(
                  `${process.env.REACT_APP_API_URL}/api2/cascading/${collectCascading[i]}`,

                  {
                    method: "GET",
                  }
                );

                const responseColumnsData3 = await responseColumns3.json();

                if (!responseColumns3.ok) {
                  throw new Error(responseColumnsData3.message);
                }
                if (collectCascading[i] === "location") {
                  setLocation(responseColumnsData3);
                } else if (collectCascading[i] === "material") {
                  setMaterial(responseColumnsData3);
                } else if (collectCascading[i] === "assignment") {
                  setAssignment(responseColumnsData3);
                }
              } catch (err) {
                setError(err.message);
              }
              setIsLoading(false);
            };
            sendRequest();
          }
        }

        // LOCATION DATA END

        // MULTISELECT DATA START

        let collectMultiSelect = [];
        for (let i = 0; i < responseColumnsData.length; i++) {
          if (responseColumnsData[i].ctypemultiselect === true) {
            collectMultiSelect = [
              ...collectMultiSelect,
              responseColumnsData[i].value,
            ];
          }
        }

        const numrows2 = collectMultiSelect.length;

        const blah2 = Array.from(Array(numrows2).keys());

        if (collectMultiSelect.length !== 0) {
          for (let i = 0; i < blah2.length; i++) {
            const sendRequest = async () => {
              setIsLoading(true);
              console.log("collectMultiSelect[i]", collectMultiSelect[i]);
              try {
                const responseColumns4 = await fetch(
                  `${process.env.REACT_APP_API_URL}/api2/multiselect/${collectMultiSelect[i]}`,

                  {
                    method: "GET",
                  }
                );

                const responseColumnsData4 = await responseColumns4.json();

                console.log(
                  "🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️🧚‍♂️",
                  responseColumnsData4
                );

                if (!responseColumns4.ok) {
                  throw new Error(responseColumnsData4.message);
                }

                let changeMultiSelectName = [];
                let categorymapp = Object.values(responseColumnsData4)
                  .reduce(
                    (concatedArr, item) =>
                      concatedArr.concat(Object.entries(item)),
                    []
                  )
                  .reduce((result, [category, values]) => {
                    result["intvalue"] = result["intvalue"] || [];
                    result["intvalue"] = result["intvalue"].concat({
                      name: values,
                    });

                    changeMultiSelectName = [
                      ...changeMultiSelectName,
                      {
                        name: values,
                      },
                    ];

                    return result;
                  }, {});

                indexMultiSelect[i] = changeMultiSelectName;

                const indexMultiSelectToJSON = JSON.parse(
                  JSON.stringify(indexMultiSelect)
                );

                // setDropDownData(indexDropDownToJSON);

                setMultiSelect(indexMultiSelectToJSON);
              } catch (err) {
                setError(err.message);
              }
              setIsLoading(false);
            };
            sendRequest();
          }
        }

        // MULTISELECT DATA END
      } catch (err) {
        setError(err.message);
      }
      setIsLoading(false);
    };
    sendRequest();
  }, [setFormData]);

  console.log("formState.inputs", formState.inputs);

  console.log("assignment", assignment);

  console.log("multiselect", multiSelect);

  const placeSubmitHandler = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    if (
      formState.inputs.attachmentfile?.value !== undefined &&
      formState.inputs.attachmentfile?.value.length > 1
    ) {
      try {
        let i;
        var formData = new FormData();

        for (var key in formState.inputs) {
          if (key === "attachmentfile") {
            for (
              i = 0;
              i < formState.inputs.attachmentfile?.value.length;
              i++
            ) {
              formData.append(key, formState.inputs[key].value[i]);
            }
          } else {
            formData.append(key, formState.inputs[key].value);
          }
        }

        await sendRequest(
          `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/multipleupload`,
          "POST",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setRefreshData(!refreshData);
        setIsLoading(false);
        closeNewDrawerHandler();
      } catch (err) { }
    } else if (
      formState.inputs.attachmentfile?.value !== undefined ||
      formState.inputs.avatarfile?.value !== "" ||
      formState.inputs.imagefile?.value !== ""
    ) {
      try {
        var formData = new FormData();
        for (var key in formState.inputs) {
          formData.append(key, formState.inputs[key].value);
        }

        await sendRequest(
          `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/singleupload`,
          "POST",
          formData,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        setRefreshData(!refreshData);
        setIsLoading(false);
        closeNewDrawerHandler();
      } catch (err) {
        console.log(err);
      }
    } else {
      const withoutFile = async () => {
        try {

          await sendRequest(
            `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/singleupload`,
            "POST",

            JSON.stringify(
              Object.values(formState)
                .reduce(
                  (concatedArr, item) =>
                    concatedArr.concat(Object.entries(item)),
                  []
                )
                .reduce((result, [category, values]) => {
                  console.log("değerler", category);

                  result[category] = result[category] || "";
                  result[category] = result[category].concat(values.value);
                  console.log("authtoken", result);
                  return result;
                }, {})
            ),

            {
              "Content-Type": "application/json",
              Authorization: "Bearer " + auth.token,
            }
          );

          setRefreshData(!refreshData);
          setIsLoading(false);
          closeNewDrawerHandler();
        } catch (err) {
          console.log("err", err);
        }
      };
      withoutFile();
    }
  };

  let k = 0; // dropdown index
  let m = 0; // dropdown index

  console.log("🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏🙏", dropdownData);

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />

      <form className={stylessNewForm.place} onSubmit={placeSubmitHandler}>
        {isLoading && <LoadingSpinner asOverlay />}
        {screenWidth <= 500 && < span className={stylessNewForm.closeicon} onClick={closeNewDrawerHandler}></span>}
        {columns.map((item, i) => {
          return item.ctypenew ? (
            item.ctypeselection ? (
              <>
                <CustomSelect
                  id={item.value}
                  onChange={inputHandler}
                  data={dropdownData[k]}
                  name={item.value}
                  label={item.cname}
                ></CustomSelect>
                <script>{(k = k + 1)}</script>
              </>
            ) : item.ctypecascadingfamily === "location" &&
              item.ctypecascadingdegree === "1" ? (
              <LocationCascading
                setSelectedCountry={setSelectedCountry}
                country={country}
                onChangeCountry={inputHandler}
                setSelectedCity={setSelectedCity}
                selectedCityList={selectedCityList}
                onChangeCity={inputHandler}
                setSelectedDistrict={setSelectedDistrict}
                selectedDistrictList={selectedDistrictList}
                onChangeDistrict={inputHandler}
              ></LocationCascading>
            ) : item.ctypecascadingfamily === "material" &&
              item.ctypecascadingdegree === "1" ? (
              <MaterialCascading
                setSelectedBrandmark={setSelectedBrandmark}
                brandmark={brandmark}
                onChangeBrandmark={inputHandler}
                setSelectedModel={setSelectedModel}
                selectedModelList={selectedModelList}
                onChangeModel={inputHandler}
                setSelectedSize={setSelectedSize}
                selectedSizeList={selectedSizeList}
                onChangeSize={inputHandler}
                setSelectedDescription={setSelectedDescriptionList}
                selectedDescriptionList={selectedDescriptionList}
                onChangeDescription={inputHandler}
                setSelectedUnit={setSelectedUnitList}
                selectedUnitList={selectedUnitList}
                onChangeUnit={inputHandler}
                setSelectedBarcode={setSelectedBarcodeList}
                selectedBarcodeList={selectedBarcodeList}
                onChangeBarcode={inputHandler}
                setSelectedWeight={setSelectedWeightList}
                selectedWeightList={selectedWeightList}
                onChangeWeight={inputHandler}
                setSelectedWeightUnit={setSelectedWeightUnit}
                selectedWeightUnitList={selectedWeightUnitList}
                onChangeWeightUnit={inputHandler}
                onChangeAmount={inputHandler}
              ></MaterialCascading>
            ) : item.ctypecascadingfamily === "assignment" &&
              item.ctypecascadingdegree === "1" ? (
              <AssignmentCascading
                setSelectedDepartment={setSelectedDepartment}
                department={department}
                onChangeDepartment={inputHandler}
                setSelectedAssignment={setSelectedAssignment}
                selectedAssignmentList={selectedAssignmentList}
                onChangeAssignment={inputHandler}
              ></AssignmentCascading>
            ) : item.ctypeinput &&
              item.value !== "po" &&
              item.value !== "rqcnumber" &&
              item.value !== "ordernote" ? (
              <Input
                id={item.value}
                element="input"
                type="text"
                label={item.cname}
                validators={
                  item.value === "email"
                    ? [VALIDATOR_EMAIL()]
                    : item.value === "password"
                      ? [VALIDATOR_MINLENGTH(5)]
                      : [VALIDATOR_REQUIRE()]
                }
                errorText="Please enter a valid title."
                onInput={inputHandler}
              />
            ) : item.ctypeinput &&
              item.value === "ordernote" ? (
              <Input
                id={item.value}
                element="input"
                type="text"
                label={item.cname}
                initialValid={true}
                validators={[true]}
                onInput={inputHandler}
              />
            )
              : item.ctypeinput && item.value === "po" ? (
                <p> {producedPOwithIndex}</p>
              ) : item.ctypeinput && item.value === "rqcnumber" ? (
                <p> {producedRqcwithIndex}</p>
              ) : item.ctypedate ? (
                <DateComp
                  id={item.value}
                  element="input"
                  type="date"
                  label={item.cname}
                  validators={[VALIDATOR_REQUIRE()]}
                  errorText="Please enter a date."
                  onInput={inputHandler}
                />
              ) : item.ctypemultiselect ? (
                <>
                  <MultiSelect
                    id={item.value}
                    onChange={inputHandler}
                    data={multiSelect[m]}
                    name={item.value}
                    label={item.cname}
                  />
                  <script>{(m = m + 1)}</script>
                </>
              ) : item.ctypeattachmentfile ? (
                <AttachmentUpload
                  id="attachmentfile"
                  onInput={inputHandler}
                  initialValue=""
                />
              ) : item.ctypeavatarfile ? (
                <AvatarUpload
                  id="avatarfile"
                  onInput={inputHandler}
                  initialValue=""
                />
              ) : item.ctypeimagefile ? (
                <ImageUpload
                  id="imagefile"
                  onInput={inputHandler}
                  initialValue=""
                />
              ) : null
          ) : null;
        })}
        <br></br>
        <Button size="3" type="submit" disabled={!formState.isValid}>
          Add New
        </Button>
        <br></br>
      </form>
    </React.Fragment>
  );
};

export default NewForm;
