import React, { useState, useEffect } from "react";

import { useHttpClient } from "../../shared/hooks/http-hook";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import CustomSelect from "../../shared/components/FormElements/CustomSelect/CustomSelect";
import Dropdown from "../../shared/components/FormElements/CustomSelect/DropDown";
import EquipmentLibraryHeaderCss from "./EquipmentLibraryHeader.module.css";
const EquipmentLibraryHeader = ({ equipmentColumns, tableUrl, projectname, resetSearchFilter, setResetSearchFilter, setFolders }) => {
    const [dropdownData, setDropDownData] = useState([]);
    const [dropdownDataValue, setDropDownDataValue] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [area, setArea] = useState("");
    let indexDropDown = [];
    let indexDropDownValue = [];
    console.log("👽👽👽👽projectname+tableurl👽👽👽👽", projectname + tableUrl);

    function handleArea(e, f, t) {

        console.log(" 🥶  🥶  🥶  🥶  🥶  🥶  🥶  🥶  🥶  🥶  🥶 ", equipmentColumns.find(x => x.cname === f).value);


        setArea(equipmentColumns.find(x => x.cname === f).value);
    }

    useEffect(() => {

        if (equipmentColumns != undefined) {
            let changeDropDownName = [];
            let changeDropDownValue = [];
            for (let k = 0; k < equipmentColumns.length; k++) {
                if (equipmentColumns[k].isSearchFilter) {
                    changeDropDownName = [
                        ...changeDropDownName,
                        {
                            name: equipmentColumns[k].cname,
                        },
                    ];
                    changeDropDownValue = [
                        ...changeDropDownValue,
                        {
                            name: equipmentColumns[k].value,
                        },
                    ];
                }

            }


            indexDropDown[0] = changeDropDownName;

            const indexDropDownToJSON = JSON.parse(
                JSON.stringify(indexDropDown)
            );

            indexDropDownValue[0] = changeDropDownValue;

            const indexDropDownValueToJSON = JSON.parse(
                JSON.stringify(indexDropDownValue)
            );

            setDropDownData(indexDropDownToJSON);
            setDropDownDataValue(indexDropDownValueToJSON)
        }

    }, [equipmentColumns])


    function searchByColumn(e) {

        const urlForSearch = projectname + tableUrl;
        console.log("area", area);
        const fetchData = async () => {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api2/${urlForSearch}/data/findbycolumn/${searchText}/${area}`,
                {
                    method: "GET",
                    headers: { "Content-Type": "application/json" },
                }
            );
            const responseData = await response.json();
            console.log(responseData);
            setFolders(responseData);
        };



        if (searchText.length > 0) {
            fetchData();
        } else {
            setResetSearchFilter(!resetSearchFilter);
        }
    }

    return (
        <React.Fragment>

            <div class={EquipmentLibraryHeaderCss.div1}><h3>{projectname + " Equipment Library"}</h3></div>
            <div class={EquipmentLibraryHeaderCss.div2}>
                <CustomSelect
                    id={"area"}
                    onChange={handleArea}
                    data={dropdownData[0]}
                    name={"area"}
                    label={"area"}
                    difference={true}
                ></CustomSelect>

                <input type="text" value={searchText} placeholder="Search.." id="search" class={EquipmentLibraryHeaderCss.input}
                    onChange={(e) => setSearchText(e.target.value)}
                />


                <button onClick={searchByColumn} class={EquipmentLibraryHeaderCss.button_007}>Search</button>

            </div>








        </React.Fragment>)
}
export default EquipmentLibraryHeader;