import React, { useContext } from "react";

import { useParams, NavLink } from "react-router-dom";
import { AuthContext } from "../../shared/context/auth-context";
import ProfilSide from "../../shared/components/Navigation/ProfileSide";
import stylessDepartmentPage from "./DepartmentPage.module.css";

const DepartmentPage = () => {
  const auth = useContext(AuthContext);

  let department = useParams().department;
  console.log("1", department);

  department = department.replace(/-/g, " ");
  console.log("2", department);

  var getInitials2 = function (string) {
    var names = string.split(" "),
      initials = names[0].charAt(0).toUpperCase() + names[0].slice(1);

    if (names.length > 1) {
      for (let i = 1; i < names.length; i++)
        initials += " " + names[i].charAt(0).toUpperCase() + names[i].slice(1);
    }
    return initials;
  };


  console.log(getInitials2(department));

  department = getInitials2(department);

  console.log("app - department DEPARTMENTPAGE", auth.employeeDepartment);

  if (
    department === auth.employeeDepartment &&
    department === "Production Department"
  ) {
    return (
      <React.Fragment>
        <div>DepartmentPage</div>

        <div>Production</div>

        <li>
          <NavLink to="/department/purchase-department/purchaseorderlist">
            {" "}
            <div>
              Go to Purchase Order List Page
              <div></div>
            </div>
          </NavLink>
        </li>
      </React.Fragment>
    );
  }
  if (
    department === auth.employeeDepartment &&
    department === "Purchasing Department"
  ) {

    return (
      <React.Fragment>
        <div className={stylessDepartmentPage.body}>
          <div className={stylessDepartmentPage.body_row}>
            <div className={stylessDepartmentPage.profil}> <ProfilSide /></div>
            <div>
              <nav className={stylessDepartmentPage.card}>DepartmentPage Purchasing</nav>
              <div className={stylessDepartmentPage.project_area}>
                <li>
                  <NavLink to="/department/purchase-department/purchaseorderlist">
                    {" "}
                    <div className={stylessDepartmentPage.project_card}>
                      Go to Purchase Order List Page
                      <div></div>
                    </div>
                  </NavLink>

                </li>
                <br></br>
                <li>
                  <NavLink to="/department/purchase-department/budgetapprovallist">
                    {" "}
                    <div className={stylessDepartmentPage.project_card}>
                      Go to Budget Approval List Page
                      <div></div>
                    </div>
                  </NavLink>
                </li>
                <br></br>
                <li>
                  <NavLink to="/department/purchase-department/supplier">
                    {" "}
                    <div className={stylessDepartmentPage.project_card}>
                      Go to Supplier List Page
                      <div></div>
                    </div>
                  </NavLink>
                </li>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  if (
    department === auth.employeeDepartment &&
    department === "Management Department"
  ) {
    return (
      <React.Fragment>
        <div>DepartmentPage</div>

        <div>Management</div>

        <li>
          <NavLink to="/department/purchase-department/purchaseorderlist">
            {" "}
            <div>
              Go to Purchase Order List Page
              <div></div>
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink to="/department/purchase-department/budgetapprovallist">
            {" "}
            <div>
              Go to Budget Approval List Page
              <div></div>
            </div>
          </NavLink>
        </li>
        <li>
          <NavLink to="/department/purchase-department/supplier">
            {" "}
            <div>
              Go to Supplier List Page
              <div></div>
            </div>
          </NavLink>
        </li>
      </React.Fragment>
    );
  } else {
    return <div>Not Authorized!!! </div>;
  }
};

export default DepartmentPage;
