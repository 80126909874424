import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

import TableBody from "./Components/TableBody";
import TableHeader from "./Components/TableHeader";
import BackDrop from "../shared/components/UIElements/BackDrop";
import SideDrawer from "../shared/components/Navigation/SideDrawer";
import Modal from "../shared/components/UIElements/Modal";
import Button from "../shared/components/FormElements/Button";
import TableNewForm from "./Forms/NewForm";
import TableEditForm from "./Forms/EditForm";
import TableDeleteForm from "./Forms/DeleteForm";
import TableRestoreForm from "./Forms/RestoreForm";
import TableTechApproveForm from "./Forms/TableTechApproveForm";
import TableCreateRqcForm from "./Forms/TableCreateRqcForm";
import TableCreateTrqForm from "./Forms/TableCreateTrqForm";
import TableSelectSupplierForm from "./Forms/TableSelectSupplierForm";
import TableFinancialApproveForm from "./Forms/TableFinancialApproveForm";
import ProfilSide from "../shared/components/Navigation/ProfileSide";
import ProfileSideDrawer from "../shared/components/Navigation/ProfileSideDrawer";
import TableDispayForm from "./Forms/DisplayForm";
import TableFilterForm from "./Forms/FilterForm";
import TableExportToExcel from "./Components/TableExportToExcel";
import stylesTable from "./Table.module.css";

import { AuthContext } from "../shared/context/auth-context";
import ReleaseForm from "./Forms/ReleaseForm";
import TableUpdateOrderStatusForm from "./Forms/TableUpdateOrderStatusForm";
import TableUpdateExpReceDateForm from "./Forms/TableUpdateExpReceDateForm";
import TableBodyMobile from "./Components/TableBodyMobile";

const Table = ({ tableUrl }) => {
  const auth = useContext(AuthContext);
  const [newDrawerIsOpen, setNewDrawerIsOpen] = useState(false);
  const [profileDrawerIsOpen, setProfileDrawerIsOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [showType, setShowType] = useState([]);
  const [query, setQuery] = useState("");
  const [filterChoosen, setFilterChoosen] = useState([]);
  const [checkedChoosen, setCheckedChoosen] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState();
  const [refreshData, setRefreshData] = useState(false);
  const [checkedlist, setCheckedList] = useState([]);
  const [isFilterFieldMultiSelect, setIsFilterFieldMultiSelect] =
    useState(false);
  const openProfileDrawerHandler = () => {
    setProfileDrawerIsOpen(true);
  };

  const closeProfileDrawerHandler = () => {
    setProfileDrawerIsOpen(false);
  };
  const openNewDrawerHandler = () => {
    setNewDrawerIsOpen(true);
  };

  const closeNewDrawerHandler = () => {
    setNewDrawerIsOpen(false);
  };

  const [releaseDrawerIsOpen, setReleaseDrawerIsOpen] = useState(false);

  const openReleaseDrawerHandler = () => {
    setReleaseDrawerIsOpen(true);
  };

  const closeReleaseDrawerHandler = () => {
    setReleaseDrawerIsOpen(false);
  };

  const [editDrawerIsOpen, setEditDrawerIsOpen] = useState(false);

  const openEditDrawerHandler = () => {
    setEditDrawerIsOpen(true);
  };

  const closeEditDrawerHandler = () => {
    setEditDrawerIsOpen(false);
  };

  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const openDeleteModalHandler = () => {
    setDeleteModalIsOpen(true);
  };

  const closeDeleteModalHandler = () => {
    setDeleteModalIsOpen(false);
  };

  const [restoreModalIsOpen, setRestoreModalIsOpen] = useState(false);

  const openRestoreModalHandler = () => {
    setRestoreModalIsOpen(true);
  };

  const closeRestoreModalHandler = () => {
    setRestoreModalIsOpen(false);
  };

  const [techApproveModalIsOpen, setTechApproveModalIsOpen] = useState(false);

  const openTechApproveModalHandler = () => {
    setTechApproveModalIsOpen(true);
  };

  const closeTechApproveModalHandler = () => {
    setTechApproveModalIsOpen(false);
  };

  const [createRqcModalIsOpen, setCreateRqcModalIsOpen] = useState(false);

  const openCreateRqcModalHandler = () => {
    setCreateRqcModalIsOpen(true);
  };

  const closeCreateRqcModalHandler = () => {
    setCreateRqcModalIsOpen(false);
  };

  const [createTrqModalIsOpen, setCreateTrqModalIsOpen] = useState(false);

  const openCreateTrqModalHandler = () => {
    setCreateTrqModalIsOpen(true);
  };

  const closeCreateTrqModalHandler = () => {
    setCreateTrqModalIsOpen(false);
  };

  const [selectSupplierModalIsOpen, setSelectSupplierModalIsOpen] =
    useState(false);

  const openSelectSupplierModalHandler = () => {
    setSelectSupplierModalIsOpen(true);
  };

  const closeSelectSupplierModalHandler = () => {
    setSelectSupplierModalIsOpen(false);
  };

  const [financialApproveModalIsOpen, setFinancialApproveModalIsOpen] =
    useState(false);

  const openFinancialApproveModalHandler = () => {
    setFinancialApproveModalIsOpen(true);
  };

  const closeFinancialApproveModalHandler = () => {
    setFinancialApproveModalIsOpen(false);
  };

  const [displayDrawerIsOpen, setDisplayDrawerIsOpen] = useState(false);

  const openDisplayDrawerHandler = () => {
    setDisplayDrawerIsOpen(true);
  };

  const closeDisplayDrawerHandler = () => {
    setDisplayDrawerIsOpen(false);
  };

  const [showUpdateOrderStatusButton, setShowUpdateOrderStatusButton] = useState(false);
  const [updateOrderStatusModalIsOpen, setUpdateOrderStatusModalIsOpen] = useState(false);

  const openUpdateOrderStatusModalHandler = () => {
    setUpdateOrderStatusModalIsOpen(true);
  };

  const closeUpdateOrderStatusModalHandler = () => {
    setUpdateOrderStatusModalIsOpen(false);
  };


  const [showUpdateExpReceDateButton, setShowUpdateExpReceDateButton] = useState(false);
  const [updateExpReceDateModalIsOpen, setUpdateExpReceDateModalIsOpen] = useState(false);

  const openUpdateExpReceDateModalHandler = () => {
    setUpdateExpReceDateModalIsOpen(true);
  };

  const closeUpdateExpReceDateModalHandler = () => {
    setUpdateExpReceDateModalIsOpen(false);
  };


  const [selectedID, setSelectedID] = useState();
  console.log("selectedID", selectedID);

  const [editAll, setEditAll] = useState(false);
  const [isChecked, setIsChecked] = useState([]);
  const [isCheckedFile, setIsCheckedFile] = useState([]);
  const [isCheckedAvatar, setIsCheckedAvatar] = useState([]);
  const [isCheckedImage, setIsCheckedImage] = useState([]);

  const [isCheckedOrderType, setIsCheckedOrderType] = useState([]);
  const [isCheckedWoNumber, setIsCheckedWoNumber] = useState([]);

  const [isCheckedCreatedBy, setIsCheckedCreatedBy] = useState([]);
  const [isCheckedCreatedByDepartment, setIsCheckedCreatedByDepartment] = useState([]);

  const openEditAllHandler = () => {
    setEditAll(true);
  };

  const closeEditAllHandler = () => {
    setEditAll(false);
  };

  const [filterDrawerIsOpen, setFilterDrawerIsOpen] = useState(false);
  const openFilterDrawerHandler = (setvalue, ctypemultiselect) => {
    setSelectedColumn(setvalue);
    setIsFilterFieldMultiSelect(ctypemultiselect);
    setFilterDrawerIsOpen(true);
  };

  const closeFilterDrawerHandler = () => {
    setFilterDrawerIsOpen(false);
  };

  const [showNewButton, setShowNewButton] = useState(false);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [showEditButton, setShowEditButton] = useState(false);

  const [showRestoreButton, setShowRestoreButton] = useState(false);
  const [showReleaseEmployeeButton, setShowReleaseEmployeeButton] = useState(false);

  const [showTechApproveButton, setShowTechApproveButton] = useState(false);
  const [showCreateRqcButton, setShowCreateRqcButton] = useState(false);
  const [showSelectSupplierButton, setShowSelectSupplierButton] =
    useState(false);
  const [showFinancialApproveButton, setShowFinancialApproveButton] =
    useState(false);
  const [showCreateTrqButton, setShowCreateTrqButton] = useState(false);

  const [showEditInGridview, setShowEditInGridview] = useState(false);
  const [showSaveAll, setShowSaveAll] = useState(false);

  useEffect(() => {
    if (tableUrl === "budgetapprovallist" || tableUrl === "trqlist") {
      setShowNewButton(false);
    } else {
      setShowNewButton(true);
    }

    if (auth.userType === "SuperAdmin") {
      setShowEditInGridview(true);
      setShowSaveAll(true);
    } else {
      setShowEditInGridview(false);
      setShowSaveAll(false);
    }


  }, [tableUrl]);

  useEffect(() => {


    setShowEditButton(false);
    setShowDeleteButton(false);
    setShowTechApproveButton(false);
    setShowCreateRqcButton(false);
    setShowCreateTrqButton(false);
    setShowUpdateExpReceDateButton(false);
    setShowUpdateOrderStatusButton(false);

    let uniqueArray = [...new Set(isCheckedCreatedBy)];

    if (tableUrl === "purchaseorderlist") {

      if (isChecked.length !== 0) {

        if (auth.employeeDepartment.includes("Purchasing")) {
          setShowCreateRqcButton(true);
          setShowCreateTrqButton(true);
          setShowUpdateExpReceDateButton(true);
          setShowUpdateOrderStatusButton(true);

        } else {
          setShowCreateRqcButton(false);
          setShowCreateTrqButton(false);
          setShowUpdateExpReceDateButton(false);
          setShowUpdateOrderStatusButton(false);
        }

        if (isChecked.length === 1) {
          var department;
          const fetchDepartment = async () => {
            const response = await fetch(
              `${process.env.REACT_APP_API_URL}/api2/departmentCheck/${uniqueArray[0]}`,
              {
                method: "GET",
                headers: { "Content-Type": "application/json" },
              }
            );

            department = await response.json();
            console.log("department", response.status);

          };

          fetchDepartment().then(() => {
            if (isChecked.length === 1 &&
              (uniqueArray.length === 1 && uniqueArray[0] === auth.employeeName) ||
              (department === auth.employeeDepartment && auth.employeeAssignment.includes("Manager")) ||

              (auth.employeeDepartment.includes("Management"))
            ) {
              setShowEditButton(true);
            } else {
              setShowEditButton(false);
            }

            if (isChecked.length !== 0 &&
              (uniqueArray.length === 1 && uniqueArray[0] === auth.employeeName) ||
              (department === auth.employeeDepartment && auth.employeeAssignment.includes("Manager")) ||

              (auth.employeeDepartment.includes("Management"))
            ) {
              setShowDeleteButton(true);
            } else {
              setShowDeleteButton(false);
            }

            if (isChecked.length !== 0 &&
              (department === auth.employeeDepartment && auth.employeeAssignment.includes("Manager")) ||
              (auth.employeeDepartment.includes("Management"))
            ) {
              setShowTechApproveButton(true);
            } else {
              setShowTechApproveButton(false);
            }


          });


        } else if (isChecked.length > 1) {
          var department = [];

          const fetchDepartment = async () => {
            for (let k = 0; k < uniqueArray.length; k++) {
              const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api2/departmentCheck/${uniqueArray[k]}`,
                {
                  method: "GET",
                  headers: { "Content-Type": "application/json" },
                }
              );
              const respdata = await response.json();

              department = [...department, respdata];
            }

          };
          fetchDepartment().then(() => {

            // Manager of createdby department check
            let uniqueArrayDepartment = [...new Set(department)];
            let sonuc = true;
            for (let j = 0; j < uniqueArrayDepartment.length; j++) {
              if (sonuc && uniqueArrayDepartment[j] === auth.employeeDepartment && auth.employeeAssignment.includes("Manager")) {
              } else {
                sonuc = false;
              }
            }
            // Manager of createdby department check

            if (isChecked.length !== 0 &&
              (uniqueArray.length === 1 && uniqueArray[0] === auth.employeeName) ||
              sonuc ||
              (auth.employeeDepartment.includes("Management"))
            ) {
              setShowDeleteButton(true);
            } else {
              setShowDeleteButton(false);
            }

            if (isChecked.length !== 0 &&
              sonuc ||
              (auth.employeeDepartment.includes("Management"))
            ) {
              setShowTechApproveButton(true);
            } else {
              setShowTechApproveButton(false);
            }
          });

        }

      } else {
        setShowEditButton(false);
        setShowDeleteButton(false);
        setShowTechApproveButton(false);
        setShowCreateRqcButton(false);
        setShowCreateTrqButton(false);
        setShowUpdateExpReceDateButton(false);
        setShowUpdateOrderStatusButton(false);
      }


    } else {
      if (isChecked.length !== 0) {

        console.log("🤢🤢🤢", tableUrl);
        console.log("🤢🤢🤢🤢🤢🤢", auth.userType);
        console.log("🤢🤢🤢🤢🤢🤢🤢🤢🤢", auth.employeeAssignment);
        console.log("🤢🤢🤢🤢🤢🤢🤢🤢🤢🤢🤢🤢", auth.employeeDepartment);
        setShowDeleteButton(true);
        setShowRestoreButton(tableUrl.includes("Recycle"));

        if (
          (isChecked.length !== 0 &&
            tableUrl === "purchaseorderlist" &&
            auth.userType === "Internal" &&
            auth.employeeAssignment.includes("Manager")) ||
          (isChecked.length !== 0 &&
            tableUrl === "purchaseorderlist" &&
            auth.userType === "Internal" &&
            auth.employeeAssignment.includes("Director"))
        ) {
          setShowTechApproveButton(true);
        }
        if (
          isChecked.length !== 0 &&
          tableUrl === "purchaseorderlist" &&
          auth.userType === "Internal" &&
          auth.employeeDepartment === "Purchasing Department"
        ) {
          setShowCreateRqcButton(true);
          setShowCreateTrqButton(true);
        }

        if (isChecked.length === 1) {
          setShowEditButton(true);
          if (tableUrl === "employee") {
            if (rows.find(x => x._id === isChecked[0]).employeestatus === false) {
              setShowReleaseEmployeeButton(false);
            } else {
              setShowReleaseEmployeeButton(true);
            }

          }

          console.log("🤮🤮🤮", tableUrl);
          console.log("🤮🤮🤮🤮🤮🤮", auth.userType);
          console.log("🤮🤮🤮🤮🤮🤮🤮🤮🤮", auth.employeeAssignment);
          console.log("🤮🤮🤮🤮🤮🤮🤮🤮🤮🤮🤮🤮", auth.employeeDepartment);

          if (
            (tableUrl === "purchaseorderlist" &&
              auth.userType === "Internal" &&
              auth.employeeAssignment.includes("Manager")) ||
            (tableUrl === "purchaseorderlist" &&
              auth.userType === "Internal" &&
              auth.employeeAssignment.includes("Director"))
          ) {
            setShowTechApproveButton(true);
          }
          if (
            isChecked.length !== 0 &&
            tableUrl === "purchaseorderlist" &&
            auth.userType === "Internal" &&
            auth.employeeDepartment === "Purchasing Department"
          ) {
            setShowCreateRqcButton(true);
            setShowCreateTrqButton(true);
          }
          if (
            isChecked.length !== 0 &&
            tableUrl === "budgetapprovallist" &&
            auth.userType === "Internal" &&
            auth.employeeDepartment === "Management Department"
          ) {
            setShowSelectSupplierButton(true);
            setShowFinancialApproveButton(true);
          }
        } else {
          setShowEditButton(false);
          setShowSelectSupplierButton(false);
          setShowFinancialApproveButton(false);
        }
      } else {
        setShowDeleteButton(false);
        setShowRestoreButton(false);
        setShowEditButton(false);
        setShowTechApproveButton(false);
        setShowCreateRqcButton(false);
        setShowCreateTrqButton(false);
        setShowSelectSupplierButton(false);
        setShowFinancialApproveButton(false);
        setShowReleaseEmployeeButton(false);
      }
    }


  }, [isChecked]);

  useEffect(() => {
    setShowDeleteButton(false);
    setShowRestoreButton(false);
    setShowEditButton(false);
    setShowTechApproveButton(false);
    setShowCreateRqcButton(false);
    setShowCreateTrqButton(false);
    setShowSelectSupplierButton(false);
    setShowFinancialApproveButton(false);
    setShowReleaseEmployeeButton(false);
    setShowUpdateExpReceDateButton(false);
    setShowUpdateOrderStatusButton(false);
  }, [refreshData]);
  // console.log("✊✊✊✊✊✊✊✊✊✊✊✊✊✊✊✊✊✊", showTechApproveButton);
  // console.log("🧑‍🎤🧑‍🎤🧑‍🎤🧑‍🎤🧑‍🎤🧑‍🎤🧑‍🎤🧑‍🎤🧑‍🎤🧑‍🎤🧑‍🎤🧑‍🎤🧑‍🎤🧑‍🎤🧑‍🎤", tableUrl);
  const runExport = () => {
    TableExportToExcel({ tableUrl });
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data/find/${query}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      const responseData = await response.json();
      console.log(responseData);
      setRows(responseData);
    };

    const fetchAll = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      const responseData = await response.json();

      setRows(responseData);
    };

    if (query.length > 1) {
      fetchData();
    } else {
      fetchAll();
    }
  }, [query]);

  useEffect(() => {
    console.log("showType[1]", showType[1]);
    console.log("showType[1]", showType[1] !== "all");
    console.log("showType[1]", showType[1] === true);
    console.log("showType[1]", showType[1] === false);
    async function fetchData(type) {
      console.log("00000000000000000PLAY TİMEMEMEMEMEMEMEMEMMEME.......................");
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/specificcolumn/find/${showType}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      const responseData = await response.json();
      console.log("responseDataaaaaaaaaaaaaaaaaaaaaaaaaaaaa", responseData);
      setRows(responseData);
    }

    async function fetchAll(type) {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api2/${tableUrl}/data`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      const responseData = await response.json();

      setRows(responseData);
    }

    switch (showType[1]) {
      case "all":
        fetchAll();
        break;
      case true:
        fetchData();
        break;
      case false:
        fetchData();
        break;
      default:
        break;
    }

  }, [showType]);

  const baslik = tableUrl.charAt(0).toUpperCase() + tableUrl.slice(1);

  const [toggleMenu, setToggleMenu] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };
  return (
    <React.Fragment>

      <div className={stylesTable.body_row}>
        <div className={stylesTable.profil}> <ProfilSide /></div>
        <div className={stylesTable.table_body}>
          <div className={stylesTable.card}>
            {" "}
            <div className={stylesTable.row}>
              {screenWidth < 650 && (<div className={stylesTable.row_for_hamburger}><div className={stylesTable.hamburger} onClick={openProfileDrawerHandler}></div> {baslik} Listesi</div>)}
              {screenWidth > 650 && (<div> {baslik} Listesi</div>)}

              <input
                className={stylesTable.search}
                placeholder="Search..."
                onChange={(e) => setQuery(e.target.value.toLowerCase())}
              />
            </div>
          </div>

          <TableHeader
            openNewDrawerHandler={openNewDrawerHandler}
            openEditDrawerHandler={openEditDrawerHandler}
            openDeleteModalHandler={openDeleteModalHandler}
            openEditAllHandler={openEditAllHandler}
            closeEditAllHandler={closeEditAllHandler}
            openTechApproveModalHandler={openTechApproveModalHandler}
            openCreateRqcModalHandler={openCreateRqcModalHandler}
            openSelectSupplierModalHandler={openSelectSupplierModalHandler}
            openFinancialApproveModalHandler={openFinancialApproveModalHandler}
            openCreateTrqModalHandler={openCreateTrqModalHandler}
            tableUrl={tableUrl}
            runExport={runExport}
            showNewButton={showNewButton}
            showDeleteButton={showDeleteButton}
            showEditButton={showEditButton}
            showTechApproveButton={showTechApproveButton}
            showCreateRqcButton={showCreateRqcButton}
            showSelectSupplierButton={showSelectSupplierButton}
            showFinancialApproveButton={showFinancialApproveButton}
            showCreateTrqButton={showCreateTrqButton}
            showRestoreButton={showRestoreButton}
            openRestoreModalHandler={openRestoreModalHandler}
            openReleaseDrawerHandler={openReleaseDrawerHandler}
            showReleaseEmployeeButton={showReleaseEmployeeButton}
            setShowType={setShowType}
            showEditInGridview={showEditInGridview}
            showSaveAll={showSaveAll}
            showUpdateOrderStatusButton={showUpdateOrderStatusButton}
            showUpdateExpReceDateButton={showUpdateExpReceDateButton}
            openUpdateOrderStatusModalHandler={openUpdateOrderStatusModalHandler}
            openUpdateExpReceDateModalHandler={openUpdateExpReceDateModalHandler}
          />

          {screenWidth > 500 ? (
            <TableBody
              setSelectedID={setSelectedID}
              editAll={editAll}
              tableUrl={tableUrl}
              setIsChecked={setIsChecked}
              isChecked={isChecked}
              setIsCheckedFile={setIsCheckedFile}
              isCheckedFile={isCheckedFile}
              filterChoosen={filterChoosen}
              rows={rows}
              setRows={setRows}
              setSelectedColumn={openFilterDrawerHandler}
              setFilterChoosen={setFilterChoosen}
              openDisplayDrawerHandler={openDisplayDrawerHandler}
              refreshData={refreshData}
              checkedlist={checkedlist}
              setCheckedList={setCheckedList}
              setIsCheckedAvatar={setIsCheckedAvatar}
              isCheckedAvatar={isCheckedAvatar}
              setIsCheckedImage={setIsCheckedImage}
              isCheckedImage={isCheckedImage}
              isCheckedOrderType={isCheckedOrderType}
              setIsCheckedOrderType={setIsCheckedOrderType}
              isCheckedWoNumber={isCheckedWoNumber}
              setIsCheckedWoNumber={setIsCheckedWoNumber}
              setIsCheckedCreatedBy={setIsCheckedCreatedBy}
              isCheckedCreatedBy={isCheckedCreatedBy}
              setIsCheckedCreatedByDepartment={setIsCheckedCreatedByDepartment}
              isCheckedCreatedByDepartment={isCheckedCreatedByDepartment}
            />
          ) : (<TableBodyMobile
            setSelectedID={setSelectedID}
            editAll={editAll}
            tableUrl={tableUrl}
            setIsChecked={setIsChecked}
            isChecked={isChecked}
            setIsCheckedFile={setIsCheckedFile}
            isCheckedFile={isCheckedFile}
            filterChoosen={filterChoosen}
            rows={rows}
            setRows={setRows}
            setSelectedColumn={openFilterDrawerHandler}
            setFilterChoosen={setFilterChoosen}
            openDisplayDrawerHandler={openDisplayDrawerHandler}
            refreshData={refreshData}
            checkedlist={checkedlist}
            setCheckedList={setCheckedList}
            setIsCheckedAvatar={setIsCheckedAvatar}
            isCheckedAvatar={isCheckedAvatar}
            setIsCheckedImage={setIsCheckedImage}
            isCheckedImage={isCheckedImage}
            isCheckedOrderType={isCheckedOrderType}
            setIsCheckedOrderType={setIsCheckedOrderType}
            isCheckedWoNumber={isCheckedWoNumber}
            setIsCheckedWoNumber={setIsCheckedWoNumber}
            setIsCheckedCreatedBy={setIsCheckedCreatedBy}
            isCheckedCreatedBy={isCheckedCreatedBy}
            setIsCheckedCreatedByDepartment={setIsCheckedCreatedByDepartment}
            isCheckedCreatedByDepartment={isCheckedCreatedByDepartment} />)}



        </div>
      </div>

      {newDrawerIsOpen && <BackDrop onClick={closeNewDrawerHandler} />}
      <SideDrawer show={newDrawerIsOpen} /* onClick={closeNewDrawerHandler}*/>
        <TableNewForm
          tableUrl={tableUrl}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
          closeNewDrawerHandler={closeNewDrawerHandler}
        />
      </SideDrawer>
      {profileDrawerIsOpen && <BackDrop onClick={closeProfileDrawerHandler} />}
      <ProfileSideDrawer show={profileDrawerIsOpen}  /* onClick={closeNewDrawerHandler}*/>
        <div className={stylesTable.profil}> <ProfilSide /></div>
      </ProfileSideDrawer>
      {selectedID !== undefined ? (
        <>
          {editDrawerIsOpen && <BackDrop onClick={closeEditDrawerHandler} />}

          <SideDrawer show={editDrawerIsOpen} /* onClick={closeDrawerHandler}*/>
            <TableEditForm
              selectedID={selectedID}
              tableUrl={tableUrl}
              setRefreshData={setRefreshData}
              refreshData={refreshData}
              closeEditDrawerHandler={closeEditDrawerHandler}
              setIsChecked={setIsChecked}
              setIsCheckedFile={setIsCheckedFile}
              setSelectedID={setSelectedID}
              checkedlist={checkedlist}
              setCheckedList={setCheckedList}
              setIsCheckedAvatar={setIsCheckedAvatar}
              setIsCheckedImage={setIsCheckedImage}
            />
          </SideDrawer>
        </>
      ) : (
        <>
          {editDrawerIsOpen && <BackDrop onClick={closeEditDrawerHandler} />}
          <Modal show={editDrawerIsOpen}>
            <p>Please select an item </p>
            <Button type="button" onClick={closeEditDrawerHandler}>
              OK
            </Button>
          </Modal>
        </>
      )}
      {deleteModalIsOpen && <BackDrop onClick={closeDeleteModalHandler} />}
      <Modal show={deleteModalIsOpen}>
        <TableDeleteForm
          selectedID={selectedID}
          tableUrl={tableUrl}
          isChecked={isChecked}
          isCheckedFile={isCheckedFile}
          isCheckedAvatar={isCheckedAvatar}
          isCheckedImage={isCheckedImage}
          closeDeleteModalHandler={closeDeleteModalHandler}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
          setIsChecked={setIsChecked}
          setIsCheckedFile={setIsCheckedFile}
          setIsCheckedAvatar={setIsCheckedAvatar}
          setIsCheckedImage={setIsCheckedImage}
        />
      </Modal>
      {restoreModalIsOpen && <BackDrop onClick={closeRestoreModalHandler} />}
      <Modal show={restoreModalIsOpen}>
        <TableRestoreForm
          selectedID={selectedID}
          tableUrl={tableUrl}
          isChecked={isChecked}
          isCheckedFile={isCheckedFile}
          isCheckedAvatar={isCheckedAvatar}
          isCheckedImage={isCheckedImage}
          closeRestoreModalHandler={closeRestoreModalHandler}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
          setIsChecked={setIsChecked}
          setIsCheckedFile={setIsCheckedFile}
          setIsCheckedAvatar={setIsCheckedAvatar}
          setIsCheckedImage={setIsCheckedImage}
        />
      </Modal>
      {techApproveModalIsOpen && (
        <BackDrop onClick={closeTechApproveModalHandler} />
      )}
      <Modal show={techApproveModalIsOpen}>
        <TableTechApproveForm
          selectedID={selectedID}
          tableUrl={tableUrl}
          isChecked={isChecked}
          closeTechApproveModalHandler={closeTechApproveModalHandler}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
          setIsChecked={setIsChecked}
        />
      </Modal>
      {createRqcModalIsOpen && (
        <BackDrop onClick={closeCreateRqcModalHandler} />
      )}
      <SideDrawer show={createRqcModalIsOpen}>
        <TableCreateRqcForm
          selectedID={selectedID}
          tableUrl={tableUrl}
          isChecked={isChecked}
          isCheckedOrderType={isCheckedOrderType}
          isCheckedWoNumber={isCheckedWoNumber}
          closeCreateRqcModalHandler={closeCreateRqcModalHandler}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
          setIsChecked={setIsChecked}
          setIsCheckedOrderType={setIsCheckedOrderType}
          setIsCheckedWoNumber={setIsCheckedWoNumber}
        />
      </SideDrawer>

      {createTrqModalIsOpen && (
        <BackDrop onClick={closeCreateTrqModalHandler} />
      )}
      <SideDrawer show={createTrqModalIsOpen}>
        <TableCreateTrqForm
          selectedID={selectedID}
          tableUrl={tableUrl}
          isChecked={isChecked}
          isCheckedOrderType={isCheckedOrderType}
          isCheckedWoNumber={isCheckedWoNumber}
          closeCreateTrqModalHandler={closeCreateTrqModalHandler}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
          setIsChecked={setIsChecked}
          setIsCheckedOrderType={setIsCheckedOrderType}
          setIsCheckedWoNumber={setIsCheckedWoNumber}
        />
      </SideDrawer>

      {selectSupplierModalIsOpen && (
        <BackDrop onClick={closeSelectSupplierModalHandler} />
      )}
      <SideDrawer show={selectSupplierModalIsOpen}>
        <TableSelectSupplierForm
          tableUrl={tableUrl}
          isChecked={isChecked}
          closeSelectSupplierModalHandler={closeSelectSupplierModalHandler}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
          setIsChecked={setIsChecked}
        />
      </SideDrawer>
      {financialApproveModalIsOpen && (
        <BackDrop onClick={closeFinancialApproveModalHandler} />
      )}
      <Modal show={financialApproveModalIsOpen}>
        <TableFinancialApproveForm
          selectedID={selectedID}
          tableUrl={tableUrl}
          isChecked={isChecked}
          closeTechApproveModalHandler={closeFinancialApproveModalHandler}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
          setIsChecked={setIsChecked}
        />
      </Modal>
      {displayDrawerIsOpen && <BackDrop onClick={closeDisplayDrawerHandler} />}
      <SideDrawer show={displayDrawerIsOpen} /* onClick={closeDrawerHandler}*/>
        <TableDispayForm selectedID={selectedID} tableUrl={tableUrl} closeDisplayDrawerHandler={closeDisplayDrawerHandler} />
      </SideDrawer>
      {filterDrawerIsOpen && <BackDrop onClick={closeFilterDrawerHandler} />}
      <SideDrawer show={filterDrawerIsOpen} /* onClick={closeDrawerHandler}*/>
        <TableFilterForm
          checkedChoosen={checkedChoosen}
          setCheckedChoosen={setCheckedChoosen}
          filterChoosen={filterChoosen}
          setFilterChoosen={setFilterChoosen}
          setRows={setRows}
          filterby={selectedColumn}
          closeFilterDrawerHandler={closeFilterDrawerHandler}
          setSelectedColumn={setSelectedColumn}
          tableUrl={tableUrl}
          isFilterFieldMultiSelect={isFilterFieldMultiSelect}
        />
      </SideDrawer>
      {releaseDrawerIsOpen && <BackDrop onClick={closeReleaseDrawerHandler} />}

      <SideDrawer show={releaseDrawerIsOpen} /* onClick={closeDrawerHandler}*/>
        <ReleaseForm
          selectedID={selectedID}
          tableUrl={tableUrl}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
          closeReleaseDrawerHandler={closeReleaseDrawerHandler}
        />
      </SideDrawer>
      {updateOrderStatusModalIsOpen && (
        <BackDrop onClick={closeUpdateOrderStatusModalHandler} />
      )}
      <Modal show={updateOrderStatusModalIsOpen}>
        <TableUpdateOrderStatusForm
          selectedID={selectedID}
          tableUrl={tableUrl}
          isChecked={isChecked}
          closeUpdateOrderStatusModalHandler={closeUpdateOrderStatusModalHandler}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
          setIsChecked={setIsChecked}
        />
      </Modal>
      {updateExpReceDateModalIsOpen && (
        <BackDrop onClick={closeUpdateExpReceDateModalHandler} />
      )}
      <Modal show={updateExpReceDateModalIsOpen}>
        <TableUpdateExpReceDateForm
          selectedID={selectedID}
          tableUrl={tableUrl}
          isChecked={isChecked}
          closeUpdateExpReceDateModalHandler={closeUpdateExpReceDateModalHandler}
          setRefreshData={setRefreshData}
          refreshData={refreshData}
          setIsChecked={setIsChecked}
        />
      </Modal>
    </React.Fragment>
  );
};

export default Table;